import * as React from 'react';
import { Box, Link, AspectImage } from 'theme-ui';
import { Row, Column, formatDate } from '@carbonplan/components';

const sx = {
  highlight: {
    mb: [3, 3, 3, 4],
    fontSize: [3, 3, 3, 4],
    fontFamily: 'heading',
    letterSpacing: 'smallcaps',
    textTransform: 'uppercase',
    color: 'secondary',
  },
  date: {
    color: 'secondary',
    fontSize: [1, 1, 1, 2],
    fontFamily: 'mono',
    letterSpacing: 'mono',
    textTransform: 'uppercase',
    transition: 'opacity 0.15s',
  },
}

export default function NewsComponent({orientation}) {

  const [news, setNews] = React.useState(false);

  React.useEffect(() => {
    setNews([
      {
        "category": "technology",
        "datetime": 1596589501,
        "headline": "Square surges after reporting 64% jump in revenue, more customers using Cash App",
        "id": 5085164,
        "image": "https://image.cnbcfm.com/api/v1/image/105569283-1542050972462rts25mct.jpg?v=1542051069",
        "related": "",
        "source": "CNBC",
        "summary": "Shares of Square soared on Tuesday evening after posting better-than-expected quarterly results and strong growth in its consumer payments app.",
        "url": "https://www.cnbc.com/2020/08/04/square-sq-earnings-q2-2020.html"
      },
      {
        "category": "business",
        "datetime": 1596588232,
        "headline": "B&G Foods CEO expects pantry demand to hold up post-pandemic",
        "id": 5085113,
        "image": "https://image.cnbcfm.com/api/v1/image/106629991-1595532157669-gettyimages-1221952946-362857076_1-5.jpeg?v=1595532242",
        "related": "",
        "source": "CNBC",
        "summary": "\"I think post-Covid, people will be working more at home, which means people will be eating more breakfast\" and other meals at home, B&G CEO Ken Romanzi said.",
        "url": "https://www.cnbc.com/2020/08/04/bg-foods-ceo-expects-pantry-demand-to-hold-up-post-pandemic.html"
      },
      {
        "category": "top news",
        "datetime": 1596584406,
        "headline": "Anthony Levandowski gets 18 months in prison for stealing Google self-driving car files",
        "id": 5084850,
        "image": "https://image.cnbcfm.com/api/v1/image/106648265-1596584130509-UBER-LEVANDOWSKI.JPG?v=1596584247",
        "related": "",
        "source": "CNBC",
        "summary": "A U.S. judge on Tuesday sentenced former Google engineer Anthony Levandowski to 18 months in prison for stealing a trade secret from Google related to self-driving cars months before becoming the head of Uber Technologies Inc's rival unit.",
        "url": "https://www.cnbc.com/2020/08/04/anthony-levandowski-gets-18-months-in-prison-for-stealing-google-self-driving-car-files.html"
      }
      ])
  }, [])

  function getFormattedDate(date) {
    let year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, '0');
    let day = date.getDate().toString().padStart(2, '0');
  
    return month + '/' + day + '/' + year;
  }
  
  if(orientation === 'vertical'){
    return(
      <Row columns={[0, 0, 3, 3]} sx={{ mb: [4, 5, 6, 7] }}>
        <Column start={[1, 1, 1, 1]} sx={{ mb: [4, 0, 5, 6] }} width={[0, 0 ,3 ,3]}>
          <Box sx={sx.highlight}>Press highlights</Box>
          {news && news.map((d, i) => {
            return (
              <Link
                key={d.id}
                href={d.url}
                sx={{
                  display: 'block',
                  mb: [4],
                  textDecoration: 'none',
                  '@media (hover: hover) and (pointer: fine)': {
                    '&:hover > #logo': {
                      opacity: 0.7,
                    },
                    '&:hover > #date': {
                      opacity: 0.7,
                    },
                  },
                }}
              >
                <Box
                  id='logo'
                  sx={{
                    opacity: 1,
                    position: 'relative',
                    width: '100%',
                    height: ['90px', '120px', '150px', '180px'],
                    transition: 'opacity 0.15s',
                    overflow: 'hidden'
                  }}
                >
                  <AspectImage ratio={4 / 3} src={d.image} />
                </Box>
                <Box id='headline' sx={{ ...sx.date, mt: [2, 2, 2, 3] }}>
                    {
                      d.headline
                    }

                </Box>
                <Box id='date' sx={{ ...sx.date, mt: [2, 2, 2, 3] }}>

                  {
                  formatDate(getFormattedDate(new Date(d.datetime * 1000)))
                  }
                </Box>
              </Link>
            )
          })}
        </Column>
      </Row>
    )
  }

  return(
       <Row columns={[3, 6, 6, 6]} sx={{ mb: [4, 5, 6, 7] }}>
        <Column start={[1, 1, 1, 1]} sx={{ mb: [4, 0, 5, 6] }} width={[3, 6, 6, 6]}>
          <Box sx={sx.highlight}>Press highlights</Box>
        </Column>
        {news && news.map((d, i) => {
          return (
            <Column
              key={d.id}
              start={[i + 1, 1 + i * 2, 1 + i * 2, 1 + i * 2]}
              width={[1, 2, 2, 2]}
              sx={{
                display: i < 3 ? 'block' : ['none', 'none', 'none', 'none'],
              }}
            >
              <Link
                href={d.url}
                sx={{
                  display: 'block',
                  mb: [4, 0, 0, 0],
                  textDecoration: 'none',
                  '@media (hover: hover) and (pointer: fine)': {
                    '&:hover > #logo': {
                      opacity: 0.7,
                    },
                    '&:hover > #date': {
                      opacity: 0.7,
                    },
                  },
                }}
              >
                <Box
                  id='logo'
                  sx={{
                    opacity: 1,
                    position: 'relative',
                    width: '100%',
                    height: ['90px', '120px', '150px', '180px'],
                    transition: 'opacity 0.15s',
                    overflow: 'hidden'
                  }}
                >
                  <AspectImage ratio={4 / 3} src={d.image} />
                </Box>
                <Box id='headline' sx={{ ...sx.date, mt: [2, 2, 2, 3] }}>
                  {
                    d.headline
                  }
                </Box>
                <Box id='date' sx={{ ...sx.date, mt: [2, 2, 2, 3] }}>
                  {
                    formatDate(getFormattedDate(new Date(d.datetime * 1000)))
                  }
                </Box>
              </Link>
            </Column>
          )
        })}
      </Row>
  )
  
}