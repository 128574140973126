import { useState } from 'react'
import { Box } from 'theme-ui'
import { Row, Column } from '@carbonplan/components'
import NumberFormat from 'react-number-format';
import NewsDetailModal from './NewsDetailModal'


const NewsTableRow = ({ fields, data, first = false, idx }) => {

  var [openModal, setOpenModal] = useState(false);
  const handleClose = () => {
    setOpenModal(false);
  };
  
  const handleClickOpen = () => {
    setOpenModal(true);
  };

  return (
    <>
      <Row
        as='tr'
        columns={[3, 6, 6, 6]}
        sx={{
          pb: ['21px', '21px', '21px', '22px'],
          pt: [0, '23px', '23px', '26px'],
          borderStyle: 'solid',
          borderWidth: '0px',
          borderTopWidth: first ? '0px' : '1px',
          borderColor: 'muted',
        }}
        onClick={()=>{handleClickOpen()}}
      >
        <Column 
          start={[0, 1, 1, 1]} 
          width={[0, 1, 1, 1]} 
          as='td'
          sx={{
            display: ['none', 'inherit', 'inherit', 'inherit']
          }}
          >
          <Box
            sx={{
              p: [2],
              pt: [2, 2, 2, '5px'],
              m: [2, -2, -2, -2],
              mt: [4, -2, -2, -2],
              width: 'fit-content',
              position: 'relative',
              '@media (hover: hover) and (pointer: fine)': {
                '&:hover > #expander': {
                  stroke: 'orange',
                },
              },
              cursor: 'pointer',
              fontSize: [3, 3, 3, 4],
              fontFamily: ['mono']
            }}
          >
            {String(idx + 1).padStart(3, '0')}
          </Box>
        </Column>
        <Column
          start={[1, 2, 2, 2]}
          width={[3, 1, 1, 1]}
          as='td'
          sx={{
            transition: 'background-color 0.15s',
            paddingY: [1, 0, 0, 0]
          }}
        >
          <Box>
            {data._value_source}
          </Box>
        </Column>

        <Column
          start={[1, 3, 3, 3]}
          width={[3, 1, 1, 1]}
          as='td'
          sx={{
            transition: 'background-color 0.15s',
            paddingY: [1, 0, 0, 0]
          }}
        >
          <Box>
            {data.date}
          </Box>
        </Column>

        <Column
          start={[1, 4, 4, 4]}
          width={[3, 2, 2, 2]}
          as='td'
          sx={{
            transition: 'background-color 0.15s',
            paddingY: [1, 0, 0, 0]
          }}
        >
          <Box>
            {data._value_headline}
          </Box>
        </Column>
        <Column
          start={[1, 6, 6, 6]}
          width={[3, 1, 1, 1]}
          as='td'
          sx={{
            transition: 'background-color 0.15s',
            paddingY: [1, 0, 0, 0]
          }}
        >
          <Box sx={{
            fontSize: [3, 3, 3, 4],
            fontFamily: ['mono']
          }}>
            <NumberFormat value={data._value_views} displayType={'text'} thousandSeparator={true} />
          </Box>
        </Column>
      </Row>
      <NewsDetailModal
        openModal={openModal}
        handleClose={handleClose}
        newArticle={data}
      ></NewsDetailModal>
    </>
  )
}

export default NewsTableRow