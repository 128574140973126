import Cookies from 'universal-cookie';
import handleResponse from '../helpers/HandleResponse';
const cookies = new Cookies();

let stockService = {
  getStocks,
  
};

function getStocks (querySearch) {
  return fetch(`${process.env.REACT_APP_API_URL}cat_stocks${querySearch ? '?q='+querySearch : ''}`,{
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + cookies.get('jwt')
    },
    method: 'GET'
  })
  .then(response => {
    return handleResponse(response)
  })
  .catch(error => console.warn(error));
}


export default stockService;