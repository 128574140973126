import { memo } from 'react';
import { useHistory  } from "react-router-dom";
import {
  useThemeUI,
  Box,
  Flex,
} from 'theme-ui'
import { Row, Tag, Column } from '@carbonplan/components'
import { Right } from '@carbonplan/icons';
import TriDataComponent from '../components/TriDataComponent';




const Report = ({ data, setHighlighted, tooltips, embed }) => {

  const { theme } = useThemeUI();
  const history = useHistory(); 

  let {
    id,
    name,
    tags,
  } = data

  return (
    <Row columns={[3, 6, 3, 3]} 
      onClick={(e) => {
        let path = `crypto/${data.crypto_symbol}?metric=social`; 
        history.push(path);
      }}
      onMouseEnter={() => {
        if (setHighlighted) setHighlighted(id)
      }}
      onMouseLeave={() => {
        if (setHighlighted) setHighlighted(null)
      }}
      sx={{
        borderStyle: 'solid',
        borderColor: 'muted',
        borderWidth: '0px',
        borderBottomWidth: embed
          ? ['0px', '0px', '0px']
          : ['1px', '1px', '0px'],
        borderLeftWidth: embed ? [0, 0, 0] : ['0px', '0px', '1px'],
        borderRadius: '0px',
        cursor: embed ? 'default' : 'pointer',
        pl: embed ? [0, 0, 0] : [0, 0, 0],
        pr: [0],
        pb: [2, 2, 0, 0],
        pt: embed ? [0, 0, 0] : ['0px', '0px', '12px'],
        my: [embed ? 0 : 3],
        mb: embed ? [0, 0, 0] : [3, 3, '24px'],
        transition: 'border-color 0.15s',
        '&:hover': {
          borderColor: ['muted', 'muted', 'secondary'],
        },
        '@media (hover: hover) and (pointer: fine)': {
          '&:hover > #container > #box > #flex > #text > #expander': {
            stroke: 'primary',
          },
        },
        '@media (hover: none) and (pointer: coarse)': {
          '#container > #box > #flex > #text > #expander': {
            stroke: 'primary',
          },
        },
      }}
    >
        <Column start={[1, 1, 1, 1]} width={[1, 2, 1, 1]}
          sx={{
            mt: ['-6px'],
            ml: [0, 0, 5, 5],
        }}>
          <Box
            id='text'
            sx={{
              fontSize: [4, 4, 4, 5],
              lineHeight: 'heading',
            }}
          >
            {data.crypto_symbol}
          </Box>
          <Box
           sx={{
             fontSize: [1, 1, 1, 2],
             pt: ['2px'],
             pb: [1],
             color: 'secondary',
           }}
         >
           {name}
         </Box>
         <Box>
           {tags.map((tag, i) => (
             <Tag
               key={id + '-tag-' + i}
               label={tag}
               value={true}
               sx={{
                 cursor: 'inherit',
                 mr: i === (tags.length - 1) ? [0] : [2],
                 color: theme.tags[tag],
               }}
             >
               {tag}
             </Tag>
           ))}
         </Box> 
        </Column>
        <Column start={[2, 3, 2, 2]} width={[2, 4, 2, 2]}>
          <Flex sx={{
            justifyContent:'space-between',
            alignItems: 'center'
          }}>
            <TriDataComponent label={data.value} chartColor={[theme.colors.green]}> </TriDataComponent>
            <Right sx={{mt:[2], width:[15], color: 'secondary'}}></Right>
          </Flex>
          
        </Column>
    </Row>
  )
}

export default memo(Report)
