import React from 'react'
import { Box, useThemeUI } from 'theme-ui'

const Logo = ({ ...props }) => {
  var theme = useThemeUI().theme;
  return (
    <Box
      as='svg'
      height='24pt'
      fill='currentColor'
      stroke='none'
      viewBox='22 0 677.8 104.8'
      sx={{pt:3}}
      {...props}
    >
        <path fill={theme.text} d="M57.9,71H43.6V39.9h14.3c11.7,0,19.8,5.3,19.8,15.5C77.7,65.7,69.6,71,57.9,71z M58.5,33.2H36v44.4h22.5
          c16.5,0,27-8.5,27-22.2C85.5,41.7,75,33.2,58.5,33.2z"/>
        <polygon fill={theme.text} points="105.3,71 105.3,58.4 135.6,58.4 135.6,51.7 105.3,51.7 105.3,39.9 141.3,39.9 141.3,33.2 97.8,33.2 
          97.8,77.6 141.9,77.6 141.9,71 "/>
        <polygon fill={theme.text} points="197.2,40 197.2,33.2 153.8,33.2 153.8,77.6 161.4,77.6 161.4,59.8 191.7,59.8 191.7,53 161.4,53 
          161.4,40 "/>
        <rect x="207.9" y="33.2" fill={theme.text} width="7.6" height="44.4"/>
        <path fill={theme.text} d="M241.9,60.4l10.8-20.5l10.8,20.5H241.9z M272.7,77.6h8.3l-24-44.4h-8.4l-24,44.4h8.2l5.6-10.7H267
          L272.7,77.6z"/>
        <polygon fill={theme.text} points="331.5,33.2 331.5,66.9 298.3,33.2 290.2,33.2 290.2,77.6 297.5,77.6 297.5,42.6 332.2,77.6 
          338.9,77.6 338.9,33.2 "/>
        <path fill={theme.text} d="M378.4,72.2c-11.7,0-19.5-5.7-19.5-16.8s7.8-16.8,19.5-16.8c7.2,0,12.9,2.4,16.2,7.9l7.1-3.6
          c-4.1-6.3-12.2-11-23.1-11c-16.9,0-27.4,8.9-27.4,23.5c0,14.6,10.5,23.5,27.1,23.5c11.2,0,19.3-4.6,23.5-11.1l-7-3.5
          C391.3,69.9,385.6,72.2,378.4,72.2z"/>
        <polygon fill={theme.text} points="420.1,71 420.1,58.4 450.4,58.4 450.4,51.7 420.1,51.7 420.1,39.9 456.1,39.9 456.1,33.2 
          412.5,33.2 412.5,77.6 456.7,77.6 456.7,71 "/>
    </Box>
  )
}

export default Logo
