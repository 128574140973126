import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from "react-router-dom";
import { Row, Column } from '@carbonplan/components'
import LineChartComponent from '../components/LineChart';
import Top from './top';
import Sidebar from './sidebar';
import { Box, useThemeUI } from 'theme-ui';
import Spinner from '../components/Spinner';
import NoData from '../components/NoDataComponent';
import HighlightsComponent from '../components/HighlightsComponent';
import holdingsService from '../services/HoldingsService';
import cryptoService from '../services/CryptoService';
import useQuery from '../helpers/useQueryParams';

function ControlChart ({settingsExpanded}) {

  var colors = useThemeUI().theme.colors;
  let query = useQuery();

  let today = new Date();
  let todayDay = String(today.getDate()).padStart(2, '0');
  let todayMonth = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  let todayYear = today.getFullYear();

  let yesterday = new Date(today.setDate(today.getDate() - 1));
  let yesterdayDay = String(yesterday.getDate()).padStart(2, '0');
  let yesterdayMonth = String(yesterday.getMonth() + 1).padStart(2, '0'); //January is 0!
  let yesterdayYear = yesterday.getFullYear();

  let monthAgo = new Date(today.setMonth(today.getMonth() - 12));
  let monthAgoDay = String(monthAgo.getDate()).padStart(2, '0');
  let monthAgoMonth = String(monthAgo.getMonth() + 1).padStart(2, '0'); //January is 0!
  let monthAgoYear = monthAgo.getFullYear();

  //let dayAfter = new Date(monthAgo.setDate(today.getDay() + 1));
  // let dayAfterDay = String(dayAfter.getDate()).padStart(2, '0');
  // let dayAfterMonth = String(dayAfter.getMonth() + 1).padStart(2, '0'); //January is 0!
  // let dayAfterYear = dayAfter.getFullYear();

  let yesterdayDateFormated = yesterdayYear + '-' + yesterdayMonth + '-' + yesterdayDay;
  let startDateFormated = query.get("start") && query.get("end") ? query.get("start") : monthAgoYear + '-' + monthAgoMonth + '-' + monthAgoDay;
  let endDateFormated = query.get("start") && query.get("end") ? query.get("end") : todayYear + '-' + todayMonth + '-' + todayDay;
  console.log(yesterdayDateFormated, startDateFormated, endDateFormated)
  //let startDateOneDayAfterFormated = query.get("start") && query.get("end") ? query.get("end") : dayAfterYear + '-' + dayAfterMonth + '-' + dayAfterDay;


  var [metricSelected, setMetricSelected] = useState('');
  var [confidenceInterval, setConfidenceInterval] = useState(1.2);
  var [movingAverageDays, setMovingAverageDays] = useState(5.0);
  var [startDate, setStartDate] = useState(startDateFormated);
  var [endDate, setEndDate] = useState(endDateFormated);
  var [campaignData, setCampaignData] = useState([]);
  var [priceData, setPriceData] = useState([]);
  var [socialData, setSocialData] = useState([]);
  var [volumePlotBands, setVolumePlotBands] = useState([]);
  var [pricePlotBands, setPricePlotBands] = useState([]);
  var [socialPlotBands, setSocialPlotBands] = useState([]);
  
  var [highlightNews, setHighlightNews] = useState([]);
  var [startAndEndDateNewsSelected, setStartAndEndDateNewsSelected] = useState([yesterdayDateFormated.replace(/-/g,"/"), endDateFormated.replace(/-/g,"/")]);

  var [plotBandsNewsDatesRangeSelected, setPlotBandsNewsDatesRangeSelected] = useState([]);
  var [loadingSpinnerSocial, setLoadingSpinnerSocial] = useState(false);
  var [loadingSpinnerPrice, setLoadingSpinnerPrice] = useState(false);
  var [loadingSpinnerVolume, setLoadingSpinnerVolume] = useState(false);

  var [loadingSpinnerNewsArticles, setLoadingSpinnerNewsArticles] = useState(false);

  var [service, setService] = useState({});

  var { slug, stockType } = useParams();
  var view = query.get("metric");
  let history = useHistory();
  
  function selectDayRange(clickDate){
    let date = new Date(clickDate);
    date = new Date(date.setDate(date.getDate() + 1)) //Gap 1 day before
    
    let oneDayBefore = new Date(date.setDate(date.getDate() - 1))
    let oneDayBeforedd = String(oneDayBefore.getDate()).padStart(2, '0');
    let oneDayBeforemm = String(oneDayBefore.getMonth() + 1).padStart(2, '0'); //January is 0!
    let oneDayBeforeyy = oneDayBefore.getFullYear();
    let oneDayBeforeFormated = oneDayBeforeyy + '/' + oneDayBeforemm + '/' + oneDayBeforedd;

    let oneDayAfter = new Date(date.setDate(date.getDate() + 2))
    let oneDayAfterdd = String(oneDayAfter.getDate()).padStart(2, '0');
    let oneDayAftermm = String(oneDayAfter.getMonth() + 1).padStart(2, '0'); //January is 0!
    let oneDayAfteryy = oneDayAfter.getFullYear();
    let oneDayAfterFormated = oneDayAfteryy + '/' + oneDayAftermm + '/' + oneDayAfterdd;

    setStartAndEndDateNewsSelected([oneDayBeforeFormated, oneDayAfterFormated])
  }

  function selectRange(event) {
    event.preventDefault()
    let startDateSelected = new Date(event.xAxis[0].min)
    let startdd = String(startDateSelected.getDate()).padStart(2, '0');
    let startmm = String(startDateSelected.getMonth() + 1).padStart(2, '0'); //January is 0!
    let startyy = startDateSelected.getFullYear();
    let startDateSelectedFormated = startyy + '/' + startmm + '/' + startdd;
    
    let endDateSelected = new Date(event.xAxis[0].max);
    let enddd = String(endDateSelected.getDate()).padStart(2, '0');
    let endmm = String(endDateSelected.getMonth() + 1).padStart(2, '0'); //January is 0!
    let endyy = endDateSelected.getFullYear();
    let endDateSelectedFormated = endyy + '/' + endmm + '/' + enddd;
    let plotBandDateRange = [{
      color: colors.background,
      borderColor:colors.muted,
      borderWidth: 2, 
      from: event.xAxis[0].min,
      to: event.xAxis[0].max
    }];
    setPlotBandsNewsDatesRangeSelected(plotBandDateRange);
    setStartAndEndDateNewsSelected([startDateSelectedFormated, endDateSelectedFormated])
  }

  useEffect(()=>{
    if(stockType == 'crypto')
      setService(cryptoService)
    if(stockType == 'stock' || stockType == 'etf')
      setService(holdingsService)
  }, [stockType]);

  useEffect(()=>{
    history.replace(`?metric=${view || 'volume'}&start=${startDate}&end=${endDate}`);
  }, [startDate, endDate, view, history]);

  useEffect(
    () => {
        setMetricSelected(slug)
        setLoadingSpinnerSocial(true);
        setLoadingSpinnerPrice(true);
        setLoadingSpinnerVolume(true);
        setLoadingSpinnerNewsArticles(true);
    }, [slug, setLoadingSpinnerSocial,setLoadingSpinnerPrice, setLoadingSpinnerNewsArticles]);

  useEffect(() => {
    if (metricSelected && view === 'volume') {
      service.getChartData(metricSelected, { confidenceInterval, startDate, endDate, movingAverageDays, measurement: 'volume' })
        .then(response => {
          if (response && response[0]) {
            var objX = [];
            var objUCI = [];
              var objC = [];
            var highlights = [];

            response[0].records.forEach((record, idx) => {
              objX.push([new Date(record.values._time).getTime(), record.values._value]);
              objUCI.push([new Date(record.values._time).getTime(), record.values._value_UCI]);
              objC.push([new Date(record.values._time).getTime(), null]);
              if (record.values._value_trigger) {
                let objTrigger = {}
                objTrigger.from = new Date(record.values._time).getTime()
                objTrigger.color = colors.muted;
                objTrigger.to = new Date(response[0].records[idx + 2] ? response[0].records[idx + 2].values._time : new Date(record.values._time).getTime() + 1000 * 60 * 60 * 24 * 3).getTime()
                highlights.push(objTrigger);
              }
            });
            setVolumePlotBands(highlights)
            setCampaignData([
              {
                name: "Trading Volume",
                data: objX
              },
              {
                name: "Threshold",
                data: objUCI,
                dashStyle: 'shortdash'
              },
              // {
              //   name: "Campaigns",
              //   data: objC,
              //   lineWidth: 30,
              //   linecap: 'square',
              //   tooltip: {
              //     pointFormatter: function () {
              //       return 'Campaign Active'
              //     }
              //   }
              // }
            ]);
          }
          setLoadingSpinnerVolume(false);
        })
    }
  }, [startDate, endDate, confidenceInterval, movingAverageDays, metricSelected, setCampaignData, setSocialPlotBands, colors, view, setLoadingSpinnerSocial]);
  
  useEffect(() => {
    if (metricSelected && view === 'price') {
      service.getChartData(metricSelected, { confidenceInterval, startDate, endDate, movingAverageDays, measurement: 'price_usd' })
        .then(response => {
          if (response && response[0]) {
            var objX = [];
            var objUCI = [];
              var objC = [];
            var highlights = [];

            response[0].records.forEach((record, idx) => {
              objX.push([new Date(record.values._time).getTime(), record.values._value]);
              objUCI.push([new Date(record.values._time).getTime(), record.values._value_UCI]);
              objC.push([new Date(record.values._time).getTime(), null]);
              if (record.values._value_trigger) {
                let objTrigger = {}
                objTrigger.from = new Date(record.values._time).getTime()
                objTrigger.color = colors.muted;
                objTrigger.to = new Date(response[0].records[idx + 2] ? response[0].records[idx + 2].values._time : new Date(record.values._time).getTime() + 1000 * 60 * 60 * 24 * 3).getTime()
                highlights.push(objTrigger);
              }
            });
            setPricePlotBands(highlights)
            setPriceData([
              {
                name: "Price",
                data: objX
              },
              {
                name: "Threshold",
                data: objUCI,
                dashStyle: 'shortdash'
              },
              // {
              //   name: "Campaigns",
              //   data: objC,
              //   lineWidth: 30,
              //   linecap: 'square',
              //   tooltip: {
              //     pointFormatter: function () {
              //       return 'Campaign Active'
              //     }
              //   }
              // },
            ]);
          }
          setLoadingSpinnerPrice(false);
        })
    }
  }, [startDate, endDate, confidenceInterval, movingAverageDays, metricSelected, setPriceData, setPricePlotBands, colors, view, setLoadingSpinnerPrice]);

  useEffect(() => {
    if (metricSelected && view === 'social') {
      service.getChartData(metricSelected, { confidenceInterval, startDate, endDate, movingAverageDays, measurement: 'social_volume_total' })
        .then(response => {
          if (response && response[0]) {
            var objX = [];
            var objUCI = [];
              var objC = [];
            var highlights = [];

            response[0].records.forEach((record, idx) => {
              objX.push([new Date(record.values._time).getTime(), record.values._value]);
              objUCI.push([new Date(record.values._time).getTime(), record.values._value_UCI]);
              objC.push([new Date(record.values._time).getTime(), null]);
              if (record.values._value_trigger) {
                let objTrigger = {}
                objTrigger.from = new Date(record.values._time).getTime()
                objTrigger.color = colors.muted;
                objTrigger.to = new Date(response[0].records[idx + 2] ? response[0].records[idx + 2].values._time : new Date(record.values._time).getTime() + 1000 * 60 * 60 * 24 * 3).getTime()
                highlights.push(objTrigger);
              }
            });
            setSocialPlotBands(highlights)
            setSocialData([
              {
                name: "Social Volume",
                data: objX
              },
              {
                name: "Threshold",
                data: objUCI,
                dashStyle: 'shortdash'
              },
              // {
              //   name: "Campaigns",
              //   data: objC,
              //   lineWidth: 30,
              //   linecap: 'square',
              //   tooltip: {
              //     pointFormatter: function () {
              //       return 'Campaign Active'
              //     }
              //   }
              // },
            ]);
          }
          setLoadingSpinnerSocial(false);
        })
    }
  }, [startDate, endDate, confidenceInterval, movingAverageDays, metricSelected, setSocialData, setSocialPlotBands, colors, view, setLoadingSpinnerSocial]);

  
  useEffect(() => {
    if (startAndEndDateNewsSelected[0] && startAndEndDateNewsSelected[1] && metricSelected ) {
      setLoadingSpinnerNewsArticles(true);
      service.getNews(metricSelected, { startDate: startAndEndDateNewsSelected[0], endDate: startAndEndDateNewsSelected[1] })
      .then(response=>{
        setHighlightNews(response);
        setLoadingSpinnerNewsArticles(false);
      })
    }
  },[startAndEndDateNewsSelected, metricSelected, view, setHighlightNews, setLoadingSpinnerNewsArticles]);
  
  return (
    <Row columns={[3, 6, 9, 9]}>
      <Column start={[1]} width={[2, 6, 3, 2]}>
        <Sidebar
          slug={slug} 
          settingsExpanded={settingsExpanded}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          confidenceInterval={confidenceInterval}
          setConfidenceInterval={setConfidenceInterval}
          movingAverageDays={movingAverageDays}
          setMovingAverageDays={setMovingAverageDays}
          view={view}
          stockType={stockType}
        />
      </Column>
      <Column start={[1, 1, 4, 3]} width={[3, 6, 6, 7]}>
          { view === 'volume'? <Top data={{activeDays: volumePlotBands.length}}/> : ''}
          { view === 'price' ? <Top data={{activeDays: pricePlotBands.length}}/> : ''}
          { view === 'social' ? <Top data={{activeDays: socialPlotBands.length}}/> : ''}
        <Box>
          { slug && view === 'volume' ? 
            ( !loadingSpinnerVolume ? 
              ( campaignData.length > 0 ?
                <Box>
                  <LineChartComponent 
                    data={campaignData} 
                    plotBands={volumePlotBands} 
                    plotBandsNewsDatesRangeSelected={plotBandsNewsDatesRangeSelected}
                    colors={[colors.yellow, colors.gray, colors.orange, colors.green]}
                    eventClick= {function(e){selectDayRange(e.xAxis[0].value)}}
                    eventSelect={function(e){selectRange(e)}}
                  >
                  </LineChartComponent>

                  <HighlightsComponent 
                    type={'volume'} 
                    news={highlightNews} 
                    startDayNewsSelected={startAndEndDateNewsSelected[0]} 
                    endDayNewsSelected={startAndEndDateNewsSelected[1]} 
                    loadingSpinnerNewsArticles={loadingSpinnerNewsArticles}
                  />
                </Box>
                :
                <Box sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  mt: [5, 7, 9, 9]
                }}>
                  <NoData labelTitle={'NO DATA'} labelText={'No data available.'}></NoData>
                </Box>
              )
            : 
              <Box sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                mt:[5, 10, 10, 10]
              }}>
                <Spinner label={true} labelTitle={'Loading'} labelText='Please wait. We are pulling data.'></Spinner>
              </Box>
            )
          : '' }
          { slug && view === 'price' ? 
            ( !loadingSpinnerPrice ? 
              ( priceData.length > 0 ?
                <Box>
                  <LineChartComponent 
                    data={priceData} 
                    plotBands={pricePlotBands}
                    plotBandsNewsDatesRangeSelected={plotBandsNewsDatesRangeSelected}
                    colors={[colors.green, colors.gray, colors.orange, colors.green]}
                    eventClick= {function(e){selectDayRange(e.xAxis[0].value)}}
                    eventSelect={function(e){selectRange(e)}}
                    >
                  </LineChartComponent> 
                  <HighlightsComponent 
                    type={'price'} 
                    news={highlightNews} 
                    startDayNewsSelected={startAndEndDateNewsSelected[0]} 
                    endDayNewsSelected={startAndEndDateNewsSelected[1]} 
                    loadingSpinnerNewsArticles={loadingSpinnerNewsArticles}
                  />
                </Box>
                :
                <Box sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  mt: [5, 7, 9, 9]
                }}>
                  <NoData labelTitle={'NO DATA'} labelText={'No data available.'}></NoData>
                </Box>
              )
            : 
              <Box sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                mt:[5, 10, 10, 10]
              }}>
                <Spinner label={true} labelTitle={'Loading'} labelText='Please wait. We are pulling data.'></Spinner>
              </Box>
            )
          : '' }
          { slug && view === 'social' ? 
            ( !loadingSpinnerSocial ? 
              ( socialData.length > 0 ?
                <Box>
                  <LineChartComponent 
                    data={socialData} 
                    plotBands={socialPlotBands}
                    plotBandsNewsDatesRangeSelected={plotBandsNewsDatesRangeSelected}
                    colors={[colors.blue, colors.gray, colors.orange, colors.blue]}
                    eventClick= {function(e){selectDayRange(e.xAxis[0].value)}}
                    eventSelect={function(e){selectRange(e)}}
                    >
                  </LineChartComponent> 
                  {/* <HighlightsComponent 
                    type={'social'} 
                    news={highlightNews} 
                    startDayNewsSelected={startDayNewsSelected} 
                    endDayNewsSelected={endDayNewsSelected} 
                    loadingSpinnerNewsArticles={loadingSpinnerNewsArticles}
                  /> */}
                </Box>
                :
                <Box sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  mt: [5, 7, 9, 9]
                }}>
                  <NoData labelTitle={'NO DATA'} labelText={'No data available.'}></NoData>
                </Box>
              )
            : 
              <Box sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                mt:[5, 10, 10, 10]
              }}>
                <Spinner label={true} labelTitle={'Loading'} labelText='Please wait. We are pulling data.'></Spinner>
              </Box>
            )
          : '' }
        </Box>
      </Column>
    </Row>
  )
}

export default ControlChart;