import * as React from 'react';
import { Row, Column } from '@carbonplan/components';
import { Box } from 'theme-ui';
import { useThemeUI } from 'theme-ui';
import ArrowIndicator from './ArrowIndicator';

export default function TickerComponent() {
  const themeUI  = useThemeUI();
  const data = [
    {
      "id": 0, 
      "name": "BTC/USD",
      "c": -33.5,
      "h": 263.31,
      "l": 260.68,
     },
     {
      "id": 1,
      "name": "ZNB ",
      "c": 50.5,
      "h": 160.31,
      "l": 170.68,
     },
     {
      "id": 2,
      "name": "APP/USD",
      "c": 20.8,
      "h": 210.31,
      "l": 220.68,
     }
  ]

  
  return (
    <>
      <Row columns={[3, 6, 6, 6]} >
        <Column start={[1, 1, 1, 1]} sx={{ mb: [4, 0, 5, 6] }} width={[3, 6, 6, 6]}>
            <Box sx={{
              mb: [3, 3, 3, 4],
              fontSize: [3, 3, 3, 4],
              fontFamily: 'heading',
              letterSpacing: 'smallcaps',
              textTransform: 'uppercase',
              color: 'secondary',
            }}>Top Movers</Box>
          </Column>
      </Row>
      <Row 
        columns={[3, 6, 6, 6]} 
        sx={{ 
          mb: [4, 5, 6, 7],
          borderTopWidth: '2px',
          borderBottomWidth: '2px',
          borderColor: themeUI.theme.colors.muted,
          borderStyle: 'solid',
          pt:[1],
          pb:[1]
        }}
      >
        {
          data.map((record, i) => {
            return (
              <Column
                key={record.id}
                start={[i + 1, 1 + i * 2, 1 + i * 2, 1 + i * 2]}
                width={[1, 2, 2, 2]}
                sx={{
                  display: i < 3 ? 'block' : ['none', 'none', 'none', 'none'],
                }}
              >
                <Box sx={{
                  width: '100%',
                  borderRightWidth: '2px',
                  borderLeftWidth: '2px',
                  borderColor: themeUI.theme.colors.muted,
                  borderStyle: i === 0 || i === 2 ? '' : 'solid',
                  textAlign: 'center',
                  fontFamily: themeUI.theme.fonts.mono,
                  fontSize: [18]
                }}>
                  <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-evenly',
                    pb:[1]
                  }}>
                    <Box>
                      {record.name}
                    </Box>
                    <Box>
                      {record.c}
                    </Box>
                  </Box>
                  <Box sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                  }}>
                    {record.c}
                    <ArrowIndicator value={record.c}></ArrowIndicator>
                  </Box>
                </Box>
              </Column>
            )
          })
        }
      </Row>
    </>
  );
}