import Cookies from 'universal-cookie';
import handleResponse from '../helpers/HandleResponse';
const cookies = new Cookies();

let AccountsService = {
  getAccount,
  getAccounts,
  getAccountTypes,
  postAccountType,
  putAccountActionableTypes
};

function getAccounts () {
  return fetch(`${process.env.REACT_APP_API_URL}account_actionable_types`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + cookies.get('jwt')
    }
  })
  .then(response => {
    return handleResponse(response)
  })
  .catch(error => console.warn(error));
}

function getAccount (id) {
  return fetch(`${process.env.REACT_APP_API_URL}account_actionable_types/${id}`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + cookies.get('jwt')
    }
  })
  .then(response => {
    return handleResponse(response)
  })
  .catch(error => console.warn(error));
}


function getAccountTypes() {
  return fetch(`${process.env.REACT_APP_API_URL}cat_actionable_types`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + cookies.get('jwt')
    }
  })
  .then(response => {
    return handleResponse(response)
  })
  .catch(error => console.warn(error));
}


function postAccountType (name, cat_actionable_type_id, redirect_url) {
  return fetch(`${process.env.REACT_APP_API_URL}cat_actionable_types`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + cookies.get('jwt')
    },
    body: JSON.stringify({name, cat_actionable_type_id, redirect_url})
  })
  .then(response => {
    return handleResponse(response)
  })
  .catch(error => console.warn(error));

}

function putAccountActionableTypes(accountId, googleAccountAdsid){
  return fetch(`${process.env.REACT_APP_API_URL}account_actionable_types/${accountId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + cookies.get('jwt')
    },
    body: JSON.stringify({selected_google_ads_account: googleAccountAdsid})
  })
  .then(response => {
    return handleResponse(response)
  })
  .catch(error => console.warn(error));
}

export default AccountsService;