import { Box, Flex } from 'theme-ui';
import { Toggle } from '@carbonplan/components';
import ConfirmationActiveModal from './confirmationActiveModal';

export const sx = {
  label: {
    fontFamily: 'mono',
    letterSpacing: 'mono',
    fontSize: [1, 1, 1, 2],
    textTransform: 'uppercase',
    color: 'secondary',
    userSelect: 'none',
  },
}

const TopTitle = ({ data, activeCampaign, openActiveModal, setOpenActiveModal, handleCloseActiveModal, setActiveCampaign }) => {

  const changeActive = () => {
    setOpenActiveModal(true);
  }

  const handleClose = () => {
    setOpenActiveModal(false);
  };
  
  const handleConfirm = () => {
    if(activeCampaign) handleCloseActiveModal(0);
    else handleCloseActiveModal(1)
    setActiveCampaign(activeCampaign ? false : true);
  };
  


  return (
    <Box
      sx={{
        position: 'sticky',
        height: 'auto',
        top: 56,
        pb: [3],
        pt: [3],
        ml: ['-16px', 0, 0],
        mr: ['-16px', 0, 0],
        pr: ['16px', 0, 0],
        pl: ['16px', 0, 0],
        bg: 'background',
        borderWidth: '0px',
        borderBottomWidth: '1px',
        borderColor: 'muted',
        borderStyle: 'solid',
        userSelect: 'none',
        zIndex: 1,
        
      }}
    >
      <Flex sx={{justifyContent: 'space-between', flexWrap: 'wrap'}}>
        <Box
          sx={{
            fontSize: [5, 6, 6, 7],
            fontFamily: 'AT Surt Medium',
          }}
        >
          {data && data.name}
        </Box>
        <Flex sx={{
          justifyContent: 'space-between',
          alignItems: 'center',
          mt: [ 3, 3, 0, 0],
          mt: [ 3, 3, 0, 0],
        }}>
          <Box sx={{...sx.label, mr:[3]}}>ACTIVE</Box>
          <Toggle value={activeCampaign} onClick={changeActive} sx={{color: 'green'}}></Toggle>
          <ConfirmationActiveModal
            openModal={openActiveModal} 
            handleClose={handleClose}
            activeCampaign={activeCampaign}
            handleConfirm={handleConfirm}
          />
        </Flex>
      </Flex>
    </Box>
  )
}

export default TopTitle
