import { memo } from 'react';
import { useHistory  } from "react-router-dom";
import { Box } from 'theme-ui';
import { Column, Row } from '@carbonplan/components';
import Typewriter from 'typewriter-effect';
import NumberFormat from "react-number-format";


const Report = ({ data, setHighlighted, tooltips, embed }) => {

  const history = useHistory(); 

  let {
    id,
    name,
    budget, 
    spend,
    campaign_triggers,
    campaign_actions
  } = data

  const ml = embed ? [0, 0, 0] : [0, 0, '24px', '36px']

  return (
    <Box
      onClick={(e) => {
        let path = `/campaign/`+data.id; 
        history.push(path);
      }}
      onMouseEnter={() => {
        if (setHighlighted) setHighlighted(id)
      }}
      onMouseLeave={() => {
        if (setHighlighted) setHighlighted(null)
      }}
      sx={{
        borderStyle: 'solid',
        borderColor: 'muted',
        borderWidth: '0px',
        borderBottomWidth: embed
          ? ['0px', '0px', '0px']
          : ['1px', '1px', '0px'],
        borderLeftWidth: embed ? [0, 0, 0] : ['0px', '0px', '1px'],
        borderRadius: '0px',
        cursor: embed ? 'default' : 'pointer',
        pl: embed ? [0, 0, 0] : [0, 0, 0],
        pr: [0],
        pb: [2, 2, 0, 0],
        pt: embed ? [0, 0, 0] : ['0px', '0px', '0px'],
        my: [embed ? 0 : 3],
        mb: embed ? [0, 0, 0] : [0, 0, 0],
        transition: 'border-color 0.15s',
        '&:hover': {
          borderColor: ['muted', 'muted', 'secondary'],
        },
        '@media (hover: hover) and (pointer: fine)': {
          '&:hover > #container > #box > #flex > #text > #expander': {
            stroke: 'primary',
          },
        },
        '@media (hover: none) and (pointer: coarse)': {
          '#container > #box > #flex > #text > #expander': {
            stroke: 'primary',
          },
        },
      }}
    >
      <Row columns={[3, 6, 9, 9]}>
        <Column start={[1, 1, 1, 1]} width={[3, 2, 3, 3]}>
          <Box
            sx={{
              display: 'inline-block',
              fontSize: [4, 4, 4, 5],
              lineHeight: 'heading',
              ml: ml,
            }}
          >
            {name}
          </Box>
          <Box
            sx={{
              fontSize: [1, 1, 1, 2],
              ml: ml,
              pt: ['2px'],
              pb: [1],
              color: 'secondary',
            }}
          >
            {'Budget:'}
            <Box sx={{
              display: 'inline-block',
              fontFamily: 'heading',
              textTransform: 'uppercase',
              color: 'primary',
              ml: [3]
            }}>
              <NumberFormat
                thousandsGroupStyle="thousand"
                value={ budget }
                prefix="$"
                decimalSeparator="."
                displayType="text"
                type="text"
                thousandSeparator={true}
              />
            </Box>
          </Box>
          <Box
            sx={{
              fontSize: [1, 1, 1, 2],
              ml: ml,
              pt: ['2px'],
              pb: [1],
              color: 'secondary',
            }}
          >
            {'Spend MTD:'}
            <Box sx={{
              display: 'inline-block',
              fontFamily: 'heading',
              textTransform: 'uppercase',
              color: 'primary',
              ml: [3]
            }}>
              { spend }
            </Box>
          </Box>
        </Column>
        <Column start={[1, 3, 4, 4]} width={[3, 2, 3, 3]}>
          <Box>Signals</Box>
          <Box sx={{
            fontFamily: 'mono',
            letterSpacing: 'mono',
            fontSize: [64],
            textTransform: 'uppercase',
            mt: ['3px'],
            userSelect: 'none',
          }}> 
          <Typewriter
            options={{
              strings: [String(campaign_triggers.length).padStart(3, '0')],
              autoStart: true,
              loop: true,
              cursor:' ',
              pauseFor: 100000
            }}
            />
          </Box>
        </Column>
        <Column start={[1, 5, 7, 7]} width={[3, 2, 3, 3]}>
          <Box>Actions</Box>
          <Box sx={{
            fontFamily: 'mono',
            letterSpacing: 'mono',
            fontSize: [64],
            textTransform: 'uppercase',
            mt: ['3px'],
            userSelect: 'none',
          }}> 
          <Typewriter
            options={{
              strings: [String(campaign_actions.length).padStart(3, '0')],
              autoStart: true,
              loop: true,
              cursor:' ',
              pauseFor: 100000
            }}
            />
          </Box>
        </Column>
      </Row>
    </Box>
  )
}

export default memo(Report)
