import { useThemeUI, Box, Flex } from 'theme-ui';
import { Row, Column } from '@carbonplan/components';
import TweetElement from './TweetElement';
import NewsElement from './NewsElement';
import RedditElement from './RedditElement';
import NewsTable from './NewsTable';

const sx = {
  highlight: {
    mb: [3, 3, 3, 4],
    fontSize: [3, 3, 3, 4],
    fontFamily: 'heading',
    letterSpacing: 'smallcaps',
    textTransform: 'uppercase',
    color: 'secondary',
  },
  date: {
    color: 'secondary',
    fontSize: [1, 1, 1, 2],
    fontFamily: 'mono',
    letterSpacing: 'mono',
    textTransform: 'uppercase',
    transition: 'opacity 0.15s',
  },
}

function HighlightsComponent({news, type, position, startDayNewsSelected, endDayNewsSelected, loadingSpinnerNewsArticles}) {
  const themeUI  = useThemeUI();

  if(position === 'holdingsSidebar'){
    return(
      <Row columns={[0, 0, 2, 2]} sx={{ mb: [4, 5, 6, 7] }}>
        <Column start={[1, 1, 1, 1]} sx={{ mb: [4, 0, 5, 6] }} width={[0, 0 ,2 ,2]}>
          <Box sx={sx.highlight}>Press highlights</Box>
          {news && news.map((d, i) => {
            return (
              <Box key={d.id}>
                { type === 'twitter' && (<TweetElement key={d.id}></TweetElement>)}
                { type === 'news' && (
                  <NewsElement 
                    key={d.id} 
                    newArticle={d} 
                    paddingX={[0]} 
                    >
                  </NewsElement>
                )}
                { type === 'reddit' && (<RedditElement key={d.id}></RedditElement>)}

              </Box>
            )
          })}
        </Column>
      </Row>
    )
  }

  if(type === 'volume' || type === 'price'){
    return(
      <NewsTable news={news} startDayNewsSelected={startDayNewsSelected} endDayNewsSelected={endDayNewsSelected} loadingSpinnerNewsArticles={loadingSpinnerNewsArticles}></NewsTable>
    )
  }

  return (
    <Row columns={[3, 6, 6, 6]} sx={{ mb: [4, 5, 6, 7] }}>
        <Column start={[1]} sx={{ mt: [3] }} width={[3, 6, 6, 6]}>
          <Flex sx={{justifyContent: 'space-between'}}>
            <Box sx={sx.highlight}>Press highlights</Box>  

            <Box sx={sx.highlight}>
              {startDayNewsSelected ?
                  new Date(startDayNewsSelected).toLocaleDateString("en-US", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })
                : 
                  ''
              }
            </Box>  
          </Flex>
        </Column>
        {news && news.map((d, i) => {
          return (
            <Column
              key={d.id}
              start={[1, i % 3 ===  0 ? 1 : (i % 3) + (i % 3 + 1) , i % 3 ===  0 ? 1 : (i % 3) + (i % 3 + 1), i % 3 ===  0 ? 1 : (i % 3) + (i % 3 + 1)]}
              width={[3, 2, 2, 2]}
              // sx={{
              //   display: i < 3 ? 'block' : ['none', 'none', 'none', 'none'],
              // }}
              sx={{
                paddingTop:[2, 0, 0, 0],
              }}
            >
              <Box sx={{
                borderLeft: ['', i % 3 !== 0 ? '1px': '', i % 3 !== 0 ? '1px': '', i % 3 !== 0 ? '1px': ''], 
                borderStyle: ['solid','solid', 'solid', 'solid'], 
                borderColor: [themeUI.theme.colors.muted, themeUI.theme.colors.muted, themeUI.theme.colors.muted, themeUI.theme.colors.muted], 
                marginY:[1]
              }}>
                { type === 'twitter' && (<TweetElement key={d.id}></TweetElement>)}
                { type === 'news' && (<NewsElement key={d.id} paddingX={[2]} newArticle={d}></NewsElement>)}
                { type === 'reddit' && (<RedditElement key={d.id}></RedditElement>)}
              </Box>
            </Column>
          )
        })}
      </Row>
    
  )
}

export default HighlightsComponent;

