import * as React from 'react';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useThemeUI } from 'theme-ui';

export default function AlertComponent({type, title, text, strongText, sx}) {
  const themeUI  = useThemeUI();

  let color;
  if(type && type==='error') color = themeUI.theme.colors.red;
  if(type && type==='warning') color = themeUI.theme.colors.yellow;
  if(type && type==='info') color = themeUI.theme.colors.blue;
  if(type && type==='success') color = themeUI.theme.colors.green;
  

  const theme = createTheme({
    components:{
      MuiAlert:{
        styleOverrides: {
         root:{
            borderRadius: '0px',
            backgroundColor: themeUI.theme.colors.background,
            color: color
            
          }
        },
      }
    }
  });

  if(type && type==='error'){
    return(
      <ThemeProvider theme={theme}>
        <Alert sx={sx} severity="error">
          {title && <AlertTitle>{title}</AlertTitle>}
          {text} {strongText && <strong> {strongText} </strong>}
        </Alert>
      </ThemeProvider>
    ) 
  }
  if(type && type==='warning'){
    return(
      <ThemeProvider theme={theme}>
        <Alert sx={sx} severity="warning">
          {title && <AlertTitle>{title}</AlertTitle>}
          {text} {strongText && <strong> {strongText} </strong>}
        </Alert>
      </ThemeProvider>
    ) 
  }
  if(type && type==='info'){
    return(
      <ThemeProvider theme={theme}>
        <Alert sx={sx} severity="info">
          {title && <AlertTitle>{title}</AlertTitle>}
          {text} {strongText && <strong> {strongText} </strong>}
        </Alert>
      </ThemeProvider>
    ) 
  }
  if(type && type==='success'){
    return(
      <ThemeProvider theme={theme}>
        <Alert sx={sx} severity="success">
          {title && <AlertTitle>{title}</AlertTitle>}
          {text} {strongText && <strong> {strongText} </strong>}
        </Alert>
      </ThemeProvider>
    ) 
  }
}