import { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useThemeUI  } from 'theme-ui';
import { TextField } from '@mui/material';
import campaignsService from '../services/CampaignsService';
import NumberFormatCustom from '../helpers/NumberFormatCustom';



export default function AddBotModal({
  openModal, 
  handleClose
}) {
  const themeUI  = useThemeUI();
  const theme = createTheme({
    components:{
      MuiPaper:{
        styleOverrides: {
          root: {
            color: themeUI.theme.colors.text,
            background:themeUI.theme.colors.background
          },
        },
      },
      MuiTypography:{
        styleOverrides: {
          root:{
            color: themeUI.theme.colors.text + '!important',
          }
        }
      },
      MuiFormLabel:{
        styleOverrides: {
          root:{
            color: themeUI.theme.colors.text + '!important',
          }
        }
      },
      MuiSvgIcon:{
        styleOverrides: {
          root:{
            color: themeUI.theme.colors.text + '!important',
          }
        }
      },
      MuiOutlinedInput:{
        styleOverrides: {
          root: {
            ':hover .MuiOutlinedInput-notchedOutline': {
              borderColor: themeUI.theme.colors.muted,
            },
            color: themeUI.theme.colors.text + '!important',
          },
          notchedOutline:{
            borderColor: themeUI.theme.colors.text,
           },
        }
      },
    }
    
  });

  var [campaignName, setCampaignName] = useState();
  var [budget, setBudget] = useState();
  
  const handleAdd = () => {
    campaignsService.postCampaign(campaignName, budget)
    .then(response =>{
      handleClose();
    })
  };

  const handleChangeName = (event) => {
    setCampaignName(event.target.value);
  };

  const handleChangeButget = (event) => {
    setBudget(event.target.value);  
  }
  
  return (
    <>
      <ThemeProvider theme={theme}>
      <Dialog 
        open={openModal} 
        onClose={handleClose}
        fullWidth={true}
        maxWidth={'sm'}
        >
        <DialogTitle>Add new bot</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{
              mb:[2]
            }}>
            Please enter the new bot name:
          </DialogContentText >
          <TextField onChange={handleChangeName}/>
          <DialogContentText sx={{
              mb:[2],
              mt: [3]
            }}>
            Budget:
          </DialogContentText >
          <TextField
            value={budget}
            onChange={handleChangeButget}
            name="numberformat"
            id="formatted-numberformat-input"
            InputProps={{
              inputComponent: NumberFormatCustom,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleAdd}>Add</Button>
        </DialogActions>
      </Dialog>
      </ThemeProvider >
    </>
  );
}
