import Cookies from 'universal-cookie';
import handleResponse from '../helpers/HandleResponse';
const cookies = new Cookies();

let eventsService = {
  getTriggerLogs,
  
};

function getTriggerLogs (params) {
  return fetch(`${process.env.REACT_APP_API_URL}trigger_logs${params ? `?${new URLSearchParams(params)}`:''}`,{
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + cookies.get('jwt')
    },
    method: 'GET'
  })
  .then(response => {
    return handleResponse(response)
  })
  .then(respose => {
    //Filter the more recents events
    respose.sort(function(a,b){
      return new Date(b.created_at) - new Date(a.created_at);
    });
    return respose.splice(0,9)
  })
  .catch(error => console.warn(error));
}


export default eventsService;