import Cookies from 'universal-cookie';
import handleResponse from '../helpers/HandleResponse';
const cookies = new Cookies();

let holdingsService = {
  getHoldings,
  getChartData,
  getNews
};

function getHoldings (params) {
  return fetch(`${process.env.REACT_APP_API_URL}cat_etfs/${params.slug}`,{
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + cookies.get('jwt')
    },
    method: 'GET'
  })
  .then(response => {
    return handleResponse(response)
  })
  .then(res =>{
    let randomTags = ['social', 'reddit', 'twitter', 'earnings', 'news']
    res.holdings = res.cat_etf_holdings.map(r=>{
      r.tags = randomTags
      return r
    })

    return res
  })
  .catch(error => console.warn(error));
}

function getChartData (symbol, params) {
  return fetch(`${process.env.REACT_APP_API_URL}cat_etf_holdings/${symbol}/getSocialVolume?${new URLSearchParams(params)}`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + cookies.get('jwt')
    }
  })
  .then(response => {
    return handleResponse(response)
  })
  .catch(error => console.warn(error));
}

function getNews (symbol, params) {
  return fetch(`${process.env.REACT_APP_API_URL}cat_etf_holdings/${symbol}/getNews?${new URLSearchParams(params)}`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + cookies.get('jwt')
    }
  })
  .then(response => {
    return handleResponse(response)
  })
  .then(res=>{
    let data = res[0].records.slice(0,9).map(r=> r.values);
    data.forEach(r=>{
      r.id = r._time;
      var date  = new Date(r._time);
      let year = date.getFullYear();
      let month = (1 + date.getMonth()).toString().padStart(2, '0');
      let day = date.getDate().toString().padStart(2, '0');
      r.date = month + '/' + day + '/' + year;
    })
    data.sort((a, b) => {
      return  b._value_views - a._value_views;
    })
    return data
  })
  .catch(error => console.warn(error));
}


export default holdingsService;