import React, { useState, useEffect } from 'react';
import { Row, Column } from '@carbonplan/components'
import Top from './top';
import Sidebar from './sidebar';
import ListCrypto from './listCrypto';
import cryptoService from '../services/CryptoService';

const initFilters = {
  search: '',
  reddit: true,
  twitter: true,
  total: true
}

function Home ({settingsExpanded}) {

  var [cryptos, setCryptos] = useState([]);
  const [filters, setFilters] = useState(initFilters)
  const [filtered, setFiltered] = useState({ count: 0, init: false })
  const [openModal, setOpenModal] = useState(false);

  

  useEffect(
    () => {
      if(openModal === false){
        cryptoService.getAccountCryptos()
        .then(response=>{
          response.map(r=> {
            r.tags = [r.tracking_type.split("_").pop()];  
            r.name = r.cat_crypto.name;
            //Fake Value
            r.value = (((Math.random() - 0.5) * 2) * 10).toFixed(2);
            return r
          })
        setCryptos(response)
      })
      }
      
  },[openModal])

  useEffect(() => {
    let obj = {}
    let count = 0
    cryptos.forEach((h) => {
      obj[h.id] = inFilter(h)
      if (obj[h.id]) {
        count += 1
      }
    })
    obj.count = count
    obj.init = true
    setFiltered(obj)
  }, [filters, cryptos])

  function inFilter (h) {

    let inTags = false
    if (h.tags.length>0) {
      for (const property in filters) {
        if(filters[property] && h.tags.includes(property)) inTags = true
      }
    }
    const searchTerm = filters.search.toLowerCase()
    const inSearch =
      searchTerm.length > 0 &&
      (h.name.toLowerCase().includes(searchTerm) ||
        (h.symbol && h.symbol.toLowerCase().includes(searchTerm)))
    if (filters.search.length > 0 && inSearch && inTags) return true
    if (filters.search.length === 0 && inTags) return true
    else return false
  }
  
  return (
    <Row columns={[3, 6, 9, 9]}>
      <Column start={[1]} width={[0, 6, 3, 3]}>
        <Sidebar 
          settingsExpanded={settingsExpanded} 
          filters={filters}
          setFilters={setFilters}
          />
      </Column>
      <Column start={[1, 1, 4, 4]} width={[3, 6, 6, 6]}>
        <Top data={cryptos} filtered={filtered} openModal={openModal} setOpenModal={setOpenModal}/>
        <ListCrypto data={cryptos} filtered={filtered} ></ListCrypto> 
      </Column>
    </Row>
  )
}

export default Home;