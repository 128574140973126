import { BehaviorSubject } from 'rxjs';
import Cookies from 'universal-cookie';

const cookies = new Cookies()
const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));

let authenticationService = {
    login,
    logout,
    signup,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue () { return currentUserSubject.value }
};

function login(username, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: username, password })
    };

    return fetch(`${process.env.REACT_APP_API_URL}auth/login`, requestOptions)
        .then(response => {
            return response.text()
        })
        .then(data => {
            data = JSON.parse(data)
            if (data.access_token) {
                cookies.set('jwt', data.access_token, { path: '/' });
                localStorage.setItem('currentUser', 'true');
            }
            return data
        });
        

}

function signup(name, email, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ name, email, password })
    };

    return fetch(`${process.env.REACT_APP_API_URL}user-create`, requestOptions)
        .then(response => {
            return response.text()
        })
        .then(data => {
            console.log(data)
        });
}

function logout() {
    cookies.remove('jwt')
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser'); 
    localStorage.clear();
    window.location = '/';
}

export default authenticationService;