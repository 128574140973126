import { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useThemeUI  } from 'theme-ui';
import Box from '@mui/material/Box';
import ComboBox from './Autocomplete';
import AlertComponent from './AlertComponent';
import accountsService from '../services/AccountsService';


export default function SelectGoogleaccountModal({
  openModal, 
  handleClose,
  googleAdsAccountsList,
  accountId,
  setOpenModal
}) {
  const themeUI  = useThemeUI();
  const theme = createTheme({
    components:{
      MuiPaper:{
        styleOverrides: {
          root: {
            color: themeUI.theme.colors.text,
            background:themeUI.theme.colors.background
          },
        },
      },
      MuiTypography:{
        styleOverrides: {
          root:{
            color: themeUI.theme.colors.text + '!important',
          }
        }
      },
      MuiFormLabel:{
        styleOverrides: {
          root:{
            color: themeUI.theme.colors.text + '!important',
          }
        }
      },
      MuiSvgIcon:{
        styleOverrides: {
          root:{
            color: themeUI.theme.colors.text + '!important',
          }
        }
      },
      MuiOutlinedInput:{
        styleOverrides: {
          root: {
            ':hover .MuiOutlinedInput-notchedOutline': {
              borderColor: themeUI.theme.colors.muted,
            },
            color: themeUI.theme.colors.text + '!important',
          },
          notchedOutline:{
            borderColor: themeUI.theme.colors.text,
           },
        }
      },
      MuiDialogContent:{
        styleOverrides: {
          root: {
            paddingBottom: '150px'
          }
        }
      }
    }
    
  });

  var [alertGoogleAdsAcount, setAlertGoogleAdsAcount] = useState(false);
  var [googleAdsAcount, setGoogleAdsAcount] = useState(null);
  
  const handleAdd = () => {
    console.log(googleAdsAcount.value)
    accountsService.putAccountActionableTypes(accountId, googleAdsAcount.value)
    .then(res=>{
      console.log(res)
      setOpenModal(false);
    })
    
  };


  
  return (
    <>
      <ThemeProvider theme={theme}>
      <Dialog 
        open={openModal} 
        onClose={handleClose}
        fullWidth={true}
        maxWidth={'sm'}
        >
        <DialogTitle>Select Google Adds Account</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{
              mb:[2]
            }}>
            Please choose the Google Account to work with:
          </DialogContentText >
          <Box sx={{
            display: 'flex',
            alignItems: 'center'
          }}>
            <ComboBox 
              data={googleAdsAccountsList} 
              label={'Google Ads Accounts'}
              value={googleAdsAcount}
              setValue= {setGoogleAdsAcount}
              setAlert={setAlertGoogleAdsAcount}
              sx={{
                width: 300,
                marginRight: '10px'
              }}
            />
            {alertGoogleAdsAcount && 
              <AlertComponent 
                type={'error'} 
                strongText={'Select a Google Ads account.'} 
                sx={{width: 250}}
              />}
          </Box>
          
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAdd}>Add</Button>
        </DialogActions>
      </Dialog>
      </ThemeProvider >
    </>
  );
}
