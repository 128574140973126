import { memo } from 'react';
import { useHistory } from 'react-router-dom';
import { useThemeUI, Box, Flex } from 'theme-ui';
import { Tag } from '@carbonplan/components';
import ArrowIndicator from '../components/ArrowIndicator';

const Item = ({ data, setHighlighted }) => {
  const { theme } = useThemeUI();
  const history = useHistory(); 

  const sx = {
    label: {
      fontSize: ['12px', '15px', '15px', '15px'],
      pt: ['2px'],
      pb: [1],
      color: 'secondary'
    }
  }
  
  let {
    id,
    name,
    symbol
  } = data;

  return (
    <Box
      onClick={(e) => {
        history.push(`/stock/${symbol}/volume`);
      }}
      onMouseEnter={() => {
        if (setHighlighted) setHighlighted(id)
      }}
      onMouseLeave={() => {
        if (setHighlighted) setHighlighted(null)
      }}
      sx={{
        borderStyle: 'solid',
        borderColor: 'muted',
        borderWidth: '0px',
        borderBottomWidth: ['1px', '1px', '0px'],
        borderLeftWidth: ['0px', '0px', '1px'],
        borderRadius: '0px',
        cursor: 'pointer',
        pl: [0, 0, 0],
        pr: [0],
        pt: ['0px', '0px', '12px'],
        my: [3],
        mb: [3, 3, '24px'],
        transition: 'border-color 0.15s',
        '&:hover': {
          borderColor: ['muted', 'muted', 'secondary'],
        },
        '@media (hover: hover) and (pointer: fine)': {
          '&:hover > #container > #box > #flex > #text > #expander': {
            stroke: 'primary',
          },
        },
        '@media (hover: none) and (pointer: coarse)': {
          '#container > #box > #flex > #text > #expander': {
            stroke: 'secondary',
          },
        },
      }}
    >
      <Flex
        id='container'
        sx={{
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          mt: ['-6px'],
          ml: [0, 0, '24px', '36px'],
        }}
      >
        <Flex
          sx={{
            width: '75%', 
            justifyContent: 'space-between',
            pr:[2]
          }}>
          <Box sx={{ mt: ['6px']}}>
            <Flex
              id='flex'
              sx={{
                flexWrap: 'wrap',
                ml: [-2],
              }}
            >
              <Box
                id='text'
                sx={{
                  display: 'inline-block',
                  fontSize: [4, 4, 4, 5],
                  lineHeight: 'heading',
                  ml: [2],
                }}
              >
                {symbol}
              </Box>
              <Box
                sx={{
                  fontSize: [3, 3, 3, 4],
                  lineHeight: 'heading',
                  ml: [2],
                  fontFamily: 'mono',
                  letterSpacing: [0],
                  color: 'primary',
                  mt: ['2px'],
                  whiteSpace: 'nowrap',
                }}
              >
                {data.percent.toFixed(2) + ' %'}
              </Box>
            </Flex>
            <Box
              sx={{
                display: ['none', 'inline-block', 'inline-block', 'inline-block'],
                fontSize: [1, 1, 1, 2],
                pt: ['2px'],
                pb: [1],
                color: 'secondary',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                maxWidth: '100%'
              }}
            >
              {name}
            </Box>
          </Box>
          <Flex sx={{
            flexWrap: 'wrap',
            justifyContent: 'flex-end'
          }}>
            <Flex
              sx={{
                flexDirection: 'column',
                alignItems: 'end',
                marginX: [2, 2, 3, 3]
              }}
            >
              <Flex>
                <Box
                  sx={{
                    fontSize: [3, 3, 3, 4],
                    lineHeight: 'heading',
                    fontFamily: 'mono',
                    letterSpacing: [0],
                    textTransform: 'uppercase',
                    color: 'primary',
                    mt: ['2px'],
                    userSelect: 'none',
                  }}
                >
                  { 3.4.toFixed(2) + ' %'}
                </Box>
                <ArrowIndicator value={1}></ArrowIndicator>
              </Flex>
              <Tag
                label={'social'}
                value={true}
                sx={{
                  cursor: 'inherit',
                  color: theme.tags['social'],
                }}
              >
                {'social'}
              </Tag>
            </Flex>
            <Flex
              sx={{
                flexDirection: 'column',
                alignItems: 'end',
                marginX: [2, 2, 3, 3]
              }}
            >
              <Flex>
                <Box
                  sx={{
                    fontSize: [3, 3, 3, 4],
                    lineHeight: 'heading',
                    fontFamily: 'mono',
                    letterSpacing: [0],
                    textTransform: 'uppercase',
                    color: 'primary',
                    mt: ['2px'],
                    userSelect: 'none',
                  }}
                >
                  { 3.4.toFixed(2) + ' %'}
                </Box>
                <ArrowIndicator value={-1}></ArrowIndicator>
              </Flex>

              <Tag
                label={'news'}
                value={true}
                sx={{
                  cursor: 'inherit',
                  color: theme.tags['news'],
                }}
              >
                {'news'}
              </Tag>
            </Flex>
            <Flex
              sx={{
                flexDirection: 'column',
                alignItems: 'end',
                marginX: [2, 2, 3, 3]
              }}
            >
              <Flex>
                <Box
                  sx={{
                    fontSize: [3, 3, 3, 4],
                    lineHeight: 'heading',
                    fontFamily: 'mono',
                    letterSpacing: [0],
                    textTransform: 'uppercase',
                    color: 'primary',
                    mt: ['2px'],
                    userSelect: 'none',
                  }}
                >
                  { 3.4.toFixed(2) + ' %'}
                </Box>
                <ArrowIndicator value={1}></ArrowIndicator>
              </Flex>
              <Tag
                label={'market'}
                value={true}
                sx={{
                  cursor: 'inherit',
                  color: theme.tags['market'],
                }}
              >
                {'market'}
              </Tag>
            </Flex>

          </Flex>
        </Flex>
        <Box 
          sx={{
            width: '25%', 
            borderStyle: 'solid',
            borderColor: 'muted',
            borderLeftWidth: ['1px'],
            transition: 'border-color 0.15s',
            '&:hover': {
              borderColor: ['muted', 'muted', 'secondary'],
            },
            paddingLeft: [2]
          }}>
            <Flex sx={{
              justifyContent: 'flex-start',
              flexWrap: 'wrap'
            }}
            >
              <Box sx={{marginX: [1]}}>
                <Box sx={{...sx.label }}>Active:</Box>
                <Box sx={{fontSize: sx.label.fontSize}}>3/13/22 - 3/17/22</Box>
              </Box>
              <Box sx={{marginX: [1]}}>
                <Box sx={{...sx.label }}>Outlets:</Box>
                <Box sx={{fontSize: sx.label.fontSize}}>Google</Box>
              </Box>
              <Box sx={{marginX: [1]}}>
                <Box sx={{...sx.label }}>Triggered By:</Box>
                <Box sx={{fontSize: sx.label.fontSize}}>Twitter Volume</Box>
              </Box>
            </Flex>
                        
        </Box>
      </Flex>
      </Box>
  )
}

export default memo(Item)