import { memo } from 'react';
import { Box, Flex } from 'theme-ui';


const itemAction = ({ data, setHighlighted, tooltips, embed, idx,  setActionRecord, setOpenActionsModal }) => {

  let {
    id,
    name,
  } = data
  
  
  var sx = {
    label: {
      fontFamily: 'mono',
      letterSpacing: 'mono',
      fontSize: [1, 1, 1, 2],
      textTransform: 'uppercase',
      color: 'secondary',
      mt: ['3px'],
      userSelect: 'none',
    }
  }


  const ml = [0, 0, '24px', '36px']

  return (
    <Box
      onMouseEnter={() => {
        if (setHighlighted) setHighlighted(id)
      }}
      onMouseLeave={() => {
        if (setHighlighted) setHighlighted(null)
      }}
      sx={{
        borderStyle: 'solid',
        borderColor: 'muted',
        borderWidth: '0px',
        borderBottomWidth: embed
          ? ['0px', '0px', '0px']
          : ['1px', '1px', '0px'],
        borderLeftWidth: embed ? [0, 0, 0] : ['0px', '0px', '1px'],
        borderRadius: '0px',
        pl: embed ? [0, 0, 0] : [0, 0, 0],
        pr: [0],
        pt: embed ? [0, 0, 0] : ['0px', '0px', '12px'],
        my: [embed ? 0 : 3],
        mb: embed ? [0, 0, 0] : [3, 3, '24px'],
        transition: 'border-color 0.15s',
        '&:hover': {
          borderColor: ['muted', 'muted', 'secondary'],
        },
        '@media (hover: hover) and (pointer: fine)': {
          '&:hover > #container > #box > #flex > #text > #expander': {
            stroke: 'primary',
          },
        },
        '@media (hover: none) and (pointer: coarse)': {
          '#container > #box > #flex > #text > #expander': {
            stroke: 'secondary',
          },
        },
      }}
    >
      <Box
        id='container'
        sx={{
          justifyContent: 'space-between',
          mt: ['-6px'],
          ml: ml,
        }}
      >
        <Box
          sx={{
            fontSize: [3, 3, 3, 4],
            lineHeight: 'heading',
            cursor: 'pointer',
            marginBottom: [1]
          }}
          onClick={(e) => {
            setActionRecord(data);
            setOpenActionsModal(true);
          }}
        >
          { name }
        </Box>
        <Flex sx={{
          justifyContent:'space-between'
        }}>
          <Box>
            <Box sx={{...sx.label, mb:[1]}}>Type</Box>
            <Box>WebHook</Box>
          </Box>
          <Box>
            <Box sx={{...sx.label, mb:[1]}}>Last Trigger</Box>
            <Box>Never</Box>
          </Box>
          <Box></Box>
        </Flex>
        
        
      </Box>
    </Box>            
    
  )
}

export default memo(itemAction)
