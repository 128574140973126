import { Box, Flex } from 'theme-ui';
import { Badge, FadeIn, Button } from '@carbonplan/components';
import AddAccountModal from '../components/AddAccountModal';

var sx = {
  label: {
    fontFamily: 'mono',
    letterSpacing: 'mono',
    fontSize: [1, 1, 1, 2],
    textTransform: 'uppercase',
    color: 'secondary',
    mt: ['3px'],
    userSelect: 'none',
  },
  title: {
    fontFamily: 'heading',
    letterSpacing: 'smallcaps',
    fontSize: [3, 3, 3, 4],
    textTransform: 'uppercase',
    color: 'primary',
  }
}

const Top = ({ data, openModal, setOpenModal }) => {
  
  const handleClose = () => {
    setOpenModal(false);
  };
  
  const handleClickOpen = () => {
    setOpenModal(true);
  };
  
  return (
    <Box
      sx={{
        position: 'sticky',
        height: ['70px','58px','58px'],
        top: 56,
        pb: ['26px'],
        mb: [0],
        pt: [2],
        mr: ['-16px', 0, 0],
        pr: ['16px', 0, 0],
        pl: [0, 0, 0],
        bg: 'background',
        borderWidth: '0px',
        borderBottomWidth: '1px',
        borderColor: 'muted',
        borderStyle: 'solid',
        userSelect: 'none',
        zIndex: 1,
      }}
    >
      <Box sx={{ mt: ['8px'] }}>
        <Flex sx={{ 
            alignItems: 'center',
            justifyContent: 'space-between',
            flexWrap: 'wrap'
          }}>
            <Box>
              <Box
                sx={{
                  display: 'inline-block',
                  mr: [2],
                  ...sx.title,
                }}
              >
                Accounts
              </Box>
              <Box
                  sx={{
                    display: 'inline-block',
                    mr: [2],
                    fontSize: [1],
                    ...sx.label,
                  }}
                >
                Total
              </Box>
              <Box sx={{ display: 'inline-block', width: '40px' }}>
                {data && (
                  <FadeIn as='span' delay={10} duration={150}>
                    <Badge sx={{ ml: [2], fontSize: [3] }}>
                      {String(data.length).padStart(3, '0')}
                    </Badge>
                  </FadeIn>
                )}
              </Box>
            </Box>
   
            <Box >
              <Button
                onClick={handleClickOpen}
                sx={{ 
                  borderWidth:[0],
                  borderBottomWidth: [1],
                  borderStyle: ['solid'],
                  borderColor: ['text'],
                  paddingBottom: [2],
                  '&:hover': {
                    borderColor: ['muted'],
                    borderBottomWidth: [1],
                  },
                  textOverflow: 'ellipsis',
                  overflow: 'hidden', 
                  whiteSpace: 'nowrap',
                  fontSize: [16]
                }}
              >
                Add New Account +
              </Button>
              <AddAccountModal
              openModal={openModal} 
              setOpenModal={setOpenModal} 
              handleClose={handleClose}
            ></AddAccountModal> 
            </Box>
        </Flex>
      </Box>
    </Box>
  )
}

export default Top
