import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { Row, Column } from '@carbonplan/components'
import { Box } from 'theme-ui';
import Top from './top';
import Sidebar from './sidebar';
import ListAccountsCompaigns from './listAccountsCompaigns';
import AccountsService from '../services/AccountsService';
import Modal from '../components/SelectGoogleAccountModal';
import Spinner from '../components/Spinner';



const initFilters = {
  search: '',
}

function AccountsCompaigns ({settingsExpanded}) {
  const [account, setAccount] = useState();
  const [accountsCompaigns, setAccountsCompaigns] = useState([]);
  const [filters, setFilters] = useState(initFilters)
  const [filtered, setFiltered] = useState({ count: 0, init: false })
  const [orderPercentDescending, setOrderPercentDescending] = useState(true);
  const [orderPercentAscending, setOrderPercentAscending] = useState(false);
  const { id } = useParams();
  //const [dataTags, setDataTags] = useState([])
  const [googleAdsAccountsList, setGoogleAdsAccountsList] = useState([])
  const [openModal, setOpenModal] = useState(false);
  const [loadingSpinner, setLoadingSpinner] = useState(false);

  useEffect(
    () => {
      setLoadingSpinner(true);
      AccountsService.getAccount(id)
      .then(response=>{
        if(response){
          setAccount(response);
          if(!response.data.selected_google_ads_account){
            let arrayGoogleAdsAccounts = []
            for (const property in response.data.available_google_ads_accounts) {
              arrayGoogleAdsAccounts.push({
                value: property,
                label: `${property} - ${response.data.available_google_ads_accounts[property].name}`
              })
            }
            setGoogleAdsAccountsList(arrayGoogleAdsAccounts)
            setOpenModal(true);
            setLoadingSpinner(false);
          }else{
            setAccountsCompaigns(response.data.available_google_ads_accounts[response.data.selected_google_ads_account].campaigns)
            // setAccountsCompaigns([
            //   {id: '1', name: 'Campaign 1', status: 'Active', cost: '10', impressions: 10, clicks: 1},
            //   {id: '2', name: 'Campaign 2', status: 'Paused', cost: '20', impressions: 20, clicks: 2},
            //   {id: '3', name: 'Campaign 3', status: 'Active', cost: '30', impressions: 30, clicks: 3}
            // ])
            setLoadingSpinner(false);
          }
        }
        
      })
    },[id])

  useEffect(() => {
    let obj = {}
    let count = 0
    accountsCompaigns.forEach((h) => {
      obj[h.id] = inFilter(h)
      if (obj[h.id]) {
        count += 1
      }
    })
    obj.count = count
    obj.init = true
    setFiltered(obj)
  }, [filters, accountsCompaigns])

  useEffect(() => {
    if (orderPercentDescending) sortDescending([...accountsCompaigns])
    if (orderPercentAscending) sortAscending([...accountsCompaigns])
  }, [orderPercentDescending, orderPercentAscending])

  // useEffect(() => {
  //   let objFilters = {...filters}
  //   dataTags.forEach(t=>{
  //     objFilters[t] = true;
  //   })
  //   setFilters(objFilters)
  // },[dataTags])

  function sortDescending(accountsCompaigns) {
    setAccountsCompaigns(
      accountsCompaigns
        .sort((a, b) => {
          return b.percent - a.percent;
        }))
  }

  function sortAscending(accountsCompaigns){
    setAccountsCompaigns(
      accountsCompaigns
      .sort((a, b) => {
        return a.percent - b.percent;
      }))
  }
  
  function inFilter (h) {
    //let inTags = false
    // if (h.tags.length>0) {
    //   for (const property in filters) {
    //     if(filters[property] && h.tags.includes(property)) inTags = true
    //   }
    // }

    // const inTags =
    //   (filters.reddit && h.tags.length > 0 && h.tags.includes('reddit')) ||
    //   (filters.twitter && h.tags.length > 0 && h.tags.includes('twitter')) ||
    //   (filters.news && h.tags.length > 0 && h.tags.includes('news')) ||
    //   (filters.earnings && h.tags.length > 0 && h.tags.includes('earnings'))

    const searchTerm = filters.search.toLowerCase()
    const inSearch =
      searchTerm.length > 0 &&
      (h.name.toLowerCase().includes(searchTerm) ||
        (h.symbol && h.symbol.toLowerCase().includes(searchTerm)))
    if (filters.search.length > 0 && inSearch /*&& inTags*/) return true
    if (filters.search.length === 0 /*&& inTags*/) return true
    else return false
  }

  const handleClose = () => {
    
  };
  
  return (
    <Row columns={[3, 6, 9, 9]}>
      <Column start={[1]} width={[2, 6, 3, 3]}>
        <Sidebar
          filtered={filtered}
          account={account}
          data={accountsCompaigns}
          filters={filters}
          setFilters={setFilters}
          settingsExpanded={settingsExpanded}
          orderPercentDescending = {orderPercentDescending}
          setOrderPercentDescending={setOrderPercentDescending}
          orderPercentAscending = {orderPercentAscending}
          setOrderPercentAscending={setOrderPercentAscending}
          //dataTags={dataTags}
        />
      </Column>
      <Column start={[1, 1, 4, 4]} width={[3, 6, 6, 6]}>
        {
          loadingSpinner && (
            <Box sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              mt:[5, 10, 10, 10]
            }}>
              <Spinner></Spinner>
            </Box>
          )
        }
        {
          !loadingSpinner && (
            <>
              <Top data={accountsCompaigns} filtered={filtered}/>
              <ListAccountsCompaigns 
                data={accountsCompaigns} 
                filtered={filtered} 
              />
              <Modal 
                openModal={openModal} 
                setOpenModal={setOpenModal} 
                handleClose={handleClose}
                googleAdsAccountsList={googleAdsAccountsList}
                accountId={id}
              ></Modal>
            </>
          )
        }
        
        
      </Column>
    </Row>
  )
}

export default AccountsCompaigns;