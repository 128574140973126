import React, { useState, useEffect } from 'react';
import { Row, Column } from '@carbonplan/components'
import Top from './top';
import ListCampaigns from './listCampaigns';
import campaignsService from '../services/CampaignsService';
import ListEvents from './listEvents';
import eventsService from '../services/EventsService';




function Home ({settingsExpanded}) {

  var [campaigns, setCampaigns] = useState();
  var [events, setEvents] = useState();
  var [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if(openModal === false){
      campaignsService.getCampaigns()
      .then(response => {
        setCampaigns(response)      
      })
    }
  },[openModal])

  useEffect(() => {
    if(openModal === false){
      eventsService.getTriggerLogs()
      .then(response => {
        setEvents(response)
      })
    }
  },[openModal])
  
  return (
    <Row columns={[3, 6, 9, 9]}>
      <Column start={[1, 1, 1, 1]} width={[3, 6, 9, 9]}>
        <Top title={'Bots'} data={campaigns} openModal={openModal} setOpenModal={setOpenModal} addCampaignButton={true}/>
        <ListCampaigns data={campaigns}></ListCampaigns> 
      </Column>
      <Column start={[1, 1, 1, 1]} width={[3, 6, 9, 9]}>
        <Top title={'Events'} data={events} openModal={openModal} setOpenModal={setOpenModal}/>
        <ListEvents data={events} type={'home'}></ListEvents>
      </Column>
    </Row>
  )
}

export default Home;