import base from '@carbonplan/theme'
const Theme = {
  ...base,
  gradients: { 0: "linear-gradient(90deg, #009A63 0%, #09CF7C 100%)" },
  tags: {
    social: 'blue',
    reddit: 'red',
    twitter: 'blue',
    news: 'yellow',
    price: 'green',
    earnings: 'green',
    total: 'green',
    market: 'teal',
    volume: 'yellow'
  },
  //breakpoints: ['40em', '78em', '102em']
}

export default Theme;
