import { Box, Flex } from 'theme-ui';
import { Button } from '@carbonplan/components';
import AddSignalModal from './AddSignalModal';
import { Badge, FadeIn } from '@carbonplan/components';
import AddActionModal from './addActionModal';

var sx = {
  label: {
    fontFamily: 'mono',
    letterSpacing: 'mono',
    fontSize: [1, 1, 1, 2],
    textTransform: 'uppercase',
    color: 'secondary',
    mt: ['3px'],
    userSelect: 'none',
  },
  title: {
    fontFamily: 'heading',
    letterSpacing: 'smallcaps',
    fontSize: [3, 3, 3, 4],
    textTransform: 'uppercase',
    color: 'primary',
  }
}

const Top = ({ data, openSignalsModal, setOpenSignalsModal, openActionsModal, setOpenActionsModal, actionRecord, type}) => {

  return (
    <Box
      sx={{
        height: ['70px','58px','58px'],
        pt: [2],
        mr: ['-16px', 0, 0],
        pr: ['16px', 0, 0],
        pl: [0, 0, 0],
        borderBottomWidth: '1px',
        borderColor: 'muted',
        borderStyle: 'solid',
        userSelect: 'none',
      }}
    >

      <Flex sx={{
        alignItems: 'center',
        flexWrap: 'wrap',
        mt: ['8px'],
        justifyContent: 'space-between'
      }}>
        <Box sx={{
          display: 'inline-block',
        }}>
          <Box
            sx={{
              display: 'inline-block',
              mr: [2],
              ...sx.label,
            }}
          >
            {type.toLowerCase()}
            <FadeIn as='span' delay={10} duration={150}>
                <Badge sx={{ ml: [2], fontSize: [3], pt: ['1px', '1px', '1px','3px']}}>
                  {data && data.length.toString().padStart(3, '0')}
                </Badge>
            </FadeIn>
          </Box>
        </Box>
        <Box>
          {
            type==='signals' && (
              <>
                <Button
                  onClick={(e) => setOpenSignalsModal(true)}
                  sx={{
                    borderWidth:[0],
                    borderBottomWidth: [1],
                    borderStyle: ['solid'],
                    borderColor: ['text'],
                    paddingBottom: [2],
                    '&:hover': {
                      borderColor: ['muted'],
                      borderBottomWidth: [1],
                    },
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    fontSize: [16]
                  }}
                >
                  Add New Signal +
                </Button>
                <AddSignalModal
                  openModal={openSignalsModal}
                  setOpenModal={setOpenSignalsModal}
                  handleClose={(e)=>setOpenSignalsModal(false)}
                  campaignId={data ? data.id : undefined}
                ></AddSignalModal>
              </>
            )
          }
          {
            type==='actions' && (
              <>
                <Button
                  onClick={(e) => setOpenActionsModal(true)}
                  sx={{
                    borderWidth:[0],
                    borderBottomWidth: [1],
                    borderStyle: ['solid'],
                    borderColor: ['text'],
                    paddingBottom: [2],
                    '&:hover': {
                      borderColor: ['muted'],
                      borderBottomWidth: [1],
                    },
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    fontSize: [16]
                  }}
                >
                  Add New Action +
                </Button>
                <AddActionModal
                  openModal={openActionsModal}
                  record={actionRecord}
                  handleClose={(e) => {setOpenActionsModal(false)}}>
                </AddActionModal> 
              </>
            )
          }

        </Box>
      </Flex>

    </Box>
  )
}

export default Top
