import React, { useState } from 'react';
import { useHistory  } from "react-router-dom";
import { default as NextLink } from 'next/link';
import { Box, Container, Link } from 'theme-ui';
import { Arrow } from '@carbonplan/icons';
import { Row, Column, Menu, Settings, Dimmer } from '@carbonplan/components';
import Logo from './logo';
import authenticationService from '../services/AuthenticationService';


const sx = {
  link: (current, label, first = false) => {
    return {
      width: 'auto',
      color: current === label ? 'secondary' : 'text',
      fontSize: [6, 6, 7, 8],
      fontFamily: 'heading',
      letterSpacing: 'heading',
      borderStyle: 'solid',
      borderColor: 'muted',
      borderWidth: '0px',
      borderBottomWidth: '1px',
      borderTopWidth: first ? '1px' : '0px',
      py: [3, 3, 4, 5],
      textDecoration: 'none',
      display: 'block',
      position: 'relative',
      transition: 'color 0.15s',
      '@media (hover: hover) and (pointer: fine)': {
        '&:hover > #arrow': {
          opacity: 1,
        },
      },
      '&:hover': {
        color: current === label ? 'secondary' : 'text',
      },
    }
  },
}


const HoverArrow = () => {
  return (
    <Arrow
      id='arrow'
      sx={{
        pointerEvents: 'none',
        display: 'inline-block',
        position: 'absolute',
        left: ['-60px', '-68px', '-80px', '-104px'],
        top: ['32px', '32px', '46px', '62px'],
        opacity: 0,
        transition: 'opacity 0.2s ease-out',
        transform: 'rotate(45deg)',
        width: [36, 36, 48, 56],
        height: [36, 36, 48, 56],
      }}
    />
  )
}


const Header = ({ status, mode, nav, dimmer, settings, hideMenu }) => {
  const [expanded, setExpanded] = useState(false)
  const history = useHistory(); 

  const toggle = (e) => {
    setExpanded(!expanded)
  }

  return (
    <Row
      sx={{
        width: '100%',
        pt: ['12px'],
        pb: [3],
      }}
    >
      <Column start={[1]} width={[2]}>
        <Box sx={{ display: 'block', width: 'fit-content' }}>
          {(mode === 'homepage' || mode === 'local') && (
            <NextLink href='/' passHref>
              <Link
                aria-label='CarbonPlan Homepage'
                sx={{
                  display: 'block',
                }}
              >
                <Logo
                  id='logo'
                  sx={{
                    cursor: 'pointer',
                    color: 'primary',
                  }}
                />
              </Link>
            </NextLink>
          )}
          {(mode === null || mode === 'remote') && (
            <Link
              href='/'
              aria-label='CarbonPlan Homepage'
              sx={{ display: 'block', pt:1 }}
            >
              <Logo sx={{ cursor: 'pointer', color: 'primary' }} />
            </Link>
          )}
        </Box>
      </Column>
      <Column
        start={[4, 9]}
        width={[2, 2]}
        dr={1}
        sx={{
          display: [status ? 'flex' : 'none', 'flex', 'flex', 'flex'],
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            fontSize: [1, 2, 3],
            position: 'relative',
            top: ['-2px', '-3px', '-3px'],
          }}
        >
          {status ? `(${status})` : ''}
        </Box>
      </Column>
      <Column
        start={[status ? 6 : 4, 6, 12, 12]}
        width={[status ? 1 : 3, 3, 2, 2]}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        <Box
          sx={{
            mr: ['18px'],
            position: 'relative',
            top: ['-1px'],
            pointerEvents:
              expanded || (settings && settings.value) ? 'none' : 'all',
            transition: 'opacity 0.15s',
            display: [
              status ? 'none' : 'block',
              'block',
              dimmer === 'top' ? 'block' : 'none',
              dimmer === 'top' ? 'block' : 'none',
            ],
          }}
        >
          <Dimmer
            sx={{
              opacity: expanded || (settings && settings.value) ? 0 : 1,
              color: 'primary',
            }}
          />
        </Box>
        {settings && (
          <Box
            sx={{
              mr: ['21px'],
              position: 'relative',
              pointerEvents: expanded ? 'none' : 'all',
              transition: 'opacity 0.15s',
              display: [status ? 'none' : 'block', 'block', 'none', 'none'],
            }}
          >
            <Settings
              value={settings.value}
              onClick={settings.onClick}
              sx={{ opacity: expanded ? 0 : 1, stroke: 'primary' }}
            />
          </Box>
        )}
        {!hideMenu && (<Menu
          sx={{
            transition: 'opacity 0.15s',
            pointerEvents: settings && settings.value ? 'none' : 'all',
            opacity: settings && settings.value ? 0 : 1,
            mr: ['-2px'],
            zIndex: 5000,
          }}
          value={expanded}
          onClick={toggle}
        />)}
      </Column>
      <Box
        sx={{
          opacity: expanded ? 1 : 0,
          pointerEvents: expanded ? 'all' : 'none',
          position: 'fixed',
          top: '0px',
          right: '0px',
          bottom: '0px',
          minWidth: '0px',
          maxHeight: '100vh',
          width: '100vw',
          backgroundColor: 'background',
          zIndex: 4000,
          pt: ['79px'],
          transition: 'opacity 0.25s',
        }}
      >
        <Container>
          <Row>
            <Column start={[2, 4, 7, 7]} width={[5, 4, 5, 5]}>
              <Box
                as='nav'
                sx={{
                  display: expanded ? 'inherit' : 'none',
                  mt: [5, 5, 5, 6],
                }}
              >
                {/* <NavGroup
                  links={links}
                  nav={nav}
                  mode={mode}
                  setExpanded={setExpanded}
                /> */}
                
                <Link
                  onClick={(e) => {
                    let path = `/`; 
                    history.push(path);
                  }}
                  sx={sx.link()}
                >
                  <HoverArrow />
                  {'Home'}
                </Link> 
                <Link
                  onClick={(e) => {
                    let path = `/accounts`; 
                    history.push(path);
                  }}
                  sx={sx.link()}
                >
                  <HoverArrow />
                  {'Accounts'}
                </Link> 
                <Link
                  onClick={(e) => {
                    let path = `/chartbuilder`; 
                    history.push(path);
                  }}
                  sx={sx.link()}
                >
                  <HoverArrow />
                  {'Signals'}
                </Link> 
                <Link
                  href={'login'}
                  sx={sx.link()}
                  onClick={() => {
                    authenticationService.logout()
                  }}
                  
                >
                  <HoverArrow />
                  {'Sign Out'}
                </Link> 
                
              </Box>
            </Column>
          </Row>
        </Container>
      </Box>
    </Row>
  )
}

export default Header
