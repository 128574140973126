import { Box } from 'theme-ui'
import { Badge, FadeIn } from '@carbonplan/components'

const Top = ({ data, filtered, tooltips, setTooltips }) => {
  return (
    <Box
      sx={{
        position: 'sticky',
        height: '58px',
        top: 56,
        pb: ['26px'],
        mb: [0],
        pt: [2],
        ml: ['-16px', 0, 0],
        mr: ['-16px', 0, 0],
        pr: ['16px', 0, 0],
        pl: ['16px', 0, 0],
        bg: 'background',
        borderWidth: '0px',
        borderBottomWidth: '1px',
        borderColor: 'muted',
        borderStyle: 'solid',
        userSelect: 'none',
        zIndex: 1,
      }}
    >
      <Box sx={{ mt: ['8px'] }}>
        <Box
          sx={{
            display: 'inline-block',
            mr: [2],
            fontFamily: 'mono',
            letterSpacing: 'mono',
            fontSize: [1, 1, 1, 2],
            textTransform: 'uppercase',
            color: 'secondary',
            mt: ['3px'],
            userSelect: 'none',
            
          }}
        >
          Active Days
        </Box>
        <Box sx={{ display: 'inline-block', width: '40px' }}>
          {data && (
            <FadeIn as='span' delay={10} duration={150}>
              <Badge sx={{ ml: [2], fontSize: [3] }}>
                {String(data.activeDays).padStart(3, '0')}
              </Badge>
            </FadeIn>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default Top
