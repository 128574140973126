import { memo } from 'react';
import ItemAccount from './itemAccount';
import { Row, Column } from '@carbonplan/components'

const List = ({
  filters,
  setFilters,
  filtered,
  data,
  setHighlighted,
  tooltips,
  setTooltips
}) => {

  return (
    <Row columns={[3, 6, 9, 9]} sx={{ mb: [4, 5, 6, 7] }}>
      {
        data && data.map(item=>{
          return (
            <Column start={[1, 1, 1, 1]} sx={{ mb: [0, 1, 2, 2] }} width={[3, 6 ,9 ,9]} key={item.id}>
              <ItemAccount data={item}></ItemAccount>
            </Column>
          )
        })
      }
    </Row>  
  )
}

export default memo(List)
