import { Box } from 'theme-ui'
import { Badge, FadeIn } from '@carbonplan/components'

var sx = {
  label: {
    fontFamily: 'mono',
    letterSpacing: 'mono',
    fontSize: [1, 1, 1, 2],
    textTransform: 'uppercase',
    color: 'secondary',
    mt: ['3px'],
    userSelect: 'none',
  }
}

const Top = ({ data, filtered, tooltips, setTooltips }) => {
  return (
    <Box
      sx={{
        position: 'sticky',
        height: '58px',
        top: 56,
        pb: ['26px'],
        mb: [0],
        pt: [2],
        ml: ['-16px', 0, 0],
        mr: ['-16px', 0, 0],
        pr: ['16px', 0, 0],
        pl: ['16px', 0, 0],
        bg: 'background',
        borderWidth: '0px',
        borderBottomWidth: '1px',
        borderColor: 'muted',
        borderStyle: 'solid',
        userSelect: 'none',
        zIndex: 1,
      }}
    >
      <Box sx={{ mt: ['8px'] }}>
      <Box
          sx={{
            display: 'inline-block',
            mr: [2],
            fontSize: [1],
            ...sx.label,
          }}
        >
          Total
        </Box>
        <Box sx={{ display: 'inline-block', width: '40px' }}>
          {filtered.init && (
            <FadeIn as='span' delay={10} duration={150}>
              <Badge sx={{ ml: [2], fontSize: [3] }}>
                {String(data.length).padStart(3, '0')}
              </Badge>
            </FadeIn>
          )}
        </Box>
        <Box
          sx={{
            display: 'inline-block',
            ml: [3, 4, 4, 4],
            mr: [2],
            ...sx.label,
          }}
        >
          Filtered
        </Box>
        <Box sx={{ display: 'inline-block', width: '40px' }}>
          {filtered.init && (
            <FadeIn as='span' delay={10} duration={150}>
              <Badge sx={{ ml: [1] }}>
                {String(filtered.count).padStart(3, '0')}
              </Badge>
            </FadeIn>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default Top