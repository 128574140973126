import { memo } from 'react';
import ItemEvent from './itemEvent';
import { Row, Column } from '@carbonplan/components'

const List = ({
  filters,
  setFilters,
  filtered,
  data,
  setHighlighted,
  tooltips,
  setTooltips,
  type
}) => {

  return (
    <>
    { type === 'sidebar' && (
      <Row columns={[3, 6, 3, 3]} sx={{ mb: [4, 5, 6, 7] }}>
      {
        data && data.map( (item, i) =>{
          return (
            <Column 
              start={[1, i%2 === 0 ? 1 : 4, i%3=== 0 ? 1 : 1, 1]} 
              sx={{ mb: [0, 1, 2, 2] }} 
              width={[3, 3 ,3 ,3]} 
              key={item.id}>
                <ItemEvent data={item}></ItemEvent>
            </Column>
          )
        })
      }
      </Row>  
    )}
    {type === 'home' && (
      <Row columns={[3, 6, 9, 9]} sx={{ mb: [4, 5, 6, 7] }}>
      {
        data && data.map( (item, i) =>{
          return (
            <Column 
              start={[1, i%2 === 0 ? 1 : 4, (i%3 * 3)+1, (i%3 * 3)+1]} 
              sx={{ mb: [0, 1, 2, 2] }} 
              width={[3, 3 ,3 ,3]} 
              key={item.id}>
                <ItemEvent data={item}></ItemEvent>
            </Column>
          )
        })
      }
      </Row>  
    )}
    </>
  )
}

export default memo(List)
