import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import FormControlLabel from '@mui/material/FormControlLabel';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Box } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { useThemeUI  } from 'theme-ui';
import cryptoService from '../services/CryptoService';
import ComboBox from '../components/Autocomplete';
import campaignsService from '../services/CampaignsService';
import etfsService from '../services/EtfService';
import AlertComponent from '../components/AlertComponent';
import stockService from '../services/StockService';

export default function AddTriggerModal({
  openModal, 
  handleClose 
}) {
  const themeUI  = useThemeUI();
  const theme = createTheme({
    components:{
      MuiPaper:{
        styleOverrides: {
          root: {
            color: themeUI.theme.colors.text,
            background:themeUI.theme.colors.background,
          },
        },
      },
      MuiTypography:{
        styleOverrides: {
          root:{
            color: themeUI.theme.colors.text + '!important',
          }
        }
      },
      MuiFormLabel:{
        styleOverrides: {
          root:{
            color: themeUI.theme.colors.text + '!important',
          }
        }
      },
      MuiSvgIcon:{
        styleOverrides: {
          root:{
            color: themeUI.theme.colors.text + '!important',
          }
        }
      },
      MuiOutlinedInput:{
        styleOverrides: {
          root: {
            ':hover .MuiOutlinedInput-notchedOutline': {
              borderColor: themeUI.theme.colors.muted,
            },
            color: themeUI.theme.colors.text + '!important',
          },
          notchedOutline:{
            borderColor: themeUI.theme.colors.text,
           },
        }
      },
      MuiSwitch:{
        styleOverrides: {
          root: {
            ".css-19ock7p-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track":{
              "backgroundColor": "rgba(126,179,106,0.55)"
            },
            "width":'70px',
            ".css-19ock7p-MuiButtonBase-root-MuiSwitch-switchBase":{
              "color": themeUI.theme.colors.secondary
            },
            ".css-19ock7p-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked":{
              "color": themeUI.theme.colors.green,
              "transform": "translateX(30px)"
            }
          },
          track:{
            backgroundColor: themeUI.theme.colors.muted
          }
          
        }
      }
      /*MuiDialogContent:{
        styleOverrides: {
          root: {
            paddingBottom: '250px'
          }
        }
      }*/
    }
    
  });
  const { id } = useParams();
  var [sourceModel, setSourceModel] = useState();
  var [sourceId, setSourceId] = useState(null);
  var [cryptoList, setCryptoList] = useState([]);
  var [etfList, setEtfList] = useState([]);
  var [stockList, setStockList] = useState([]);
  var [search, setSearch] = useState('');
  var [alertSourceModel, setAlertSourceModel] = useState(false);
  var [alertSourceId, setAlertSourceId] = useState(false);
  
  var [clearInput, setClearInput] = useState(false);
  var [openAutocomplete, setOpenAutocomplete] = useState(false);
  var loading = false;

  const handleAdd = () => {
    if(!sourceModel) setAlertSourceModel(true);
    else if(!sourceId) setAlertSourceId(true);
   
    if(id && sourceModel && sourceId){
      campaignsService.postCampaignTriggers(id, sourceModel, sourceId.symbol)
      .then(respose =>{
        setSourceModel();
        handleClose();
      })
    }
  };

  const handleChangeInput = (e) => {
    setAlertSourceId(false);
    setSearch(e.target.value);
  };

  const handleSelect = (e) =>{
    setSourceId(null);
    setAlertSourceModel(false);
    setSourceModel(e.target.value)
  }

  const handleChangeAutocomplete = (e,v, r) => {
    if(r==='clear') setClearInput(!clearInput);
    setAlertSourceId(false);
    setSourceId(v);
  }


  useEffect(
    () => {
      cryptoService.getCryptos()
        .then(response => {
          response = response.map(r=> {r['label'] = r.name; return r})
          setCryptoList(response)
        })
      etfsService.getETFs()
        .then(response=> {
          response = response.map(r=> {r['label'] = r.symbol; return r})
          setEtfList(response)
        })
    }, [])

    useEffect(
      () => {
        let active = true;
        loading = true; 
        if(active && openModal){
          stockService.getStocks(search)
          .then(response=> {
            setStockList(response);
            loading = false;
          })
          return () => {
            active = false;
          };
        }
      }, [search, openModal, clearInput])
  
  return (
    <>
      <ThemeProvider theme={theme}>
      <Dialog 
        open={openModal} 
        onClose={()=>{handleClose(); setSourceModel(null);}}
        fullWidth={true}
        maxWidth={'sm'}
      >
        <DialogTitle>Add new signal</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{
              mb:[2]
            }}>
            Please choose the new signal type:
          </DialogContentText >

          <Box sx={{
            display: 'flex',
            alignItems: 'center'
          }}>
            <RadioGroup
              row
            >
            
            <FormControlLabel value="CatEtf" control={<Radio />} label="ETF" onChange={handleSelect}/>
            <FormControlLabel value="CatCrypto" control={<Radio />} label="Crypto" onChange={handleSelect}/>
            <FormControlLabel value="CatStock" control={<Radio />} label="Stock" onChange={handleSelect}/>
            { alertSourceModel && (
              <AlertComponent 
                type={'error'} 
                strongText={'Select a trigger type.'} 
                sx={{width: 250}
              }></AlertComponent>
            )}
          </RadioGroup>
          </Box>
          
          {
            sourceModel &&  sourceModel === 'CatEtf' && (
              <>
                <DialogContentText sx={{
                  mt: [3],
                  mb:[2]
                }}>
                  Select source:
                </DialogContentText>
                <Box sx={{
                  display: 'flex',
                  alignItems: 'center'
                }}>
                  <ComboBox 
                    data={etfList} 
                    label={'ETF'}
                    value={sourceId}
                    setValue= {setSourceId}
                    setAlert={setAlertSourceId}
                    sx={{
                      width: 300,
                      marginRight: '10px'
                    }}
                  />
                  { alertSourceId && (
                    <AlertComponent 
                      type={'error'} 
                      strongText={'Select a source.'} 
                      sx={{width: 250}
                    }></AlertComponent>
                  )}
                </Box>
                
              </>
            )
          }
          {
            sourceModel &&  sourceModel === 'CatCrypto' && (
              <>
                <DialogContentText sx={{
                  mt: [3],
                  mb:[2]
                }}>
                  Select source:
                </DialogContentText>
                <Box sx={{
                  display: 'flex',
                  alignItems: 'center'
                }}>
                  <ComboBox 
                    data={cryptoList} 
                    label={'Crypto'}
                    value={sourceId}
                    setValue= {setSourceId}
                    setAlert={setAlertSourceId}
                    sx={{
                      width: 300,
                      marginRight: '10px'
                    }}
                  />
                  { alertSourceId && (
                    <AlertComponent 
                      type={'error'} 
                      strongText={'Select a source.'} 
                      sx={{width: 250}
                    }></AlertComponent>
                  )}
                </Box>
                
              </>
            )
          }
          {
            sourceModel &&  sourceModel === 'CatStock' && (
              <>
                <DialogContentText sx={{
                  mt: [3],
                  mb:[2]
                }}>
                  Select source:
                </DialogContentText>
                <Box sx={{
                  display: 'flex',
                  alignItems: 'center'
                }}>
                  <Autocomplete
                    sx={{ width: 300 }}
                    open={openAutocomplete}
                    onOpen={() => {
                      setOpenAutocomplete(true);
                    }}
                    onClose={() => {
                      setOpenAutocomplete(false);
                      setSearch('');
                    }}
                    onChange={handleChangeAutocomplete}
                    isOptionEqualToValue={(option, value) => option.symbol === value.symbol }
                    getOptionLabel={(option) => option.symbol}
                    options={stockList}
                    loading={loading}
                    filterOptions={(x) => x}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Stock"
                        onChange={handleChangeInput}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {loading ? <CircularProgress color="inherit" size={20} /> : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                  { alertSourceId && (
                    <AlertComponent 
                      type={'error'} 
                      strongText={'Select a source.'} 
                      sx={{width: 250}
                    }></AlertComponent>
                  )}
                </Box>
                
              </>
            )
          }
          
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>{handleClose(); setSourceModel(null);}}>Cancel</Button>
          <Button onClick={handleAdd}>Add</Button>
        </DialogActions>
      </Dialog>
      </ThemeProvider >
    </>
  );
}
