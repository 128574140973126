import { useEffect, useState } from 'react';
import { useParams, useHistory } from "react-router-dom";
import { Box, Flex } from 'theme-ui';
import { Row, Column, Button } from '@carbonplan/components';
import holdingsService from '../services/HoldingsService';

export default function TrendingHoldings({columns}) {

  const sx = {
    button: { 
      borderWidth:[0],
      borderBottomWidth: [1],
      borderStyle: ['solid'],
      borderColor: ['text'],
      paddingTop: [2],
      '&:hover': {
        borderColor: ['muted'],
        borderBottomWidth: [1],
      },
      textOverflow: 'ellipsis',
      overflow: 'hidden', 
      whiteSpace: 'nowrap',
      textTransform: 'uppercase',
      fontSize: [1, 1, 1, 2],
      fontFamily: 'mono',
      letterSpacing: 'mono',
      transition:'opacity 0.05s'
    }
  }
  const { slug } = useParams();
  const history = useHistory(); 
  var [holdings, setHoldings] = useState([])

  useEffect(
    () => {
      holdingsService.getHoldings({slug})
      .then(response=>{
        setHoldings(response.cat_etf_holdings.slice(0,10))
      })
  },[slug])


  return (
    <Row columns={columns} sx={{ mb: [4, 5, 6, 7] }}>
      <Column start={[1, 1, 1, 1]} sx={{ mb: [4, 0, 5, 6] }} width={columns}>
        <Flex sx={{
          mb: [3, 3, 3, 4],
          fontSize: [3, 3, 3, 4],
          fontFamily: 'heading',
          letterSpacing: 'smallcaps',
          textTransform: 'uppercase',
          color: 'primary',
        }} >
        
        <Box sx={{fontSize: [2], color: 'secondary',}}>Holdings</Box>
        </Flex>

        {holdings && holdings.map((d, i) => {
          return (
            <Flex key={d.id} 
              onClick={()=>{history.push(`/stock/${d.symbol}?metric=volume`);}}
              sx={{
                fontFamily: 'mono',
                letterSpacing: 'mono',
                fontSize: [1, 1, 1, 2],
                textTransform: 'uppercase',
                borderStyle: 'solid',
                borderColor: 'muted',
                borderBottomWidth: ['1px'],
                marginTop: [2],
                paddingBottom: [1],
                cursor: ['pointer']
              }}>
                <Box sx={{color: 'secondary'}}>
                  {String(i+1).padStart(2, '0')}
                  
                </Box>
                <Box sx={{
                  ml:[5],
                  display: ['inline-block'],
                  whiteSpace: ['nowrap'],
                  overflow: ['hidden'],
                  textOverflow: ['ellipsis'],
                  color: 'primary',
                  width: ['85%']
                  }}>{d.name}</Box>
            </Flex>
          )
        })}
        {holdings.length > 0 && 
          <Button
            onClick={()=> {history.push(`/etf/${slug}/holdings`);}}
            sx={{...sx.button, color: 'blue', borderColor: 'blue', opacity: '0.33'}}
          >
            VIEW ALL
          </Button>
        }
      </Column>
    </Row>
  );

}