import Cookies from 'universal-cookie';
import handleResponse from '../helpers/HandleResponse';
const cookies = new Cookies();

let campaignsService = {
  getCampaigns,
  getCampaign,
  postCampaign,
  updateCampaign,
  postCampaignTriggers,
  postBotActions,
  updateBotActions,
  listBotActions,
  deleteBotAction
};

function getCampaigns () {
  return fetch(`${process.env.REACT_APP_API_URL}campaigns`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + cookies.get('jwt')
    }
  })
  .then(response => {
    return handleResponse(response)
  })
  .catch(error => console.warn(error));
}

function getCampaign (id) {
  return fetch(`${process.env.REACT_APP_API_URL}campaigns/${id}`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + cookies.get('jwt')
    }
  })
  .then(response => {
    return handleResponse(response)
  })
  .catch(error => console.warn(error));
}

function postCampaign(name, budget){
  /*FAKE DATA*/
  let active = 1;

  return fetch(`${process.env.REACT_APP_API_URL}campaigns`,{
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + cookies.get('jwt')
    },
    body: JSON.stringify({ name, active, budget })
  })
  .then(response => {
    return handleResponse(response)
  })
  .catch(error => console.warn(error));
}

function updateCampaign(id, name, budget, active){
  return fetch(`${process.env.REACT_APP_API_URL}campaigns/${id}`,{
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + cookies.get('jwt')
    },
    body: JSON.stringify({ name, active, budget })
  })
  .then(response => {
    return handleResponse(response)
  })
  .catch(error => console.warn(error));
}

function postCampaignTriggers (campaign_id, source_model, source_id) {
  /*FAKE DATA*/

  let source_type = 'database';
  let name = source_id;
  let label = 'Hardcoded label'
  
  return fetch(`${process.env.REACT_APP_API_URL}campaign_triggers`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + cookies.get('jwt')
    },
    body: JSON.stringify({campaign_id, source_type, source_model, source_id, name, label})
  })
  .then(response => {
    return handleResponse(response)
  })
  .catch(error => console.warn(error));
}

function postBotActions(webhookName, botId, weebHookUrl, headersArray, auth){
  let body = {
    "name": webhookName,
    "bot_id": botId,
    "webhook":{
        "url": weebHookUrl,
        "method": "POST",
        "headers": headersArray,
        auth
    }
  }

  return fetch(`${process.env.REACT_APP_API_URL}bot_webhooks`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + cookies.get('jwt')
    },
    body: JSON.stringify(body)
  })
  .then(response => {
    return handleResponse(response)
  })
  .catch(error => console.warn(error));
}

function updateBotActions(actionId, webhookName, botId, weebHookUrl, headersArray, auth){
  let body = {
    "name": webhookName,
    "bot_id": botId,
    "webhook":{
        "url": weebHookUrl,
        "method": "POST",
        "headers": headersArray,
        auth
    }
  }
  return fetch(`${process.env.REACT_APP_API_URL}bot_webhooks/${actionId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + cookies.get('jwt')
    },
    body: JSON.stringify(body)
  })
  .then(response => {
    return handleResponse(response)
  })
  .catch(error => console.warn(error));
}


function listBotActions(bot_id){
  
  let queryParams = new URLSearchParams({
    bot_id,
  })
  return fetch(`${process.env.REACT_APP_API_URL}bot_webhooks?${queryParams}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + cookies.get('jwt')
    },
  })
  .then(response => {
    return handleResponse(response)
  })
  .catch(error => console.warn(error));
}

function deleteBotAction(action_id){
  return fetch(`${process.env.REACT_APP_API_URL}bot_webhooks/${action_id}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + cookies.get('jwt')
    },
  })
  .then(response => {
    return handleResponse(response)
  })
  .catch(error => console.warn(error));
}

export default campaignsService;