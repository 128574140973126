/** @jsxImportSource theme-ui */

import { useState, useEffect } from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { Box } from 'theme-ui';

function BarChart(props) {
  var [options, setOptions] = useState({
    colors: props.colors,
    chart: {
      backgroundColor: 'transparent',
      type: 'column',
      height: '60px',
      width: 100
    },
    series: props.data,
    xAxis: {
      labels: {
        enabled: false
      },
      visible: false
    },
    yAxis:{
      gridLineColor: 'transparent',
      labels: {
        enabled: false
      },
      visible: false
    },
    scrollbar: {
      enabled: false
    },
    navigator: {
      enabled: false
    },
    rangeSelector: {
      enabled: false
    },
    credits: {
      enabled: false
    },
    legend: {
      enabled: false,
    },
    buttonTheme: {
      visibility: 'hidden'
    },
    labelStyle: {
      visibility: 'hidden'
    },
    zoomType : false,
    plotOptions: {
      series: {
        enableMouseTracking: false
      }
    }
  });

  useEffect(() => {
    setOptions( prevState => ({ ...prevState, series: props.data }));
  
    Highcharts.charts.forEach(function(chart) {
      if(chart) chart.reflow();
    });
  }, [props.color, props.data, props.plotBands]);
  
  return (
    <Box sx={{
      height: '100%',
      '.highcharts-plot-border': {
        fill: 'transparent'
      }
    }}>
      { options &&
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
          allowChartUpdate={true}
          constructorType={'stockChart'}
          containerProps={{ className: props.className }}
      />
      }
    </Box>
  )
}

export default BarChart;
