import { memo, useState, useEffect, useRef } from 'react'
import { Box, Input, Flex } from 'theme-ui';

import { sx } from './sidebar'

const Search = ({ setSearch, tooltips }) => {
  const [value, setValue] = useState('')
  const [hasFocus, setFocus] = useState(false)
  const inputRef = useRef(null)

  useEffect(() => {
    function handler(event) {
      const { key, metaKey } = event
      if (key === '/' && metaKey) {
        if (!hasFocus) inputRef.current.focus()
      }
    }
    document.addEventListener('keydown', handler)
    return () => {
      document.removeEventListener('keydown', handler)
    }
  }, [hasFocus])

  return (
    <Flex sx={{ mb: [-1], mt: [0, 0, 1, 1], justifyContent: 'space-between', alignItems: 'center'}}>
      <Box sx={{...sx.label, pt: '2px'}}>Search</Box>
      <Input
            type='text'
            ref={inputRef}
            placeholder={'enter search term'}
            onChange={(e) => {
              setValue(e.currentTarget.value)
              setSearch(e.currentTarget.value)
            }}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            sx={{
              fontSize: [1],
              height: '24px',
              pl: [3],
              pr: [0],
              fontFamily: 'mono',
              borderRadius: '0px',
              borderWidth: '0px',
              textAlign: 'left',
              display: 'inline-block',
              ':focus-visible': {
                outline: 'none !important',
                background: 'none !important',
              },
            }}
            value={value}
          />
      
    </Flex>
  )
}

export default memo(Search)
