import { useState, useEffect } from 'react'
import { Guide } from '@carbonplan/components';
import Layout from '../components/layout';
import NotFound from './NotFound';
import { motion } from 'framer-motion';

function Index() {
  const [settingsExpanded, setSettingsExpanded] = useState(false);

  useEffect(() => {
    document.title = "Defiance Analytics | Home"
    
  }, [])

  return (
    <motion.div
      initial={{ scaleY: 0 }}
      animate={{ scaleY: 1 }}
      exit={{ scaleY: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Layout
        footer={false}
        metadata={false}
        dimmer={'top'}
        settings={{
          value: settingsExpanded,
          onClick: () => setSettingsExpanded(!settingsExpanded),
        }}
        
      >
        <Guide />
        <NotFound/>
      </Layout>
    </motion.div>
  )
}

export default Index
