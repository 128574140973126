import { memo } from 'react';
import { Row, Column } from '@carbonplan/components'
import { Box } from 'theme-ui';
import ItemAsset from './itemAsset';
import ItemAction from './itemAction';
import Top from './top';
import Search from './search';


const List = ({
  filtered,
  data,
  actions,
  openSignalsModal,
  setOpenSignalsModal,
  openActionsModal,
  setOpenActionsModal,
  actionRecord,
  setActionRecord,
  setFilters
}) => {

  function setSearch(value) {
    setFilters((filters) => {
      return { ...filters, search: value }
    })
  }
  

  return (
    <Row columns={[1, 1, 2, 2]}>
      <Column start={[1]} width={[1]}>
        <Top data={data} openSignalsModal={openSignalsModal} setOpenSignalsModal={setOpenSignalsModal} type={'signals'}/>
        { data && data.length > 10 && (
          <Search setSearch={setSearch} />
        )}
        {
          filtered.init && filtered.count > 0 && data && data.filter((d) => filtered[d.id]).map((item, idx)=>{
            return (
              <ItemAsset data={item} key={item.id} idx={idx}></ItemAsset>
            )
          })
        }
        {filtered.count === 0 && filtered.init === true && (
          <Box
            sx={{
              pl: [0, 0, 0],
              pt: [3, 2, '150px'],
              maxWidth: '350px',
              margin: [0, 0, 'auto'],
            }}
          >
            <Box
              sx={{
                display: 'inline-block',
                fontSize: [1, 1, 1, 2],
                fontFamily: 'mono',
                color: 'secondary',
                textTransform: 'uppercase',
                letterSpacing: 'mono',
              }}
            >
              No results found
            </Box>
            <Box
              sx={{
                mt: [2],
                fontSize: [2, 2, 2, 3],
              }}
            >
              Please try changing the settings in the filter panel and try
              again.
            </Box>
          </Box>
        )}
      </Column>
      <Column start={[1, 1, 2, 2]} width={[1]}>
        <Top data={actions} openActionsModal={openActionsModal} setOpenActionsModal={setOpenActionsModal} type={'actions'} actionRecord={actionRecord}/>
        {
          actions && actions.map((item, idx)=>{
            return (
              <ItemAction data={item} key={item.id} idx={idx} setActionRecord={setActionRecord} setOpenActionsModal={setOpenActionsModal}></ItemAction>
            )
          })
        }
        {/* {filtered.count === 0 && filtered.init === true && (
          <Box
            sx={{
              pl: [0, 0, 0],
              pt: [3, 2, '150px'],
              maxWidth: '350px',
              margin: [0, 0, 'auto'],
            }}
          >
            <Box
              sx={{
                display: 'inline-block',
                fontSize: [1, 1, 1, 2],
                fontFamily: 'mono',
                color: 'secondary',
                textTransform: 'uppercase',
                letterSpacing: 'mono',
              }}
            >
              No results found
            </Box>
            <Box
              sx={{
                mt: [2],
                fontSize: [2, 2, 2, 3],
              }}
            >
              Please try changing the settings in the filter panel and try
              again.
            </Box>
          </Box>
        )} */}
      </Column>
      
    </Row>
  )
}

export default memo(List)
