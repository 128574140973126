import * as React from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {useThemeUI } from 'theme-ui';


export default function ArrowIndicator({value}) {
  const themeUI  = useThemeUI();
  const theme = createTheme();
  const [color, setColor] = React.useState()

  React.useEffect(() => {
    if(value && value > 0) setColor(themeUI.theme.colors.green)
    if(value && value < 0) setColor(themeUI.theme.colors.red)
  }, [setColor, themeUI, value]);



 
  return (
    <ThemeProvider theme={theme}>
      {
        value && value > 0 && (<ArrowDropUpIcon sx={{ color: color }}/>)
      }
      {
        value && value < 0 && (<ArrowDropDownIcon sx={{ color: color }}/>)
      }
    </ThemeProvider>
    
  );
}