import authenticationService from '../services/AuthenticationService';

let handleResponse = function (response) {
    if(!response.ok){
        if ([401, 403].indexOf(response.status) !== -1) {
          // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
          authenticationService.logout();
          window.location.reload(true);
          return []
        }
      }
    return response.json();
}

export default handleResponse;