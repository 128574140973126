import { Box } from 'theme-ui';


function NoData({labelTitle, labelText}) {
  return (
    <Box>
      <Box
        sx={{
          display: 'inline-block',
          fontSize: [1, 1, 1, 2],
          fontFamily: 'mono',
          color: 'secondary',
          textTransform: 'uppercase',
          letterSpacing: 'mono',
        }}
      >
        {labelTitle}
      </Box>
      <Box
        sx={{
          mt: [2],
          fontSize: [2, 2, 2, 3],
        }}
      >
        {labelText}
      </Box>
    </Box>
  )
}

export default NoData;
