/** @jsxImportSource theme-ui */

import { useState, useEffect } from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { Box, useThemeUI } from 'theme-ui';


function Chart(props) {
  var colors = useThemeUI().theme.colors;
  var [options, setOptions] = useState({});
  useEffect(() => {
    setOptions({
      colors: props.colors,
      chart: {
        backgroundColor: 'transparent',
        type: 'line',
        events:{
          click: props.eventClick || undefined,
          selection: props.eventSelect || undefined,
        },
        zoomType: 'x',
        margin: [0, 0, 0, 0]
      },
      series: props.data,
      xAxis: {
        plotBands: props.plotBandsNewsDatesRangeSelected ? 
                    props.plotBands ? [...props.plotBands, ...props.plotBandsNewsDatesRangeSelected] : props.plotBandsNewsDatesRangeSelected 
                  : props.plotBands || [],
      },
      yAxis: {
        gridLineColor: colors.muted,
        gridLineDashStyle: 'ShortDot'

      },
      scrollbar: {
        enabled: false
      },
      rangeSelector: {
        enabled: false
      },
      credits: {
        enabled: false
      },
      navigator: { enabled: false},
      height: '100%',
      legend: {
        enabled: props.legend ? true : props.legend,
        verticalAlign: 'top',
        align: 'right',
        itemStyle:{color: colors.primary}
      },
      plotOptions: {
        series: {
         kdNow: true,
         enableMouseTracking: props.disableMouseTracking ? false : true
       }
      },
      tooltip:{
        enabled: props.tooltipDisabled ? false : true
      }
    });
  
    Highcharts.charts.forEach(function(chart) {
      if(chart) chart.reflow();
    });
  }, [props.colors, colors, props.data, props.plotBands, props.eventClick, props.eventSelect, props.disableMouseTracking, props.legend, props.plotBandsNewsDatesRangeSelected, props.tooltipDisabled]);
  
  return (
    <Box sx={{
      height: '100%',
      '.highcharts-plot-border': {
        fill: 'transparent'
      }
    }}>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        allowChartUpdate={true}
        constructorType={'stockChart'}
        containerProps={{ className: props.className, style: { height: props.height ? props.height : '36em' }}}
      />
    </Box>
  )
}

export default Chart;
