import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';


export default function ComboBox({data, label, value, setValue, setAlert, sx}) {
  return (
    <Autocomplete
      value={value}
      onChange={(event, newValue) => {
          setValue(newValue);
          if(setAlert) setAlert(false);
      }}
      disablePortal
      id="combo-box-demo"
      options={data}
      sx={sx}
      renderInput={(params) => <TextField {...params} label={label} />}
    />
  );
}