import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import Box from '@mui/material/Box';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {useThemeUI } from 'theme-ui';



export default function DateRangePickerComponent({
  startDate,
  setStartDate,
  endDate,
  setEndDate, 
  blurRef
}) {

  const [value, setValue] = useState([startDate ? `${startDate.replace('-', '/') }` : '', endDate ?  `${endDate.replace('-', '/') }` : '']);
  const themeUI  = useThemeUI();
  const theme = createTheme({
    components:{
      MuiDateRangePickerDay:{
        styleOverrides: {
          root:{
            color: themeUI.theme.colors.text,
          }
        },
      },
      MuiPaper:{
        styleOverrides: {
          root: {
            color: themeUI.theme.colors.text,
            background:themeUI.theme.colors.background
          },
        },
      },
      MuiSvgIcon:{
        styleOverrides: {
          root:{
            color: themeUI.theme.colors.text,
          }
        }
      },
      MuiTypography:{
        styleOverrides: {
          root:{
            color: themeUI.theme.colors.text + '!important',
          }
        }
      },
      MuiButtonBase:{
        styleOverrides: {
          root:{
            color: themeUI.theme.colors.text + '!important',
          }
        }
      },
      MuiOutlinedInput:{
        styleOverrides: {
          root: {
            ':hover .MuiOutlinedInput-notchedOutline': {
              borderColor: themeUI.theme.colors.muted,
            }
          },
          notchedOutline:{
            borderColor: themeUI.theme.colors.text,
           },
        }
      },
      MuiInputBase:{
        styleOverrides: {
          root:{
            color: themeUI.theme.colors.text,
          }
        }
      }
    }
  });
  
  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
      {themeUI.colorMode === 'light' ?
              <Box sx={{
                '.MuiFormControl-root':{
                  background: ''
                },
                pt: [2]
              }}>
                <DateRangePicker
                  startText="Start Date"
                  endText="End Date"
                  value={value}
                  onChange={(newValue) => {
                    if(newValue[0] && newValue[1]){
                      setStartDate(new Date(newValue[0]).toISOString().slice(0, 10))
                      setEndDate(new Date(newValue[1]).toISOString().slice(0, 10))
                    }
                    setValue(newValue);
                  }}
                  renderInput={(startProps, endProps) => (
                    <React.Fragment>
                      <TextField {...startProps} />
                      <Box sx={{ mx: 2 }}> to </Box>
                      <TextField {...endProps} />
                    </React.Fragment>
                  )}
                />
              </Box>
        :
          <Box sx={{
            '.MuiInputLabel-root':{
              color: themeUI.theme.colors.text
            },
            '.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused':{
              color: themeUI.theme.colors.text
            },
            '.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root':{
              color: themeUI.theme.colors.text
            },
            '.css-1smlk3z-MuiPaper-root':{
              background: themeUI.theme.colors.muted,
            },
            pt: [2],
          }}>
            <DateRangePicker
              startText="Start Date"
              endText="End Date"
              value={value}
              onChange={(newValue) => {
                if(newValue[0] && newValue[1]){
                  setStartDate(new Date(newValue[0]).toISOString().slice(0, 10))
                  setEndDate(new Date(newValue[1]).toISOString().slice(0, 10))
                }
                setValue(newValue);
              }}
              renderInput={(startProps, endProps) => (
                <React.Fragment>
                  <TextField {...startProps} />
                  <Box sx={{ mx: 2 }}> to </Box>
                  <TextField {...endProps} />
                </React.Fragment>
              )}
            />
          </Box>
      }
      </LocalizationProvider>
    </ThemeProvider>
  );
}
