import React, { useEffect, useState } from 'react';
import { Box, Grid } from 'theme-ui'
import ReactPaginate from 'react-paginate';
import ItemCrypto from './itemWithTriData';

function ItemsTwoColumns({ currentItems, setHighlighted, tooltips }) {
  return (
    <>
      <Box sx={{}}>
        {currentItems
          .filter((d, i) => i % 2 === 0)
          .map((d, i) => (
            <ItemCrypto
              setHighlighted={setHighlighted}
              key={d.id}
              data={d}
              tooltips={tooltips}
            />
          ))}
          
      </Box>
      <Box sx={{ pr: [0] }}>
        {currentItems
          .filter((d, i) => i % 2 === 1)
          .map((d, i) => (
            <ItemCrypto
              setHighlighted={setHighlighted}
              key={d.id}
              data={d}
              tooltips={tooltips}
            />
          ))}
      </Box>
      
    </>
  );
}
function ItemsOneColumn({ currentItems, setHighlighted, tooltips }) {
  return (
    <Box sx={{ mb: [0], mt: [0, 0, '28px'] }}>
      {
        currentItems
          .map((d, i) => (
            <ItemCrypto
              setHighlighted={setHighlighted}
              key={i}
              data={d}
              tooltips={tooltips}
            //tag={tags}
            />
          ))}
    </Box>
  );
}

function PaginatedItems({ itemsPerPage, items, filtered, setHighlighted, tooltips, isWide }) {
  // We start with an empty list of items.
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);
  
  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    //console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    setCurrentItems(items.filter((d) => filtered[d.id]).slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.filter((d) => filtered[d.id]).length / itemsPerPage));
  }, [itemOffset, itemsPerPage]);

  useEffect(()=>{
    setItemOffset(0)
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(items.filter((d) => filtered[d.id]).slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.filter((d) => filtered[d.id]).length / itemsPerPage));
  }, [items, filtered])

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    // console.log(
    //   `User requested page number ${event.selected}, which is offset ${newOffset}`
    // );
    setItemOffset(newOffset);
  };

  return (
    <>
      {isWide && (
        <Grid
          columns={[2]}
          sx={{ display: ['grid'], mt: ['10px'], ml: ['0'], mb:['50px']}}
          gap={[4, 5, 5, 6]}
        >
          <ItemsTwoColumns
            currentItems={currentItems}
            setHighlighted={setHighlighted}
            tooltips={tooltips}
          />
        </Grid>
      )}
      {!isWide && (
        <ItemsOneColumn
          currentItems={currentItems}
          setHighlighted={setHighlighted}
          tooltips={tooltips}
        />
      )}
      {filtered.count > 6 && (
        <Box
          sx={{  
            mt: ['30px'], 
            ml: ['0'], 
            mb:['30px'],
            '.pagination': {
              display: 'flex',
              justifyContent: 'center',
              listStyle: 'none',
              cursor: 'pointer'
            },
            '.pagination a' : {
              padding: '10px',
              borderRadius: '5px',
              color: 'primary',
              fontFamily: 'mono',
              letterSpacings: 'mono',
              margin: '0px 3px',
              strokeWidth: 1.5,
              opacity: 1,
              ': hover': {
                stroke: 'primary',
                fontSize: [18],
              },
            },
            '.pagination__link--active a': {
              color: 'primary',
              backgroundColor: 'muted'
            },
            '.pagination__link--disabled a': {
              color: 'secondary',
              border: '0px solid',
              borderColor: 'secondary'
            }
            }}>
          <ReactPaginate
            breakLabel="..."
            nextLabel={'NEXT'}
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel={'PREV'}
            renderOnZeroPageCount={null}
            containerClassName={"pagination"}
            previousLinkClassName={"pagination__link"}
            nextLinkClassName={"pagination__link"}
            disabledClassName={"pagination__link--disabled"}
            activeClassName={"pagination__link--active"}
          />
        </Box>
        
      )}
      
    </>
  );
}

export default PaginatedItems