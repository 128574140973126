import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { Row, Column } from '@carbonplan/components'
import Top from './top';
import Sidebar from './sidebar';
import holdingsService from '../services/HoldingsService';
import ListHoldings from './listHoldings';


const initFilters = {
  search: '',
  reddit: true,
  twitter: true,
  news: true,
  earnings: true,
}

function Holdings ({settingsExpanded}) {
  var [holdings, setHoldings] = useState([]);
  const [filters, setFilters] = useState(initFilters)
  const [filtered, setFiltered] = useState({ count: 0, init: false })
  const [orderPercentDescending, setOrderPercentDescending] = useState(true);
  const [orderPercentAscending, setOrderPercentAscending] = useState(false);
  const { slug } = useParams();
  const [slugData, setSlugData] = useState();
  //const [dataTags, setDataTags] = useState([])

  useEffect(
    () => {
      holdingsService.getHoldings({slug})
      .then( response => {
        setHoldings(response.holdings);
        setSlugData(response)
      })
          
  },[slug])
  useEffect(() => {
    let obj = {}
    let count = 0
    holdings.forEach((h) => {
      obj[h.symbol] = inFilter(h)
      if (obj[h.symbol]) {
        count += 1
      }
    })
    obj.count = count
    obj.init = true
    setFiltered(obj)
  }, [filters, holdings])

  useEffect(() => {
    if (orderPercentDescending) sortDescending([...holdings])
    if (orderPercentAscending) sortAscending([...holdings])
  }, [orderPercentDescending, orderPercentAscending])

  // useEffect(() => {
  //   let objFilters = {...filters}
  //   dataTags.forEach(t=>{
  //     objFilters[t] = true;
  //   })
  //   setFilters(objFilters)
  // },[dataTags])

  function sortDescending(holdings) {
    setHoldings(
      holdings
        .sort((a, b) => {
          return b.percent - a.percent;
        }))
  }

  function sortAscending(holdings){
    setHoldings(
      holdings
      .sort((a, b) => {
        return a.percent - b.percent;
      }))
  }

  
  function inFilter (h) {
    let inTags = false
    if (h.tags.length>0) {
      for (const property in filters) {
        if(filters[property] && h.tags.includes(property)) inTags = true
      }
    }

    // const inTags =
    //   (filters.reddit && h.tags.length > 0 && h.tags.includes('reddit')) ||
    //   (filters.twitter && h.tags.length > 0 && h.tags.includes('twitter')) ||
    //   (filters.news && h.tags.length > 0 && h.tags.includes('news')) ||
    //   (filters.earnings && h.tags.length > 0 && h.tags.includes('earnings'))

    const searchTerm = filters.search.toLowerCase()
    const inSearch =
      searchTerm.length > 0 &&
      (h.name.toLowerCase().includes(searchTerm) ||
        (h.symbol && h.symbol.toLowerCase().includes(searchTerm)))
    if (filters.search.length > 0 && inSearch && inTags) return true
    if (filters.search.length === 0 && inTags) return true
    else return false
  }
  
  return (
    <Row columns={[3, 6, 9, 9]}>
      <Column start={[1]} width={[2, 2, 2, 2]}>
        <Sidebar
          filtered={filtered}
          slugData={slugData}
          data={holdings}
          filters={filters}
          setFilters={setFilters}
          settingsExpanded={settingsExpanded}
          orderPercentDescending = {orderPercentDescending}
          setOrderPercentDescending={setOrderPercentDescending}
          orderPercentAscending = {orderPercentAscending}
          setOrderPercentAscending={setOrderPercentAscending}
          //dataTags={dataTags}
        />
      </Column>
      <Column start={[1, 1, 3, 3]} width={[3, 6, 7, 7]}>
        <Top data={holdings} filtered={filtered}/>
        <ListHoldings 
          data={holdings} 
          filtered={filtered} 
        />
      </Column>
    </Row>
  )
}

export default Holdings;