import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Box, TextField } from '@mui/material';
import { useThemeUI  } from 'theme-ui';
import AlertComponent from './AlertComponent';
import AccountsService from '../services/AccountsService';

export default function AddAccountModal({
  openModal, 
  handleClose,
  campaignId 
}) {
  const themeUI  = useThemeUI();
  const theme = createTheme({
    components:{
      MuiPaper:{
        styleOverrides: {
          root: {
            color: themeUI.theme.colors.text,
            background:themeUI.theme.colors.background,
          },
        },
      },
      MuiTypography:{
        styleOverrides: {
          root:{
            color: themeUI.theme.colors.text + '!important',
          }
        }
      },
      MuiFormLabel:{
        styleOverrides: {
          root:{
            color: themeUI.theme.colors.text + '!important',
          }
        }
      },
      MuiSvgIcon:{
        styleOverrides: {
          root:{
            color: themeUI.theme.colors.text + '!important',
          }
        }
      },
      MuiOutlinedInput:{
        styleOverrides: {
          root: {
            ':hover .MuiOutlinedInput-notchedOutline': {
              borderColor: themeUI.theme.colors.muted,
            },
            color: themeUI.theme.colors.text + '!important',
          },
          notchedOutline:{
            borderColor: themeUI.theme.colors.text,
           },
        }
      },
    }
    
  });

  var [listCampaignTypes, setListCampaignTypes] = useState([])
  var [accountTypeId, setAccountTypeId] = useState(null);
  var [accountName, setAccountName] = useState();
  var [nameAccountAlert, setNameAccountAlert] = useState(false);
  var [typeAccountAlert, setTypeAccountAlert] = useState(false);

  
  
  const handleAdd = () => {
    if(!accountName) setNameAccountAlert(true); 
    if(!accountTypeId) setTypeAccountAlert(true);

    AccountsService.postAccountType(accountName, accountTypeId, window.location.href)
    .then(response => {
      if (response && response.oauth_url){
        window.location.href = response.oauth_url;
      }
    })


  };

  const handleSelect = (e) => {
    setAccountTypeId(e.target.value);
    setTypeAccountAlert(false);
  }

  const handleChangeName = (event) => {
    setAccountName(event.target.value);
    setNameAccountAlert(false);
  };


  useEffect(
    () => {
      AccountsService.getAccountTypes()
      .then(response=>{
        setListCampaignTypes(response)
      })
    }, [])
  
  return (
    <>
      <ThemeProvider theme={theme}>
      <Dialog 
        open={openModal} 
        onClose={()=>{handleClose(); setAccountTypeId(null);}}
        fullWidth={true}
        maxWidth={'sm'}
      >
        <DialogTitle>Add new account</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{
              mb:[2]
            }}>
            Please enter the new account name:
          </DialogContentText >
          <Box sx={{
            display: 'flex',
            alignItems: 'center'
          }}>
            <TextField onChange={handleChangeName} sx={{marginRight: '10px'}}/>
            {nameAccountAlert && (
              <AlertComponent 
                type={'error'} 
                strongText={"Name can't be blank"} 
                sx={{width: 250}}
              />
            )}
          </Box>
          
          <DialogContentText sx={{
              mb:[2],
              mt:[2]
            }}>
            Please choose the new account type:
          </DialogContentText >

          <RadioGroup
            row
          >
            {listCampaignTypes.map(item=>(
              <FormControlLabel key={item.id} value={item.id} control={<Radio />} label={item.name} onChange={handleSelect}/>  
            ))}
            {/* <FormControlLabel value="CatEtf" control={<Radio />} label="Google" onChange={handleSelect}/>
            <FormControlLabel value="CatCrypto" control={<Radio />} label="Facebook" onChange={handleSelect}/>
            <FormControlLabel value="CatCrypto" control={<Radio />} label="Twitter" onChange={handleSelect}/> */}
          </RadioGroup>
          {typeAccountAlert && (
              <AlertComponent 
                type={'error'} 
                strongText={"Type can't be blank"} 
                sx={{width: 250}}
              />
          )}
          
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>{handleClose(); setAccountTypeId(null);}}>Cancel</Button>
          <Button onClick={handleAdd}>Add</Button>
        </DialogActions>
      </Dialog>
      </ThemeProvider >
    </>
  );
}
