import { Box, Divider } from 'theme-ui'
import { Tray } from '@carbonplan/components'
import Search from './search'
//import OrderByPercent from './orderByPercent';


export const sx = {
  label: {
    fontFamily: 'mono',
    letterSpacing: 'mono',
    fontSize: [1, 1, 1, 2],
    textTransform: 'uppercase',
    color: 'secondary',
    mt: ['3px'],
    userSelect: 'none',
  },
}

const Sidebar = ({
  highlighted,
  filtered,
  data,
  filters,
  setFilters,
  bounds,
  setBounds,
  tooltips,
  settingsExpanded,
  orderPercentDescending,
  setOrderPercentDescending,
  orderPercentAscending,
  setOrderPercentAscending,
  dataTags,
  account
}) => {
  function setSearch(value) {
    setFilters((filters) => {
      return { ...filters, search: value }
    })
  }
  return (
    <>
      <Box
        sx={{
          position: 'sticky',
          top: 56,
          height: ['fit-content', 'fit-content', 'calc(100vh - 56px)'],
          overflowY: 'scroll',
          '::-webkit-scrollbar': {
            width: 0,
            background: 'transparent',
          },
          scrollbarWidth: 'none',
          px: ['20px'],
          mx: ['-20px'],
        }}
      >
        <Box
          sx={{
            pt: [3],
            pb: [3],
            fontSize: [6, 6, 6, 7],
            width: 'fit-content',
            fontFamily: 'AT Surt Medium',
            lineHeight: 'heading',
          }}
        >
          {account && account.name}
        </Box>
        <Box
          sx={{
            pt: [0],
            pb: [3, 3, 4, 4],
            mb: [0, 3, 0, 1],
            fontSize: [2, 2, 2, 3],
            fontFamily: 'body',
            lineHeight: 'body',
          }}
        >
          <Box>
            This is a description about this Google Account.
          </Box>
        </Box>
        <Box sx={{ display: ['none', 'none', 'initial'] }}>
          <Divider sx={{ mt: [0], mb: [0] }} />
          <Box sx={{ mb: [1], mt: [3] }}>
            <Search setSearch={setSearch} tooltips={tooltips} />
          </Box>
          <Box>

          </Box>
          {/* <Metadata
            filters={filters}
            setFilters={setFilters}
            tooltips={tooltips}
            settingsExpanded={settingsExpanded}
          /> */}
          <Divider sx={{ my: [0], mt: ['11px', '11px', '11px', '14px'] }} />
          {/* <Metrics
            highlighted={highlighted}
            filtered={filtered}
            data={data}
            bounds={bounds}
            setBounds={setBounds}
            tooltips={tooltips}
          /> */}
          <Box sx={{ mt: [2, 2, 2, 2], pt: ['12px', '12px', '12px', '14px'] }}>
            <Box sx={{ ...sx.label, mb: [2], pb: ['6px', '6px', '6px', '12px'] }}>
            Order by ...
            </Box>
          </Box>
          <Box>
            {/* <OrderByPercent 
              sx={sx} 
              upSortHover={upSortHover}
              setSortUpHover={setSortUpHover}
              downSortHover={downSortHover}
              setSortDownHover={setSortDownHover}
              orderPercentAscending={orderPercentAscending}
              setOrderPercentAscending={setOrderPercentAscending}
              orderPercentDescending={orderPercentDescending}
              setOrderPercentDescending={setOrderPercentDescending}
            /> */}
          </Box>
        </Box>
      </Box>
      <Tray expanded={settingsExpanded}>
        <Box sx={{ mb: [1], mt: [0, 0, 3, 3] }}>
          <Search setSearch={setSearch} tooltips={tooltips} />
        </Box>
        <Box>
            {/* <OrderByPercent 
              sx={sx} 
              upSortHover={upSortHover}
              setSortUpHover={setSortUpHover}
              downSortHover={downSortHover}
              setSortDownHover={setSortDownHover}
              orderPercentAscending={orderPercentAscending}
              setOrderPercentAscending={setOrderPercentAscending}
              orderPercentDescending={orderPercentDescending}
              setOrderPercentDescending={setOrderPercentDescending}
            /> */}
          </Box>
          <Box>
            
          </Box>
      </Tray>
    </>
  )
}

export default Sidebar
