import { useState } from 'react';
import { Box, useThemeUI } from 'theme-ui';
import { Badge, FadeIn, Button } from '@carbonplan/components';
import Modal from '../components/AddCryptoModal';

var sx = {
  label: {
    fontFamily: 'mono',
    letterSpacing: 'mono',
    fontSize: [1, 1, 1, 2],
    textTransform: 'uppercase',
    color: 'secondary',
    mt: ['3px'],
    userSelect: 'none',
  }
}

const Top = ({ data, filtered, openModal, setOpenModal }) => {
  var colors = useThemeUI().theme.colors;
  var [cryptoSelectedAlert, setCriptoSelectedAlert] = useState(false);
  var [socialVolumeSelectedAlert, setSocialVolumeSelectedAlert] = useState(false);
  var [advertisingTypeSelectedAlert, setAdvertisingTypeSelectedAlert] = useState(false);

  const handleClose = () => {
    setOpenModal(false);
  };
  
  const handleClickOpen = () => {
    setCriptoSelectedAlert(false);
    setSocialVolumeSelectedAlert(false);
    setAdvertisingTypeSelectedAlert(false);
    setOpenModal(true);
  };
  
  return (
    <Box
      sx={{
        position: 'sticky',
        height: ['70px','58px','58px'],
        top: 56,
        mb: [0],
        pt: [1, 1, 3, 3],
        ml: ['-16px', 0, 0],
        mr: ['-16px', 0, 0],
        pr: ['16px', 0, 0],
        pl: ['16px', 0, 0],
        bg: 'background',
        borderWidth: '0px',
        borderBottomWidth: '1px',
        borderColor: 'muted',
        borderStyle: 'solid',
        userSelect: 'none',
        zIndex: 1
      }}
    >
      <Box sx={{ 
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'wrap'
      }}>
        <Box sx={{ 
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mr:[5]
        }}>
          <Box sx={{mr:[3], mb:[1], display: 'inline-block', whiteSpace: 'nowrap'}}>
            <Box
              sx={{
                display: 'inline-block',
                mr: [2],
                fontSize: [1],
                ...sx.label,
              }}
            >
              Total
            </Box>

            <Box sx={{ display: 'inline-block', width: '40px' }}>
              {filtered.init && (
                <FadeIn as='span' delay={10} duration={150}>
                  <Badge sx={{ ml: [2], fontSize: [3] }}>
                    {String(data.length).padStart(3, '0')}
                  </Badge>
                </FadeIn>
              )}
            </Box>
          </Box>
          
          <Box sx={{display: 'inline-block', whiteSpace: 'nowrap'}}>
            <Box
              sx={{
                display: 'inline-block',
                ml: [0],
                mr: [2],
                ...sx.label,
              }}
            >
              Filtered
            </Box>
            <Box sx={{ display: 'inline-block', width: '40px' }}>
              {filtered.init && (
                <FadeIn as='span' delay={10} duration={150}>
                  <Badge sx={{ ml: [2], fontSize: [3] }}>
                    {String(filtered.count).padStart(3, '0')}
                  </Badge>
                </FadeIn>
              )}
            </Box>
          </Box>
        </Box>
        
        <Box sx={{ display: 'inline-block'}}>
          <Button
            onClick={handleClickOpen}
            sx={{ 
              borderWidth:[0],
              borderBottomWidth: [1],
              borderStyle: ['solid'],
              borderColor: colors.text,
              paddingBottom: [2],
              '&:hover': {
                borderColor: colors.muted,
                borderBottomWidth: [1],
              },
              textOverflow: 'ellipsis',
              overflow: 'hidden', 
              whiteSpace: 'nowrap',
              fontSize: [16]
            }}
          >
            Add New Crypto +
          </Button>
          <Modal 
            openModal={openModal} 
            setOpenModal={setOpenModal} 
            handleClose={handleClose}
            cryptoSelectedAlert={cryptoSelectedAlert}
            setCriptoSelectedAlert={setCriptoSelectedAlert}
            socialVolumeSelectedAlert={socialVolumeSelectedAlert} 
            advertisingTypeSelectedAlert={advertisingTypeSelectedAlert} 
            setSocialVolumeSelectedAlert={setSocialVolumeSelectedAlert}
            setAdvertisingTypeSelectedAlert={setAdvertisingTypeSelectedAlert}
          ></Modal>
        </Box>
      </Box>
    </Box>
  )
}

export default Top
