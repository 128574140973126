import { memo, useState, useEffect, useRef } from 'react'
import { Box, Input } from 'theme-ui'
import { Row, Column } from '@carbonplan/components'
import { sx } from './sidebar'

const Search = ({ setSearch, tooltips }) => {
  const [value, setValue] = useState('')
  const [hasFocus, setFocus] = useState(false)
  const inputRef = useRef(null)

  useEffect(() => {
    function handler(event) {
      const { key, metaKey } = event
      if (key === '/' && metaKey) {
        if (!hasFocus) inputRef.current.focus()
      }
    }
    document.addEventListener('keydown', handler)
    return () => {
      document.removeEventListener('keydown', handler)
    }
  }, [hasFocus])

  return (
    <Box sx={{ mb: [-1], mt: [0, 0, 0, '22px'] }}>
      <Row columns={[6, 8, 4, 4]} sx={{ mt: [1, 1, 1, 2] }}>
        <Column start={[1, 1, 1, 1]} width={[2, 1, 1, 1]}>
          <Box sx={sx.label}>Search</Box>
        </Column>
        <Column start={[3, 3, 2, 2]} width={[4, 4, 3, 3]}>
          <Box sx={{ display: 'inline-block', float: 'right' }}>
            {/* <TooltipToggle
              tooltips={tooltips}
              value={value}
              setValue={setValue}
            /> */}
          </Box>
          <Box sx={{ position: 'relative', width: '90%' }}>
          <Input
            type='text'
            ref={inputRef}
            placeholder={'enter search term'}
            onChange={(e) => {
              setValue(e.currentTarget.value)
              setSearch(e.currentTarget.value)
            }}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            sx={{
              fontSize: [1],
              height: '24px',
              width: '90%',
              pt: [2],
              pb: [3],
              pl: [0],
              pr: [0],
              mt: ['3px'],
              fontFamily: 'mono',
              borderRadius: '0px',
              borderWidth: '0px',
              textAlign: 'left',
              display: 'inline-block',
              ':focus-visible': {
                outline: 'none !important',
                background: 'none !important',
              },
            }}
            value={value}
          />
        </Box>
          <Box sx={{ mt: [1] }}>
            {/* <TooltipDescription
              label={label}
              value={value}
              tooltips={tooltips}
            /> */}
          </Box>
        </Column>
      </Row>
    </Box>
  )
}

export default memo(Search)
