import React, { useState, useEffect } from 'react';
import { Row, Column } from '@carbonplan/components'
import Top from './top';
import ListAccounts from './listAccounts';
import AccountsService from '../services/AccountsService';




function Accounts ({settingsExpanded}) {

  var [accounts, setAccounts] = useState();
  var [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if(openModal === false){
      AccountsService.getAccounts()
      .then(respose=>{
        setAccounts(respose)
      })
    }
  },[openModal])
  
  return (
    <Row columns={[3, 6, 9, 9]}>
      <Column start={[1, 1, 1, 1]} width={[3, 6, 9, 9]}>
        <Top data={accounts} openModal={openModal} setOpenModal={setOpenModal}/>
        <ListAccounts data={accounts}></ListAccounts> 
      </Column>
    </Row>
  )
}

export default Accounts;