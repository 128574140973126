import { Rings } from  'react-loader-spinner';
import { useThemeUI, Box, Flex } from 'theme-ui';


function Spinner({label, labelTitle, labelText}) {
  const themeUI  = useThemeUI();

  return (
    <Flex>
      <Rings
        height="100"
        width="100"
        color={themeUI.theme.colors.secondary}
        ariaLabel='loading'
      />
      {
        label && (
        <Box sx={{mt: [5], ml:[4]}}>
          <Box
            sx={{
              display: 'inline-block',
              fontSize: [1, 1, 1, 2],
              fontFamily: 'mono',
              color: 'secondary',
              textTransform: 'uppercase',
              letterSpacing: 'mono',
            }}
          >
            {labelTitle}
          </Box>
          <Box
            sx={{
              mt: [2],
              fontSize: [2, 2, 2, 3],
            }}
          >
            {labelText}
          </Box>
        </Box>
        )

      }
      
    </Flex>
    
  )
}

export default Spinner;

