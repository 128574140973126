import { useState, useEffect } from 'react';
import { Redirect, useHistory } from "react-router-dom";
import { Row, Column, Guide, Button } from '@carbonplan/components';
import Layout from '../components/layout';
import { Box, Divider, Input, useColorMode } from 'theme-ui';
import { Right } from '@carbonplan/icons';
import { ReactComponent as SvgLogo } from '../images/DefianceCircle-01.svg';
import authenticationService from '../services/AuthenticationService';
import AlertComponent from '../components/AlertComponent';
import { motion } from 'framer-motion';


function Index() {
  const [user, setUser] = useState('');
  const [password, setPassword] = useState('');
  const [loginOver, setLoginOver] = useState(false);
  const [showSignUp, setShowSignUp] = useState(false);
  const [colorMode] = useColorMode();
  const history = useHistory();
  const [alertLogin, setAlertLogin] = useState(false);
  const [userFullName, setUserFullname] = useState('')
  const [userEmail, setUserEmail] = useState('');
  const [userPassword, setUserPassword] = useState('');
  const [userConfirmPassword, setUserConfirmPassword] = useState('');
  const [alertSignUp, setAlertSignUp] = useState(false);
  const [alertSignUpText, setAlertSignUpText] = useState('Signing up error');
  const [alertSignUpVerification, setAlertSignUpVerification] = useState(false);

  useEffect(() => {
    document.title = "Defiance Analytics | Login";
    //blockScroll(true);
  }, [])

  const logIn = function(){
    authenticationService.login(user, password)
    .then(res=>{
      if(localStorage.getItem('currentUser')){
        history.push("/");
      }else{
        setAlertLogin(true);
      }
    })
  }

  if(localStorage.getItem('currentUser')){
    return <Redirect to="/" />
  }

  const signUp = function(){
    if(userFullName === ''){
      setAlertSignUpText("The full name can't be blank");
      setAlertSignUp(true);
      return;
    }
    if(userEmail === ''){
      setAlertSignUpText("The email can't be blank");
      setAlertSignUp(true);
      return;
    }
    if(userPassword === ''){
      setAlertSignUpText("The password can't be blank");
      setAlertSignUp(true);
      return;
    }
    if(userConfirmPassword === ''){
      setAlertSignUpText("The confirm password can't be blank");
      setAlertSignUp(true);
      return;
    }
    if(userPassword !== userConfirmPassword){
      setAlertSignUpText('The passwords do not match');
      setAlertSignUp(true);
      return;
    }
    //More password security verifications 

    authenticationService.signup(userFullName, userEmail, userPassword)
    .then(res=>{
      console.log(res);
    })

    setAlertSignUpVerification(true);

  }
  
  return (
    <motion.div
    initial={{ scaleY: 0 }}
    animate={{ scaleY: 1 }}
    exit={{ scaleY: 0 }}
    transition={{ duration: 0.5 }}
    >
      <Layout
        footer={false}
        metadata={false}
        dimmer={'top'}
        // settings={{
        //   value: settingsExpanded,
        //   onClick: () => setSettingsExpanded(!settingsExpanded),
        // }}
        title={'cdr database / research / carbonplan'}
        description={'Public database of reports on carbon removal projects.'}
        card={'https://images.carbonplan.org/social/cdr-database.png'}
        nav={'research'}
        hideMenu = {true}
      >
        <Guide />
        <Box>
          <Row columns = {[3, 6, 9, 9]} >
            <Column start={[1]} width={[3, 6, 5, 5]}>
              <Box
                sx={{
                  fontSize: [6, 6, 6, 7],
                  width: 'fit-content',
                  fontFamily: 'AT Surt Medium',
                  lineHeight: 'heading',
                  paddingTop: [8, 8, 10, 10],
                  paddingLeft: [5, 5, 10, 10],
                  
                }}
              >
                Defiance Analitycs
              </Box>
              <Box sx={{
                width:[1],
                height:[1],
                '.logo':{
                  height:['400px', '400px', '700px'], 
                  width:['350px', '400px', '700px'], 
                  position:'relative',
                  top: ['-400px', '-400px', '-150px'], 
                  left: ['-150px', '-150px', '-170px'],
                  zIndex: '-1',
                  opacity: colorMode === 'light' ? 0.5 : 1,
                  transform:  colorMode === 'light' ? 'rotate(180deg)' : ''
                }
              }}> 
              <SvgLogo className='logo'></SvgLogo>
              </Box>
            </Column>
            <Column start={[1, 1, 6, 6]} width={[3, 6, 4, 4]} >
              { !showSignUp && (
                <Box>
                  <Box
                    sx={{
                      fontSize: [6, 6, 6, 7],
                      width: '90%',
                      lineHeight: 'heading',
                      paddingTop: [8, 8, 9, 9],
                      paddingLeft: [5, 5, 0, 0],

                    }}>
                    <Input
                      type='text'
                      placeholder={'Username'}
                      onChange={(e) => {
                        setUser(e.target.value)
                      }}
                      value={user}
                      sx={{
                        height: '24px',
                        width: '90%',
                        pt: [3],
                        pb: [3],
                        pl: [0],
                        pr: [0],
                        mt: ['3px'],
                        fontFamily: 'mono',
                        fontSize: [25],
                        borderRadius: '0px',
                        borderWidth: '0px',
                        textAlign: 'left',
                        display: 'inline-block',
                        ':focus-visible': {
                          outline: 'none !important',
                          background: 'none !important',
                        },
                      }}
                    />
                    <Divider sx={{ my: [0], mt: ['11px', '11px', '11px', '14px'] }} />
                    <Input
                      type='password'
                      placeholder={'Password'}
                      onChange={(e) => {
                        setPassword(e.target.value)
                      }}
                      onKeyDown={(event)=> {
                        if (event.keyCode === 13) {
                          logIn()
                        }
                      }}
                      value={password}
                      sx={{
                        height: '24px',
                        width: '90%',
                        pt: [2],
                        pb: [3],
                        pl: [0],
                        pr: [0],
                        mt: [5],
                        fontFamily: 'mono',
                        fontSize: [25],
                        borderRadius: '0px',
                        borderWidth: '0px',
                        textAlign: 'left',
                        display: 'inline-block',
                        ':focus-visible': {
                          outline: 'none !important',
                          background: 'none !important',
                        },
                      }}
                    />
                    <Divider sx={{ my: [0], mt: ['11px', '11px', '11px', '14px'], pb: alertLogin ? [4] : [0] }}/>
                    {alertLogin && <AlertComponent type={'error'} strongText={'The username or password is incorrect'}></AlertComponent>}
                  </Box>
                  <Button
                    sx={{
                      textDecoration: 'none',
                      fontSize: [5],
                      color: 'text',
                      fontFamily: 'mono',
                      letterSpacing: 'mono',
                      display: 'block',
                      marginTop: alertLogin ? [4, 4, 6, 6] : [6, 6, 7, 7],
                      marginLeft:[5, 5, 0, 0],
                      cursor: 'pointer',
                      opacity: 0.7,
                      '&: hover': {
                        fontSize:[6],
                        transition: '0.15s',
                        color: 'text',
                        opacity: 1
                      }
                    }}
                    onMouseOver={() => setLoginOver(true)}
                    onMouseLeave={() => setLoginOver(false)}
                    onClick={()=>{
                      logIn()
                    }}
                    >
                      LOGIN
                      <Right 
                        sx={{
                          width: '30px',
                          ml: [4],
                          stroke: 'text',
                          color: 'text',
                          strokeWidth: loginOver ? 2 : 1.5,
                          opacity: loginOver ? 1 : 0.7,
                        }}
                      />
                  </Button>
                  <Box sx={{
                    fontSize: [3],
                    fontFamily: 'AT Surt Medium',
                    lineHeight: 'heading',
                    paddingTop: [3, 3, 6, 6],
                    color: 'secondary',
                    paddingLeft: [5, 5, 0, 0],
                    flexWrap: 'wrap'
                  }}>
                    <Box sx={{
                      whiteSpace: 'nowrap',
                      paddingBottom: [4, 4]
                    }}>Don't have an account?</Box>
                    <Box 
                      sx={{
                        color: 'text',
                        opacity: 0.7,
                        cursor: 'pointer',
                        whiteSpace: 'nowrap',
                        '&: hover': {
                          fontSize:[4],
                          transition: '0.15s',
                          color: 'text',
                          opacity: 1
                        },
                      }}
                    onClick={()=>{
                      setShowSignUp(true);
                    }}
                    >SIGN UP</Box>
                  </Box>
                </Box>
              )}

              { showSignUp && !alertSignUpVerification && (
                
                <Box>
                  <Box sx={{
                    fontSize: [4],
                    width: 'fit-content',
                    fontFamily: 'AT Surt Medium',
                    lineHeight: 'heading',
                    paddingTop: [3, 3, 7, 7],
                    paddingLeft: [5, 5, 0, 0],
                    color: 'secondary'
                  }}>
                    Sign up to use Defiance Analytics app.
                  </Box>
                  <Box
                    sx={{
                      fontSize: [5, 5, 5, 6],
                      width: '90%',
                      lineHeight: 'heading',
                      paddingLeft: [5, 5, 0, 0],
                      paddingTop: [5]

                    }}>
                    <Input
                      type='text'
                      placeholder={'Full name'}
                      onChange={(e) => {
                        setUserFullname(e.target.value)
                      }}
                      value={userFullName}
                      sx={{
                        height: '24px',
                        width: '90%',
                        pt: [3],
                        pb: [3],
                        pl: [0],
                        pr: [0],
                        mt: ['3px'],
                        fontFamily: 'mono',
                        fontSize: [25],
                        borderRadius: '0px',
                        borderWidth: '0px',
                        textAlign: 'left',
                        display: 'inline-block',
                        ':focus-visible': {
                          outline: 'none !important',
                          background: 'none !important',
                          },
                      }}
                    />
                    <Divider sx={{ my: [0], mt: ['11px', '11px', '11px', '14px'] }} />
                    <Input
                      type='text'
                      placeholder={'Email'}
                      onChange={(e) => {
                        setUserEmail(e.target.value)
                      }}
                      value={userEmail}
                      sx={{
                        height: '24px',
                        width: '90%',
                        pt: [3],
                        pb: [3],
                        pl: [0],
                        pr: [0],
                        mt: [2],
                        fontFamily: 'mono',
                        fontSize: [25],
                        borderRadius: '0px',
                        borderWidth: '0px',
                        textAlign: 'left',
                        display: 'inline-block',
                        ':focus-visible': {
                          outline: 'none !important',
                          background: 'none !important',
                        },
                      }}
                    />
                    <Divider sx={{ my: [0], mt: ['11px', '11px', '11px', '14px'] }} />
                    <Input
                      type='password'
                      placeholder={'Password'}
                      onChange={(e) => {
                        setUserPassword(e.target.value)
                      }}
                      onKeyDown={(event)=> {
                        if (event.keyCode === 13) {
                          logIn()
                        }
                      }}
                      value={userPassword}
                      sx={{
                        height: '24px',
                        width: '90%',
                        pt: [2],
                        pb: [3],
                        pl: [0],
                        pr: [0],
                        mt: [2],
                        fontFamily: 'mono',
                        fontSize: [25],
                        borderRadius: '0px',
                        borderWidth: '0px',
                        textAlign: 'left',
                        display: 'inline-block',
                        ':focus-visible': {
                          outline: 'none !important',
                          background: 'none !important',
                        },
                      }}
                    />
                    <Divider sx={{ my: [0], mt: ['11px', '11px', '11px', '14px'] }} />
                    <Input
                      type='password'
                      placeholder={'Confirm password'}
                      onChange={(e) => {
                        setUserConfirmPassword(e.target.value)
                      }}
                      onKeyDown={(event)=> {
                        if (event.keyCode === 13) {
                          signUp()
                        }
                      }}
                      value={userConfirmPassword}
                      sx={{
                        height: '24px',
                        width: '90%',
                        pt: [2],
                        pb: [3],
                        pl: [0],
                        pr: [0],
                        mt: [2],
                        fontFamily: 'mono',
                        fontSize: [25],
                        borderRadius: '0px',
                        borderWidth: '0px',
                        textAlign: 'left',
                        display: 'inline-block',
                        ':focus-visible': {
                          outline: 'none !important',
                          background: 'none !important',
                        },
                      }}
                    />
                    <Divider sx={{ my: [0], mt: ['11px', '11px', '11px', '14px'], pb: alertLogin ? [4] : [0] }}/>
                      {alertSignUp && <AlertComponent type={'error'} strongText={alertSignUpText}></AlertComponent>}
                  </Box>
                  <Button
                    sx={{
                      textDecoration: 'none',
                      fontSize: [5],
                      color: 'text',
                      fontFamily: 'mono',
                      letterSpacing: 'mono',
                      display: 'block',
                      marginTop: alertLogin ? [4, 4, 6, 6] : [6, 6, 8, 8],
                      marginLeft:[5, 5, 1, 1],
                      cursor: 'pointer',
                      opacity: 0.7,
                      '&: hover': {
                        fontSize:[6],
                        transition: '0.15s',
                        color: 'text',
                        opacity: 1
                      }
                    }}
                    onMouseOver={() => setLoginOver(true)}
                    onMouseLeave={() => setLoginOver(false)}
                    onClick={()=>{
                      signUp()
                    }}
                    >
                      SIGN UP
                      <Right 
                        sx={{
                          width: '30px',
                          ml: [4],
                          stroke: 'text',
                          color: 'text',
                          strokeWidth: loginOver ? 2 : 1.5,
                          opacity: loginOver ? 1 : 0.7,
                        }}
                      />
                  </Button>
                  
                </Box>
              )}
              
              { showSignUp && alertSignUpVerification && (
                
                <Box>
                  <Box sx={{
                    fontSize: [4],
                    width: 'fit-content',
                    fontFamily: 'AT Surt Medium',
                    lineHeight: 'heading',
                    paddingTop: [5, 5, 10, 10],
                    paddingLeft: [5, 5, 0, 0],
                    color: 'secondary'
                  }}>
                    <AlertComponent type={'success'} strongText={'We have sent you an email to verify your account.'}></AlertComponent>
                  </Box>
                  
                </Box>
              )}
              
            </Column>
          </Row>
        </Box>


      </Layout>
    </motion.div>
  )
}

export default Index
