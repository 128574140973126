import { memo } from 'react'
import { Box } from 'theme-ui'
import { useMedia } from 'react-use'
import { FadeIn } from '@carbonplan/components'
import Pagination from './pagination';
import NewsComponent from '../components/NewsComponent';
import TickerComponent from '../components/TickerComponent';

const List = ({
  filters,
  setFilters,
  filtered,
  data,
  setHighlighted,
  tooltips,
  setTooltips
}) => {
  const isWide = useMedia('screen and (min-width: 72em)')
  return (
  <>
    <Box>
      {filtered.init && (
        <FadeIn delay={10} duration={150}>
          {filtered.count > 0 && (
            <Pagination
              itemsPerPage={isWide ? 40 : 50}
              items={data}
              filtered={filtered}
              setHighlighted={setHighlighted}
              tooltips={tooltips}
              isWide={isWide}
            />
          )}
          {filtered.count === 0 && filtered.init === true && (
            <Box
              sx={{
                pl: [0, 0, 0],
                pt: [3, 2, '150px'],
                maxWidth: '350px',
                margin: [0, 0, 'auto'],
              }}
            >
              <Box
                sx={{
                  display: 'inline-block',
                  fontSize: [1, 1, 1, 2],
                  fontFamily: 'mono',
                  color: 'secondary',
                  textTransform: 'uppercase',
                  letterSpacing: 'mono',
                }}
              >
                No results found
              </Box>
              <Box
                sx={{
                  mt: [2],
                  fontSize: [2, 2, 2, 3],
                }}
              >
                Please try changing the settings in the filter panel and try
                again.
              </Box>
            </Box>
          )}
        </FadeIn>
      )}
    </Box>
    
    <Box>
      <NewsComponent orientation={'horizontal'}></NewsComponent>  
    </Box>
    <Box>
      <TickerComponent></TickerComponent>
    </Box>
</>
  )
}

export default memo(List)
