import { memo, useEffect, useState } from 'react';
import { useHistory  } from "react-router-dom";
import { Box, useThemeUI } from 'theme-ui';
import LineChartComponent  from '../components/LineChart';
import Spinner from '../components/Spinner';
import NoData from '../components/NoDataComponent';
import holdingsService from '../services/HoldingsService';


const ItemEvent = ({ data, setHighlighted }) => {

  const history = useHistory(); 

  let {
    id,
    source_id,
    source_model,
    updated_at,
    metric,
    confidence_interval,
    moving_avg_days,
  } = data;

  var [eventData, setEventData] = useState([]);
  var [loadingSpinner, setLoadingSpinner] = useState(false);
  var colors = useThemeUI().theme.colors;
  var [plotBands, setPlotBands] = useState([]);

  
  let endDate = new Date(updated_at);
  let endDateDay = String(endDate.getDate()).padStart(2, '0');
  let endDateMonth = String(endDate.getMonth() + 1).padStart(2, '0'); //January is 0!
  let endDateYear = endDate.getFullYear();
  let endDateFormated = endDateYear + '-' + endDateMonth + '-' + endDateDay;

  let startDate = new Date(endDate);
  startDate.setDate(startDate.getDate() - 20);
  let startDateDay = String(startDate.getDate()).padStart(2, '0');
  let startDateMonth = String(startDate.getMonth() + 1).padStart(2, '0'); //January is 0!
  let startDateYear = startDate.getFullYear();
  let startDateFormated = startDateYear + '-' + startDateMonth + '-' + startDateDay;

  useEffect(() => {
    setLoadingSpinner(true);
    holdingsService.getChartData(source_id, { confidenceInterval: confidence_interval, startDate: startDateFormated, endDate: endDateFormated, movingAverageDays: moving_avg_days, measurement: metric })
        .then(response => {
          if (response && response[0]) {
            var objX = [];
            var objUCI = [];
            var objC = [];
            var highlights = [];

            response[0].records.forEach((record, idx) => {
              objX.push([new Date(record.values._time).getTime(), record.values._value]);
              objUCI.push([new Date(record.values._time).getTime(), record.values._value_UCI]);
              objC.push([new Date(record.values._time).getTime(), null]);
              if (record.values._value_trigger) {
                let objTrigger = {}
                objTrigger.from = new Date(record.values._time).getTime()
                objTrigger.color = colors.muted;
                objTrigger.to = new Date(response[0].records[idx + 2] ? response[0].records[idx + 2].values._time : new Date(record.values._time).getTime() + 1000 * 60 * 60 * 24 * 3).getTime()
                highlights.push(objTrigger);
              }
            });
            setPlotBands(highlights)
            setEventData([
              {
                name: "Trading Volume",
                data: objX
              },
              {
                name: "Threshold",
                data: objUCI,
                dashStyle: 'shortdash'
              },
            ]);
          }
          setLoadingSpinner(false);
        })
  },[colors.muted, confidence_interval, endDateFormated, metric, moving_avg_days, source_id, startDateFormated])

  return (
    <Box
      onMouseEnter={() => {
        if (setHighlighted) setHighlighted(id)
      }}
      onMouseLeave={() => {
        if (setHighlighted) setHighlighted(null)
      }}
      onClick={(e) => {
        let path; 
        //if(source_model === "CatEtf") path = `/etf/${source_id}?metric=volume&start=${startDateFormated}&end=${endDateFormated}`
        //if(source_model === "CatCrypto") path = `/crypto/${source_id}?metric=social&start=${startDateFormated}&end=${endDateFormated}`; 
        if(source_model === "CatEtf") path = `/chart/etf/${source_id}?metric=volume&start=${startDateFormated}&end=${endDateFormated}`
        if(source_model === "CatCrypto") path = `/chart/crypto/${source_id}?metric=social&start=${startDateFormated}&end=${endDateFormated}`; 
        history.push(path);
      }}
      sx={{
        borderStyle: 'solid',
        borderColor: 'muted',
        borderWidth: '1px',
        height: [170],
        paddingBottom: [4],
        borderRadius: '0px',
        cursor: 'pointer',
        pl: [0, 0, 0],
        pr: [0],
        pb: [2, 2, 0, 0],
        pt: ['0px', '0px', '0px'],
        my: [3],
        mb: [0, 0, 0],
        transition: 'border-color 0.15s',
        '&:hover': {
          borderColor: ['muted', 'muted', 'secondary'],
        },
        '@media (hover: hover) and (pointer: fine)': {
          '&:hover > #container > #box > #flex > #text > #expander': {
            stroke: 'primary',
          },
        },
        '@media (hover: none) and (pointer: coarse)': {
          '#container > #box > #flex > #text > #expander': {
            stroke: 'primary',
          },
        },
      }}
    >
      <Box sx={{
        width: '100%',
        paddingTop: [3],
        paddingX: [3],
        display: 'inline-block',
        fontSize: [3, 3, 3, 4],
        lineHeight: 'heading',
      }}>
        {source_id + ' ' + metric}
      </Box>
      
      {!loadingSpinner ? 
        ( eventData.length > 0 ?
          <Box>
            <LineChartComponent 
              data={eventData} 
              plotBands={plotBands}
              colors={[colors.blue, colors.gray, colors.orange, colors.green]}
              height={'8em'}
              legend={false}
              tooltipDisabled={true}
              disableMouseTracking={true}
            >
            </LineChartComponent>
          </Box>
          :
          <Box sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            mt: [4, 4, 4, 4]
          }}>
            <NoData labelTitle={'NO DATA'} labelText={'No data available.'}></NoData>
          </Box>
        )
      : 
        <Box sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}>
          <Spinner label={true} labelTitle={'Loading'} labelText='Please wait. We are pulling data.'></Spinner>
        </Box>
      }
    </Box>
  )
}

export default memo(ItemEvent)
