import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useThemeUI  } from 'theme-ui';
import ComboBox from './Autocomplete';
import cryptoService from '../services/CryptoService';
import AlertComponent from './AlertComponent';

const socialVolumeList = [
  {
    label: "Total",
    value: "social_volume_total"
  },
  {
    label: "Twitter",
    value: "social_volume_twitter"
  },
  {
    label: "Reddit",
    value: "social_volume_reddit"
  }
]
const advertisingTypeList = [
  {
    label:"Google Adwards",
    value:"google_adwards"
  }
]

export default function FormDialog({
  openModal, 
  handleClose, 
  cryptoSelectedAlert,
  setCriptoSelectedAlert,
  socialVolumeSelectedAlert,
  setSocialVolumeSelectedAlert, 
  advertisingTypeSelectedAlert,
  setAdvertisingTypeSelectedAlert
}) {
  const themeUI  = useThemeUI();
  const theme = createTheme({
    components:{
      MuiPaper:{
        styleOverrides: {
          root: {
            color: themeUI.theme.colors.text,
            background:themeUI.theme.colors.background
          },
        },
      },
      MuiTypography:{
        styleOverrides: {
          root:{
            color: themeUI.theme.colors.text + '!important',
          }
        }
      },
      MuiFormLabel:{
        styleOverrides: {
          root:{
            color: themeUI.theme.colors.text + '!important',
          }
        }
      },
      MuiSvgIcon:{
        styleOverrides: {
          root:{
            color: themeUI.theme.colors.text + '!important',
          }
        }
      },
      MuiOutlinedInput:{
        styleOverrides: {
          root: {
            ':hover .MuiOutlinedInput-notchedOutline': {
              borderColor: themeUI.theme.colors.muted,
            },
            color: themeUI.theme.colors.text + '!important',
          },
          notchedOutline:{
            borderColor: themeUI.theme.colors.text,
           },
        }
      },
    }
    
  });

  var [cryptoList, setCryptoList] = useState([]);
  var [cryptoSelected, setCryptoSelected] = useState(null);
  var [socialVolumeSelected, setSocialVolumeSelected] = useState(null);
  var [advertisingTypeSelected, setAdvertisingType] = useState(null);


  const handleAdd = () => {
    if(!cryptoSelected) setCriptoSelectedAlert(true);
    else setCriptoSelectedAlert(false);
    if(!socialVolumeSelected) setSocialVolumeSelectedAlert(true);
    else setSocialVolumeSelectedAlert(false);
    if(!advertisingTypeSelected) setAdvertisingTypeSelectedAlert(true);
    else setAdvertisingTypeSelectedAlert(false);

    if(cryptoSelected && socialVolumeSelected && advertisingTypeSelected){
      cryptoService.postAccountCryptos(cryptoSelected.symbol, socialVolumeSelected.value, advertisingTypeSelected.value)
      .then(res=>{
        handleClose();
      })
    }
  };

  useEffect(
    () => {
      cryptoService.getCryptos()
        .then(response => {
          response = response.map(r=> {r['label'] = r.name; return r})
          setCryptoList(response)
        })
    }, [])

  return (
    <>
      <ThemeProvider theme={theme}>
      <Dialog 
        open={openModal} 
        onClose={handleClose}
        fullWidth={true}
        maxWidth={'sm'}
        >
        <DialogTitle> Add new crypto</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{
              mb:[2]
            }}>
            To start tracking a new crypto, please enter the name and select from the lists:
          </DialogContentText >
          <Box sx={{
            display: 'flex',
            alignItems: 'center'
          }}>
            <ComboBox 
              data={cryptoList} 
              label={'Crypto'}
              value={cryptoSelected}
              setValue= {setCryptoSelected}
              setAlert={setCriptoSelectedAlert}
              sx={{
                width: 300,
                marginRight: '10px'
              }}
            />
            {cryptoSelectedAlert && 
              <AlertComponent 
                type={'error'} 
                strongText={'Select a crypto.'} 
                sx={{width: 250}
              }></AlertComponent>}
          </Box>
          <DialogContentText sx={{
              mt: [3],
              mb:[2]
            }}>
            Pick a social volume to track:
          </DialogContentText>
          <Box sx={{
            display: 'flex',
            alignItems: 'center'
          }}>
            <ComboBox 
              data={socialVolumeList} 
              label={'Social volume'}
              value={socialVolumeSelected}
              setValue={setSocialVolumeSelected}
              setAlert={setSocialVolumeSelectedAlert}
              sx={{
                width: 300,
                marginRight: '10px'
              }}
            />
            {socialVolumeSelectedAlert && 
              <AlertComponent 
                type={'error'} 
                strongText={'Select a social volume.'} 
                sx={{width: 250}
              }></AlertComponent>}
          </Box>
          <DialogContentText sx={{
              mt: [3],
              mb:[2]
            }}>
            Pick a advertising:
          </DialogContentText>
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
          }}>
            <ComboBox 
              data={advertisingTypeList} 
              label={'Advertising'}
              value={advertisingTypeSelected} 
              setValue={setAdvertisingType}
              setAlert={setAdvertisingTypeSelectedAlert}
              sx={{
                width: 300,
                marginRight: '10px'
              }}
            />
            {advertisingTypeSelectedAlert && 
              <AlertComponent 
                type={'error'} 
                strongText={'Select a advertising type.'} 
                sx={{width: 250}
              }></AlertComponent>}
          </Box>  
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleAdd}>Add</Button>
        </DialogActions>
      </Dialog>
      </ThemeProvider >
    </>
  );
}
