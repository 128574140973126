import { Route, Switch, useLocation } from 'react-router-dom';
import Login from'../Login';
import PriveteRoute from '../Login/priveteRoute';
//import CryptoDetail from '../CryptoDetail';
//import EtfDetails from '../EtfDetail';
import EtfHoldings from '../EtfHoldings';
//import StockDetail from '../StockDetail';
import TestingComponents from '../TestingComponents';
import NotFound from '../NotFound';
import Home from '../Home';
import CampaignDetail from '../CampaignDetail';
import Accounts from '../Accounts';
import AccountsCampaigns from '../AccountsCampaigns';
import ChartBuilder from '../ChartBuilder';
import ControlChart from '../ControlChart';
import { AnimatePresence } from 'framer-motion';
import { LicenseInfo } from '@mui/x-license-pro';

function App({ signOut, /*user*/ }) {
  const location = useLocation();
  LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENCE);
  return (
    <AnimatePresence exitBeforeEnter initial={false}>
      <Switch location={location} key={location.pathname}>
        <Route path='/login' exact component={Login}></Route>
        <PriveteRoute path='/' exact component={Home}></PriveteRoute>
        <PriveteRoute path='/components' exact component={TestingComponents}></PriveteRoute>
        <PriveteRoute path='/campaign/:id' exact component={CampaignDetail}></PriveteRoute>
        <PriveteRoute path='/chart/:stockType/:slug' exact component={ControlChart}></PriveteRoute>
        {/* <PriveteRoute path='/crypto/:slug' exact component={CryptoDetail}></PriveteRoute>
        <PriveteRoute path='/stock/:slug' exact component={StockDetail}></PriveteRoute>
        <PriveteRoute path='/etf/:slug' exact component={EtfDetails}></PriveteRoute> */}
        <PriveteRoute path='/chartbuilder' exact component={ChartBuilder}></PriveteRoute>
        <PriveteRoute path='/etf/:slug/holdings' exact component={EtfHoldings}></PriveteRoute>
        <PriveteRoute path='/accounts' exact component={Accounts}></PriveteRoute>
        <PriveteRoute path='/accounts/:id/campaigns' exact component={AccountsCampaigns}></PriveteRoute>
        <PriveteRoute component={ NotFound }/>
      </Switch>
    </AnimatePresence>
  )
}

export default App;
