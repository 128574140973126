import { useState } from 'react';
import { useThemeUI, Box, Flex } from 'theme-ui';
import NewsDetailModal from './NewsDetailModal';


function NewsElement({newArticle, paddingX}) {
  const themeUI  = useThemeUI();
  var [openModal, setOpenModal] = useState(false);
  const handleClose = () => {
    setOpenModal(false);
  };
  
  const handleClickOpen = () => {
    setOpenModal(true);
  };
  
  return (
    <Box sx={{
      paddingY:[2, 0, 0, 0]
      }}
    >
      <Box sx={{paddingX}} onClick= {()=>{handleClickOpen()}}>
        <Flex id="head" sx={{justifyContent: 'space-between', paddingTop:[2]}}>
          <Flex id="title" sx={{alignItems: 'center', maxWidth: '80%'}}>
            <Box id="text" sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden !important',
              textOverflow: 'ellipsis',
              fontSize: [3, 3, 3, 4],
              fontWeight: 500
            }}>
              {(newArticle && newArticle._value_source) || 'Economic New Crypto'}
            </Box>
          </Flex>
          <Box sx={{    
              paddingLeft: 1,
              fontFamily: 'mono',
              letterSpacing: 'mono',
              fontSize: [1, 1, 1, 2],
              textTransform: 'uppercase',
              color: themeUI.theme.tags.news,
              mt: ['3px'],
              userSelect: 'none',
              marginLeft: [2]
              }}>
                NEWS
            </Box>
          
        </Flex>
        <Box id="content">
          <Box id="message" 
            sx={{
              pt:[3], 
              height:[80, 97, 97, 97],
              display: '-webkit-box',
              maxWidth: '100%',
              WebkitLineClamp: 5,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden'
            }}>
            {(newArticle && newArticle._value_headline) || 'Generate convincing fake tweet images'}
          </Box>
          <Flex id="info" sx={{color: themeUI.theme.colors.muted, fontSize: [1], paddingTop: [1], paddingBottom: [2]}}>
            {/* <div id="time">9:41 AM</div>
            &nbsp;·&nbsp;
            <div id="date">Apr 13, 2022</div> */}
            {(newArticle && newArticle.date)}
          </Flex>
        </Box>
      </Box>
      <NewsDetailModal
        openModal={openModal}
        handleClose={handleClose}
        newArticle={newArticle}
      ></NewsDetailModal>
    </Box>

    
  )
}

export default NewsElement;

