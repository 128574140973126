import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useThemeUI } from 'theme-ui';

export default function NewsDetailModal({
  openModal, 
  handleClose,
  newArticle 
}) {
  const themeUI  = useThemeUI();
  const theme = createTheme({
    components:{
      MuiPaper:{
        styleOverrides: {
          root: {
            color: themeUI.theme.colors.text,
            background:themeUI.theme.colors.background,
          },
        },
      },
      MuiTypography:{
        styleOverrides: {
          root:{
            color: themeUI.theme.colors.text + '!important',
          }
        }
      },
      MuiFormLabel:{
        styleOverrides: {
          root:{
            color: themeUI.theme.colors.text + '!important',
          }
        }
      },
      MuiSvgIcon:{
        styleOverrides: {
          root:{
            color: themeUI.theme.colors.text + '!important',
          }
        }
      },
      MuiOutlinedInput:{
        styleOverrides: {
          root: {
            ':hover .MuiOutlinedInput-notchedOutline': {
              borderColor: themeUI.theme.colors.muted,
            },
            color: themeUI.theme.colors.text + '!important',
          },
          notchedOutline:{
            borderColor: themeUI.theme.colors.text,
           },
        }
      },
      MuiDialogContent:{  
        styleOverrides: {
          root: {
            maxHeight: '600px'
          }
        }
      }
    }
    
  });
  
  
  return (
    <>
      <ThemeProvider theme={theme}>
      <Dialog 
        open={openModal} 
        onClose={()=>{handleClose();}}
        // fullWidth={true}
        maxWidth={'sm'}
      >
        <DialogTitle>{newArticle && newArticle._value_headline}</DialogTitle>
        <DialogContent>
          <Box sx={{mb:[2]}}>
            <img alt={newArticle && newArticle._value_image} src={newArticle && newArticle._value_image} style={{ maxHeight:'300px'}} />
          </Box>
          <DialogContentText sx={{
              mb:[2]
            }}>
            {newArticle && newArticle._value_summary}
          </DialogContentText>
            
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>{handleClose()}}>Close</Button>
          <Button onClick={()=>{if(newArticle._value_url) window.open(newArticle._value_url);} }>Go to Site</Button>
        </DialogActions>
      </Dialog>
      </ThemeProvider >
    </>
  );
}
