import { useThemeUI, Box, Flex } from 'theme-ui';


function RedditElement({label, labelTitle, labelText}) {
  const themeUI  = useThemeUI();
  return (
    <Box sx={{
      paddingY:[2, 0, 0, 0]
    }}>
      <Box sx={{ paddingX: [2]}}>
        <Flex id="head" sx={{justifyContent: 'space-between'}}>
          <Flex id="title">
            <Box id="text">
              <Flex id="top" sx={{paddingBottom:[1], alignItems: 'end'}}>
                <Box id="name" sx={{paddingRight:[2]}}>Name</Box>
                <svg xmlns="http://www.w3.org/2000/svg" id="tweet_verified" width="18" height="18" viewBox="0 0 24 24" fill="currentColor">
                  <g>
                    <path d="M22.5 12.5c0-1.58-.875-2.95-2.148-3.6.154-.435.238-.905.238-1.4 0-2.21-1.71-3.998-3.818-3.998-.47 0-.92.084-1.336.25C14.818 2.415 13.51 1.5 12 1.5s-2.816.917-3.437 2.25c-.415-.165-.866-.25-1.336-.25-2.11 0-3.818 1.79-3.818 4 0 .494.083.964.237 1.4-1.272.65-2.147 2.018-2.147 3.6 0 1.495.782 2.798 1.942 3.486-.02.17-.032.34-.032.514 0 2.21 1.708 4 3.818 4 .47 0 .92-.086 1.335-.25.62 1.334 1.926 2.25 3.437 2.25 1.512 0 2.818-.916 3.437-2.25.415.163.865.248 1.336.248 2.11 0 3.818-1.79 3.818-4 0-.174-.012-.344-.033-.513 1.158-.687 1.943-1.99 1.943-3.484zm-6.616-3.334l-4.334 6.5c-.145.217-.382.334-.625.334-.143 0-.288-.04-.416-.126l-.115-.094-2.415-2.415c-.293-.293-.293-.768 0-1.06s.768-.294 1.06 0l1.77 1.767 3.825-5.74c.23-.345.696-.436 1.04-.207.346.23.44.696.21 1.04z"></path>
                  </g>
                </svg>
              </Flex>
              <Box>
                @<span id="username">username</span>
              </Box>
            </Box>
          </Flex>
          <Box>
            <Box sx={{    
              fontFamily: 'mono',
              letterSpacing: 'mono',
              fontSize: [1, 1, 1, 2],
              textTransform: 'uppercase',
              color: themeUI.theme.tags.reddit,
              mt: ['3px'],
              userSelect: 'none',
              }}>
                REDDIT
            </Box>
            {/* <svg height="25px" version="1.1" viewBox="0 0 512 512" width="25px" xmlns="http://www.w3.org/2000/svg" fill='#FF4500'>
              <g id="_x32_75-reddit"><g>
                <path d="M434.705,205.146c-14.544,0-27.342,6.012-36.744,15.416    c-34.612-23.946-81.248-39.363-132.924-41.011l26.856-120.998l85.513,19.197c0,20.942,17.063,38.005,38.006,38.005    c21.329,0,38.489-17.549,38.489-38.49c0-20.942-17.063-38.49-38.489-38.49c-14.933,0-27.826,9.017-34.226,21.329l-94.432-20.942    c-4.751-1.26-9.405,2.133-10.665,6.884l-29.475,133.407c-51.288,2.133-97.438,17.549-132.146,41.496    c-9.406-9.792-22.687-15.804-37.231-15.804c-53.906,0-71.552,72.326-22.203,97.051c-1.744,7.66-2.52,15.805-2.52,23.945    c0,81.248,91.524,147.08,203.892,147.08c112.854,0,204.378-65.832,204.378-147.08c0-8.141-0.872-16.674-3.005-24.332    C506.158,276.99,488.32,205.146,434.705,205.146L434.705,205.146z" />
                <path d="M133.277,307.334c0-21.328,17.063-38.49,38.49-38.49c20.942,0,38.005,17.064,38.005,38.49    c0,20.941-17.063,38.008-38.005,38.008C150.438,345.439,133.277,328.275,133.277,307.334z"/>
                <path d="M341.048,397.986c-35.291,35.289-134.862,35.289-170.153,0c-3.877-3.393-3.877-9.404,0-13.283    c3.395-3.391,9.405-3.391,12.799,0c26.953,27.633,116.344,28.119,144.461,0c3.393-3.391,9.402-3.391,12.797,0    C344.927,388.582,344.927,394.594,341.048,397.986L341.048,397.986z"/>
                <path d="M340.273,345.439c-20.943,0-38.006-17.066-38.006-38.008c0-21.328,17.063-38.49,38.006-38.49    c21.328,0,38.488,17.064,38.488,38.49C378.667,328.275,361.602,345.439,340.273,345.439z"/>
              </g></g>
              <g id="Layer_1"/>
            </svg> */}
          </Box>
          
        </Flex>
        <Box id="content">
          <Box id="message" sx={{pt:[3], height:[80, 100, 100, 100]}}>
            Generate convincing fake tweet images
          </Box>
          <Flex id="info" sx={{color: themeUI.theme.colors.muted, fontSize: [1], paddingTop: [1], paddingBottom: [2]}}>
            <div id="time">9:41 AM</div>
            &nbsp;·&nbsp;
            <div id="date">Apr 13, 2022</div>
          </Flex>
        </Box>
      </Box>
    </Box>

    
  )
}

export default RedditElement;

