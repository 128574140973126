import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Box, TextField } from '@mui/material';
import { useThemeUI  } from 'theme-ui';
import AlertComponent from '../components/AlertComponent';
import campaignsService from '../services/CampaignsService';

export default function AddActionModal({
  openModal, 
  handleClose,
  record
}) {
  const themeUI  = useThemeUI();
  const theme = createTheme({
    components:{
      MuiPaper:{
        styleOverrides: {
          root: {
            color: themeUI.theme.colors.text,
            background:themeUI.theme.colors.background
          },
        },
      },
      MuiTypography:{
        styleOverrides: {
          root:{
            color: themeUI.theme.colors.text + '!important',
          }
        }
      },
      MuiFormLabel:{
        styleOverrides: {
          root:{
            color: themeUI.theme.colors.text + '!important',
          }
        }
      },
      MuiSvgIcon:{
        styleOverrides: {
          root:{
            color: themeUI.theme.colors.text + '!important',
          }
        }
      },
      MuiOutlinedInput:{
        styleOverrides: {
          root: {
            ':hover .MuiOutlinedInput-notchedOutline': {
              borderColor: themeUI.theme.colors.muted,
            },
            color: themeUI.theme.colors.text + '!important',
          },
          notchedOutline:{
            borderColor: themeUI.theme.colors.text,
          },
          
        }
      },
      MuiInputBase:{
        styleOverrides: {
          root:{
            "&.Mui-disabled":{
              color: themeUI.theme.colors.muted,
            }
          },
        }
      }
    }
    
  });
  const { id } = useParams();
  var [actionName, setActionName] = useState('');
  var [actionNameAlert, setActionNameAlert] = useState(false);
  var [actionUrl, setActionUrl] = useState('');
  var [actionUrlAlert, setActionUrlAlert] = useState(false);
  var [actionHeaders, setActionHeaders] = useState({});
  var [addingHeader, setAddingHeader] = useState(false);
  var [newHeaderKey, setNewHeaderKey] = useState('');
  var [newHeaderValue, setNewHeaderValue] = useState('');
  var [newHeaderAlert, setNewHeaderAlert] = useState(false);
  var [actionAuth, setActionAuth] = useState('none');
  var [actionAuthUser, setActionAuthUser] = useState('');
  var [actionAuthAlert, setActionAuthAlert] = useState(false);
  var [actionAuthPass, setActionAuthPass] = useState('');

  useEffect(()=>{
    if(record){
      setActionName(record.name);
      setActionUrl(record.webhook.url);
      if(record.webhook.auth.type === 'basic'){
        setActionAuth('basic');
        setActionAuthUser(record.webhook.auth.username);
        setActionAuthPass(record.webhook.auth.password)
      }else{
        setActionAuth('none');
      }

      let headers = {} 
      if(record.webhook.headers && Array.isArray(record.webhook.headers)){
        record.webhook.headers.forEach(element => {
          headers[element.header] = element.value;
        });
      }
      setActionHeaders(headers);
      setActionNameAlert(false);
      setActionUrlAlert(false);
      setAddingHeader(false);
      setNewHeaderAlert(false);
      setActionAuthAlert(false);
      
    }else{
      setActionName('');
      setActionNameAlert(false);
      setActionUrl('');
      setActionUrlAlert(false);
      setActionHeaders({});
      setAddingHeader(false);
      setNewHeaderKey('');
      setNewHeaderValue('');
      setNewHeaderAlert(false);
      setActionAuth('none');
      setActionAuthUser('');
      setActionAuthAlert(false);
      setActionAuthPass('');
    }
  },[record])

  

  const handleChangeName = (event) => {
    setActionName(event.target.value);
    setActionNameAlert(false);
  };
  const handleChangeUrl = (event) => {
    setActionUrl(event.target.value);
    setActionUrlAlert(false);
  };

  const handleAddHeader = (event) => {
    setAddingHeader(true);
  }

  const handleSaveHeader = (e) => {
    if(newHeaderKey && newHeaderValue){
      let newHeader = {};
      newHeader[newHeaderKey] = newHeaderValue;
      setActionHeaders({...actionHeaders, ...newHeader});
      setNewHeaderKey('');
      setNewHeaderValue('');
    }
    else{
      setNewHeaderAlert(true);
    }
  }

  const handleDeleteHeader = (keyObject) => {
    let headersCopy = {...actionHeaders};
    delete headersCopy[keyObject];
    setActionHeaders(headersCopy);
  }

  const validateURl = url =>{
    let expression = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
    let regex = new RegExp(expression);
    return url.match(regex); 
  }

  const handleAddAction = () => {
    if(!actionName){
      setActionNameAlert(true);
    }

    if(!actionUrl){
      setActionUrlAlert(true);
    }

    let auth = {};
    if(actionAuth === 'none'){
      auth = {"type": "none"};
    }
    if(actionAuth === 'basic') {
      if(actionAuthUser && actionAuthPass)
        auth = {
          "type": "basic",
          "username": actionAuthUser,
          "password": actionAuthPass
        }
        else{
          setActionAuthAlert(true);
        }
    }

    if((newHeaderKey && newHeaderValue === '') || (newHeaderKey === '' && newHeaderValue)) setNewHeaderAlert(true);

    if(!validateURl(actionUrl)) setActionUrlAlert(true);

    if(actionName && validateURl(actionUrl) && (actionAuth === 'none' || (actionAuth === 'basic' && actionAuthUser && actionAuthPass)) && (((addingHeader && newHeaderKey && newHeaderValue)) || (addingHeader && newHeaderKey === '' && newHeaderValue === '') || !addingHeader)){
      let formatedHeaders = []
      for (const key in actionHeaders) {
        formatedHeaders.push({
          "header": key,
          "value": actionHeaders[key]
        })
      }
      if(newHeaderKey && newHeaderValue){
        formatedHeaders.push({
          "header": newHeaderKey,
          "value": newHeaderValue
        })
        setNewHeaderKey('');
        setNewHeaderValue('');
      }
      if(!record){
        campaignsService.postBotActions(actionName, id, actionUrl, formatedHeaders, auth)
        .then(res => {
          handleClose();
          setActionName('');
          setActionNameAlert(false);
          setActionUrl('');
          setActionUrlAlert(false);
          setActionHeaders({});
          setAddingHeader(false);
          setNewHeaderKey('');
          setNewHeaderValue('');
          setNewHeaderAlert(false);
          setActionAuth('none');
          setActionAuthUser('');
          setActionAuthAlert(false);
          setActionAuthPass('');
        })
      }else{
        campaignsService.updateBotActions(record.id, actionName, id, actionUrl, formatedHeaders, auth)
        .then(res => {
          handleClose();
        })
      }
      
    }
  }

  const handleDeleteAction = () => {
    campaignsService.deleteBotAction(record.id)
    .then(res => {
      handleClose();
    })
  }
  
  return (
    <>
      <ThemeProvider theme={theme}>
      <Dialog 
        open={openModal} 
        onClose={handleClose}
        fullWidth={true}
        maxWidth={'sm'}
        >
        <DialogTitle>Add Action</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{
              mb:[2]
            }}>
            Please enter the new action name:
          </DialogContentText >
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap'
          }}>
            <TextField value={actionName} onChange={handleChangeName} sx={{marginRight: '10px'}}/>
            {actionNameAlert && (
              <AlertComponent 
                type={'error'} 
                strongText={"Name can't be blank"} 
              />
            )}
          </Box>
          <DialogContentText sx={{
              mb:[2],
              mt:[2]
            }}>
            Please enter the action url:
          </DialogContentText >
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap'
          }}>
            <TextField value={actionUrl} onChange={handleChangeUrl} sx={{marginRight: '10px'}}/>
            {actionUrlAlert && (
              <AlertComponent 
                type={'error'} 
                strongText={"Enter a valid URL"} 
              />
            )}
          </Box>
          <DialogContentText sx={{
              mb:[2],
              mt:[2]
            }}>
              Type of authentication:
          </DialogContentText >
          <FormControl 
            sx={{
              display: 'block',
              mt:[2]
            }}>
            <InputLabel id="authAction">Auth</InputLabel>
            <Select
              sx={{width: 220}}
              labelId="authAction"
              value={actionAuth}
              label="Auth"
              onChange={(e) => setActionAuth(e.target.value)}
            >
              <MenuItem value={'none'}>None</MenuItem>
              <MenuItem value={'basic'}>Basic</MenuItem>
            </Select>
          </FormControl>
          {
            actionAuth === 'basic' && (
              <Box sx={{
                display: 'flex',
                mt:[2],
                alignItems: 'center',
                flexWrap: 'wrap'
                }}>
                  <Box>
                    <DialogContentText>
                      Username:
                    </DialogContentText >
                    <TextField value={actionAuthUser} onChange={(e)=>setActionAuthUser(e.target.value)} sx={{marginRight: '10px'}}/>
                  </Box>
                  <Box>
                    <DialogContentText>
                      Password:
                    </DialogContentText >
                    <TextField value={actionAuthPass} onChange={(e)=>setActionAuthPass(e.target.value)} type="password" sx={{marginRight: '10px'}}/>
                  </Box>
              </Box>
            )
          }
          {
            actionAuthAlert && (
              <AlertComponent 
                type={'error'} 
                strongText={"Username or password can't be blank"} 
              />
            )
          }
          <DialogContentText sx={{
              mb:[2],
              mt:[2]
            }}>
            Please enter the headers:
          </DialogContentText >
          {
            actionHeaders && Object.keys(actionHeaders).map(keyObject => {
              return (
                <Box 
                  key={keyObject}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    mb:[2],
                    mt:[2]
                  }}
                >
                  <TextField 
                    value={keyObject} 
                    disabled
                    sx={{
                      ".css-17cvodc-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled":{
                        "WebkitTextFillColor": themeUI.colorMode === 'light' ? '': themeUI.theme.colors.grey
                      },
                      marginRight: '10px',
                      "& .MuiInputBase-root.Mui-disabled": {
                          "& > fieldset": {
                              borderColor: themeUI.colorMode === 'light' ? '' : themeUI.theme.colors.grey
                          }
                      }
                    }}  
                  />
                  <TextField 
                    value={actionHeaders[keyObject]} 
                    disabled 
                    sx={{
                      ".css-17cvodc-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled":{
                        "WebkitTextFillColor": themeUI.colorMode === 'light' ? '': themeUI.theme.colors.grey
                      },
                      marginRight: '10px',
                      "& .MuiInputBase-root.Mui-disabled": {
                          "& > fieldset": {
                              borderColor: themeUI.colorMode === 'light' ? '' : themeUI.theme.colors.grey
                          }
                      }
                    }}  
                  />
                  <DeleteIcon sx={{color: themeUI.theme.colors.red + '!important'}} onClick={(e) => handleDeleteHeader(keyObject)}></DeleteIcon>
                </Box>
              )
            })
          }
          {
            !addingHeader && (Object.keys(actionHeaders).length === 0 || record) && (
              <Button onClick={handleAddHeader}>
                Add Headers
              </Button>
            )
          } 
          {
            addingHeader && (
              <Box sx={{
                display: 'flex',
                alignItems: 'center',
                mb:[2],
                mt:[2]
              }}>
                <Box>
                  <DialogContentText>Header:</DialogContentText>
                  <TextField value={newHeaderKey} onChange={(e)=>setNewHeaderKey(e.target.value)} sx={{marginRight: '10px'}}/>
                </Box>
                <Box>
                  <DialogContentText>Value:</DialogContentText>
                  <TextField value={newHeaderValue} onChange={(e)=>setNewHeaderValue(e.target.value)} sx={{marginRight: '10px'}}/>
                </Box>
                <CheckBoxIcon sx={{ color: themeUI.theme.colors.green + '!important' }}onClick={handleSaveHeader}></CheckBoxIcon>
              </Box>
            )
          }
          {
            newHeaderAlert && (
              <AlertComponent 
                type={'error'} 
                strongText={"Header or it's value can't be blank"} 
              />
            )
          }
        </DialogContent>
        <DialogActions>
          {record && (<Button sx={{color: themeUI.theme.colors.red}} onClick={handleDeleteAction}>Delete</Button>)}
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleAddAction}>Ok</Button>
        </DialogActions>
      </Dialog>
      </ThemeProvider >
    </>
  );
}