import { useState } from 'react'
import { Guide } from '@carbonplan/components';
import Layout from '../components/layout';
import AccountsCompaigns from './AccountsCompaigns';
import { motion } from 'framer-motion';

function Index() {
  const [settingsExpanded, setSettingsExpanded] = useState(false)

  return (
    <motion.div
    initial={{ scaleY: 0 }}
    animate={{ scaleY: 1 }}
    exit={{ scaleY: 0 }}
    transition={{ duration: 0.5 }}
    >
      <Layout
        footer={false}
        metadata={false}
        dimmer={'top'}
        settings={{
          value: settingsExpanded,
          onClick: () => setSettingsExpanded(!settingsExpanded),
        }}
        title={'cdr database / research / carbonplan'}
        description={'Public database of reports on carbon removal projects.'}
        card={'https://images.carbonplan.org/social/cdr-database.png'}
        nav={'research'}
      >
        <Guide />
        <AccountsCompaigns
          settingsExpanded={settingsExpanded}
        />

      </Layout>
    </motion.div>
  )
}

export default Index
