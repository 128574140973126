import { memo } from 'react';
import { Box, Flex } from 'theme-ui';
import { Column, Row } from '@carbonplan/components';
import NumberFormat from "react-number-format";
//import Metric from './metric'
//import Share from './share'


const Report = ({ data, setHighlighted, tooltips }) => {

  let {
    id,
    name,
    status,
    cost,
    impressions,
    clicks
  } = data

  /*metrics = showMetrics.map((metric) => {
    return metrics.filter((m) => m.name == metric)[0]
  })

  metrics = [
    {
      name: 'rating',
      value: rating,
      units: '',
      notes: '',
      comment: '',
    },
  ].concat(metrics)*/

  const ml = [0, 0, '24px', '36px']

  return (
    <Box
      onClick={(e) => {
        //if (!embed) setExpanded(!expanded)
      }}
      onMouseEnter={() => {
        if (setHighlighted) setHighlighted(id)
      }}
      onMouseLeave={() => {
        if (setHighlighted) setHighlighted(null)
      }}
      sx={{
        borderStyle: 'solid',
        borderColor: 'muted',
        borderWidth: '0px',
        borderBottomWidth: ['1px', '1px', '0px'],
        borderLeftWidth: ['0px', '0px', '1px'],
        borderRadius: '0px',
        cursor: 'pointer',
        pl: [0, 0, 0],
        pr: [0],
        pt: ['0px', '0px', '12px'],
        my: [3],
        mb: [3, 3, '24px'],
        transition: 'border-color 0.15s',
        '&:hover': {
          borderColor: ['muted', 'muted', 'secondary'],
        },
        '@media (hover: hover) and (pointer: fine)': {
          '&:hover > #container > #box > #flex > #text > #expander': {
            stroke: 'primary',
          },
        },
        '@media (hover: none) and (pointer: coarse)': {
          '#container > #box > #flex > #text > #expander': {
            stroke: 'secondary',
          },
        },
      }}
    >
      <Flex
        id='container'
        sx={{
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          mt: ['-6px'],
          ml: ml,
        }}
      >
        <Box id='box' sx={{ mt: ['6px'] }}>
          <Flex
            id='flex'
            sx={{
              width: ['fit-content'],
              flexWrap: 'wrap',
              ml: [-2],
            }}
          >
            <Box
              id='text'
              sx={{
                display: 'inline-block',
                fontSize: [4, 4, 4, 5],
                lineHeight: 'heading',
                ml: [2],
              }}
            >
              { name }
            </Box>
          </Flex>
        </Box>
      </Flex>

      <Row columns={[3, 6, 3, 3]} 
        sx={{
          fontSize: [1, 1, 1, 2],
          ml: ml,
          pt: ['2px'],
          pb: [1],
        }}>
        <Column start={[1, 1, 1, 1]} width={[3, 2, 1, 1]}>
          <Box
            sx={{
              fontSize: [1, 1, 1, 2],
              pt: ['2px'],
              pb: [1],
              color: 'secondary',
              whiteSpace: 'nowrap'
            }}
          >
            {'Status:'}
            <Box sx={{
              display: 'inline-block',
              fontFamily: 'heading',
              color: 'primary',
              ml: [3]
            }}>
              {status}
            </Box>
          </Box>
          <Box
            sx={{
              fontSize: [1, 1, 1, 2],
              pt: ['2px'],
              pb: [1],
              color: 'secondary',
              whiteSpace: 'nowrap'
            }}
          >
            {'Impressions:'}
            <Box sx={{
              display: 'inline-block',
              fontFamily: 'heading',
              textTransform: 'uppercase',
              color: 'primary',
              ml: [3]
            }}>
              { impressions }
            </Box>
          </Box> 
        </Column>
        <Column start={[1, 3, 2, 2]} width={[3, 2, 1, 1]}>
        <Box
            sx={{
              fontSize: [1, 1, 1, 2],
              pt: ['2px'],
              pb: [1],
              color: 'secondary',
              whiteSpace: 'nowrap'
            }}
          >
            {'Cost:'}
            <Box sx={{
              display: 'inline-block',
              fontFamily: 'heading',
              color: 'primary',
              ml: [3]
            }}>
              <NumberFormat
                thousandsGroupStyle="thousand"
                value={ cost }
                prefix="$"
                decimalSeparator="."
                displayType="text"
                type="text"
                thousandSeparator={true}
              />
            </Box>
          </Box>
        </Column>
        <Column start={[1, 5, 3, 3]} width={[3, 2, 1, 1]}>
          <Box
            sx={{
              fontSize: [1, 1, 1, 2],
              pt: ['2px'],
              pb: [1],
              color: 'secondary',
              whiteSpace: 'nowrap'
            }}
          >
            {'Clicks:'}
            <Box sx={{
              display: 'inline-block',
              fontFamily: 'heading',
              color: 'primary',
              ml: [3]
            }}>
              {clicks}
            </Box>
          </Box>
        </Column>
      </Row>
      <Box
        
      >

      </Box> 


    </Box>
  )
}

export default memo(Report)
