import Cookies from 'universal-cookie';
import handleResponse from '../helpers/HandleResponse';
const cookies = new Cookies();


let etfsService = {
  getETFs,
  getChartData,
  getNews
};

function getETFs () {
  return fetch(`${process.env.REACT_APP_API_URL}cat_etfs`,{
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + cookies.get('jwt')
    },
    method: 'GET'
  })
  .then(response => {
    return handleResponse(response)
  })
  .catch(error => console.warn(error));
}


function getChartData (symbol, params) {
  return fetch(`${process.env.REACT_APP_API_URL}cat_etf/${symbol}/getTradingVolume?${new URLSearchParams(params)}`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + cookies.get('jwt')
    }
  })
  .then(response => {
    return handleResponse(response)
  })
  .catch(error => console.warn(error));
}



function getNews (symbol, params) {
  return fetch(`${process.env.REACT_APP_API_URL}cat_etf/${symbol}/getNews?${new URLSearchParams(params)}`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + cookies.get('jwt')
    }
  })
  .then(response => {
    return handleResponse(response)
  })
  .then(res=>{
    let data = res[0].records.slice(0,9).map(r=> r.values);
    data.forEach(r=>{
      r.id = r._time;
      let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
      var today  = new Date(r._time);
      r.date = today.toLocaleDateString("en-US", options)
    })
    return data
  })
  .catch(error => console.warn(error));
}


export default etfsService;