import { useParams } from "react-router-dom";
import { Box, Divider, Flex } from 'theme-ui';
import { Tray, Toggle } from '@carbonplan/components';
import Search from './search';

export const sx = {
  label: {
    fontFamily: 'mono',
    letterSpacing: 'mono',
    fontSize: [1, 1, 1, 2],
    textTransform: 'uppercase',
    color: 'secondary',
    mt: ['3px'],
    userSelect: 'none',
  },
}

const Sidebar = ({
  highlighted,
  filtered,
  data,
  slugData,
  filters,
  setFilters,
  bounds,
  setBounds,
  tooltips,
  settingsExpanded,
  orderPercentDescending,
  setOrderPercentDescending,
  orderPercentAscending,
  setOrderPercentAscending,
  dataTags
}) => {
  function setSearch(value) {
    setFilters((filters) => {
      return { ...filters, search: value }
    })
  }
  const { slug } = useParams();

  return (
    <>
      <Box
        sx={{
          position: 'sticky',
          display: ['none', 'none', 'initial'],
          top: 56,
          height: ['fit-content', 'fit-content', 'calc(100vh - 56px)'],
          overflowY: 'scroll',
          '::-webkit-scrollbar': {
            width: 0,
            background: 'transparent',
          },
          scrollbarWidth: 'none',
          px: ['20px'],
          mx: ['-20px'],
        }}
      >
        <Box
          sx={{
            pt: [3],
            pb: [3],
            fontSize: [6, 6, 6, 7],
            width: 'fit-content',
            fontFamily: 'AT Surt Medium',
            lineHeight: 'heading',
          }}
        >
          {slug.toUpperCase()}
        </Box>
        <Box
          sx={{
            pt: [0],
            pb: [3, 3, 4, 4],
            mb: [0, 3, 0, 1],
            fontSize: [2, 2, 2, 3],
            fontFamily: 'body',
            lineHeight: 'body',
          }}
        >
          <Flex sx={{
            justifyContent: 'space-between',
            alignItems: 'center',
            mt: [2]
          }}>
            <Box sx={{...sx.label, mr:[3]}}>ACTIVE</Box>
            <Toggle value={true} onClick={()=>console.log('test')} sx={{color: 'green'}}></Toggle>
          </Flex>
        </Box>
        <Box>
          <Divider sx={{ mt: [0], mb: [3] }} />
          <Search setSearch={setSearch} tooltips={tooltips} />
          
        </Box>
        <Box>
          <Divider sx={{ mt: [0], mb: [3] }} />
          {/* <HighlightsComponent twitts={twitts} position={'holdingsSidebar'}></HighlightsComponent> */}
        </Box>
      </Box>
      <Tray expanded={settingsExpanded}>
        <Box sx={{ mb: [1], mt: [0, 0, 3, 3] }}>
          <Search setSearch={setSearch} tooltips={tooltips} />
        </Box>
        {/* <Box>
            <OrderByPercent 
              sx={sx} 
              upSortHover={upSortHover}
              setSortUpHover={setSortUpHover}
              downSortHover={downSortHover}
              setSortDownHover={setSortDownHover}
              orderPercentAscending={orderPercentAscending}
              setOrderPercentAscending={setOrderPercentAscending}
              orderPercentDescending={orderPercentDescending}
              setOrderPercentDescending={setOrderPercentDescending}
            />
          </Box> */}
          {/* <Box>
            <TagsFilter
              filters={filters}
              setFilters={setFilters}
              tooltips={tooltips}
              settingsExpanded={settingsExpanded}
              dataTags={dataTags}
            />
          </Box> */}
      </Tray>
    </>
  )
}

export default Sidebar