import { memo, useMemo, useCallback } from 'react'
import { Box } from 'theme-ui'
import { Row, Column, Filter } from '@carbonplan/components'
import { sx } from './sidebar'
import Theme from '../theme'


const categories = [
  'reddit',
  'twitter',
  'total',
]

const filterGroups = {
  categories
}

const ComponentFilterGroups = (filters) => {
  return useMemo(() => {
    const result = {}
    for (const group in filterGroups) {
      result[group] = filterGroups[group].reduce((obj, key) => {
        obj[key] = filters[key]
        return obj
      }, {})
    }
    return result
  }, [filters])
}

const TagsFilter = ({
  filters: combinedFilters,
  setFilters,
  tooltips,
  settingsExpanded,
  dataTags
}) => {
  const filters = ComponentFilterGroups(combinedFilters)

  const toggleOption = useCallback((updatedFilters) => {
    setFilters((filters) => {
      return { ...filters, ...updatedFilters }
    })
  },[setFilters])

  return (
    <Row columns={[6, 8, 4, 4]} sx={{ mt: [1, 1, 1, 2] }}>
      <Column start={[1, 1, 1, 1]} width={[2, 1, 1, 1]}>
        <Box sx={sx.label}>Tags</Box>
      </Column>
      <Column start={[3, 3, 2, 2]} width={[4, 4, 3, 3]}>
        <Box sx={{ display: 'inline-block', float: 'right' }}>
          {/* <TooltipToggle
            tooltips={tooltips}
            value={value}
            setValue={setValue}
          /> */}
        </Box>
        <Filter
          values={filters.categories}
          setValues={toggleOption}
          colors={Theme.tags}
          multiSelect
          showAll
        />
        <Box sx={{ mt: [1] }}>
          {/* <TooltipDescription
            label={label}
            value={value}
            tooltips={tooltips}
          /> */}
        </Box>
      </Column>
    </Row>
  )
}

export default memo(TagsFilter)
