import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { Row, Column } from '@carbonplan/components'
import Sidebar from './sidebar';
import CampaignContent from './campaingContent';
import campaignsService from '../services/CampaignsService';
import eventsService from '../services/EventsService';
import TopTitle from './topTitle';

const initFilters = {
  search: '',
  reddit: true,
  twitter: true,
  news: true,
  earnings: true,
}

function CampaignDetail ({settingsExpanded}) {
  
  const [campaign, setCampaign] = useState({campaign_triggers:[]});
  const [actions, setActions] = useState([])
  const { id } = useParams();
  const [openModal, setOpenModal] = useState(false);
  const [openActionsModal, setOpenActionsModal] = useState(false);
  const [openActiveModal, setOpenActiveModal] = useState(false);
  const [filters, setFilters] = useState(initFilters)
  const [filtered, setFiltered] = useState({ count: 0, init: false })
  const [activeCampaign, setActiveCampaign] = useState(false);
  const [actionRecord, setActionRecord] = useState(undefined);
  const [events, setEvents] = useState([]);
  

  useEffect(() => { 
    eventsService.getTriggerLogs()
    .then(response => {
      setEvents(response)
    })
  },[])


  useEffect(() => { 
    if(!openModal){
      campaignsService.getCampaign(id)
      .then(response=>{
        response[0].campaign_triggers.forEach(r=>{
          if(r.source_model !== 'CatCrypto') {
            r.tags = ['volume', 'price', 'social'];
          } else {
            r.tags = ['volume', 'price', 'social'];
          }
        });
        setCampaign(response[0]);
        setActiveCampaign( response[0].active === 1 ? true : false );
      })
    }
  },[id, openModal])

  useEffect(() => { 
    if(!openActionsModal){
      campaignsService.listBotActions(id)
      .then(response=>{
        setActions(response)
      })
    }
  },[id, openActionsModal])

  var handleCloseActiveModal = (active) => {
    campaignsService.updateCampaign(campaign.id, campaign.name, campaign.budget, active)
    .then(response=>{
      let tags = [
        'social',
        'reddit',
        'twitter',
        'price',
      ]
      response.campaign_triggers=[]
      response.campaign_triggers.map(r=>{r.tags = tags; return r});
      //setCampaign(response);
      
      setCampaign(prevState => (
        response.campaign_triggers = prevState.campaign_triggers, //THis data should come from backend
        { ...prevState, ...response}
      ));
      setOpenActiveModal(false);
    })

  }
    


  useEffect(() => {
    let obj = {}
    let count = 0
    campaign.campaign_triggers.forEach((h) => {
      obj[h.id] = inFilter(h)
      if (obj[h.id]) {
        count += 1
      }
    })
    obj.count = count
    obj.init = true
    setFiltered(obj)
  }, [filters, campaign.campaign_triggers])

  function inFilter (h) {
    // let inTags = false
    // if (h.tags && h.tags.length>0) {
    //   for (const property in filters) {
    //     if(filters[property] && h.tags.includes(property)) inTags = true
    //   }
    // }

    // const inTags =
    //   (filters.reddit && h.tags.length > 0 && h.tags.includes('reddit')) ||
    //   (filters.twitter && h.tags.length > 0 && h.tags.includes('twitter')) ||
    //   (filters.news && h.tags.length > 0 && h.tags.includes('news')) ||
    //   (filters.earnings && h.tags.length > 0 && h.tags.includes('earnings'))

    const searchTerm = filters.search.toLowerCase()
    const inSearch =
      searchTerm.length > 0 &&
      (h.name.toLowerCase().includes(searchTerm) ||
        (h.symbol && h.symbol.toLowerCase().includes(searchTerm)))

    if (filters.search.length > 0 && inSearch /*&& inTags*/) return true
    if (filters.search.length === 0 /*&& inTags*/) return true
    else return false
  }


  return (
    <>
    <TopTitle 
      data={campaign} 
      activeCampaign={activeCampaign}
      setOpenActiveModal={setOpenActiveModal}
      openActiveModal={openActiveModal}
      setActiveCampaign={setActiveCampaign}
      handleCloseActiveModal = {handleCloseActiveModal}
    ></TopTitle>  
    <Row columns={[3, 6, 9, 9]}>
      <Column start={[1]} width={[3, 6, 3, 3]}>
        <Sidebar
          filtered={filtered}
          data={campaign}
          setData={setCampaign}
          filters={filters}
          settingsExpanded={settingsExpanded}
          activeCampaign={activeCampaign}
          setActiveCampaign={setActiveCampaign}
          events={events}
        />
      </Column>
      <Column start={[1, 1, 4, 4]} width={[3, 6, 6, 6]}>
        <CampaignContent 
          data={campaign ? campaign.campaign_triggers : []} 
          filtered={filtered} 
          actions={actions}
          openSignalsModal={openModal}
          setOpenSignalsModal={setOpenModal}
          openActionsModal={openActionsModal}
          setOpenActionsModal={setOpenActionsModal}
          actionRecord={actionRecord}
          setActionRecord={setActionRecord}
          setFilters={setFilters}
        />
      </Column>
    </Row>
    </>
    
    
  )
}

export default CampaignDetail;