import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Box, Flex, Divider, Slider } from 'theme-ui';
import { Tray, Badge, Button } from '@carbonplan/components'
import DatePickerMaterial from '../components/DatePickerComponent';
//import TrendingHoldings from "./trendingHoldings";
import ChangeStockModal from "../components/ChangeStockModal";
import { Reset } from '@carbonplan/icons'

const sx = {
  button: { 
    borderWidth:[0],
    borderBottomWidth: [1],
    borderStyle: ['solid'],
    borderColor: ['text'],
    paddingBottom: [2],
    '&:hover': {
      borderColor: ['muted'],
      borderBottomWidth: [1],
    },
    textOverflow: 'ellipsis',
    overflow: 'hidden', 
    whiteSpace: 'nowrap',
    textTransform: 'uppercase',
    fontSize: [1, 1, 1, 2],
    fontFamily: 'mono',
    letterSpacing: 'mono',
    transition:'opacity 0.05s'
  },
  label: {
    fontFamily: 'mono',
    letterSpacing: 'mono',
    fontSize: [1, 1, 1, 2],
    textTransform: 'uppercase',
    color: 'secondary',
    mt: ['3px'],
    userSelect: 'none',
  }
}

const Sidebar = ({
  slug,
  settingsExpanded,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  confidenceInterval,
  setConfidenceInterval,
  movingAverageDays,
  setMovingAverageDays,
  view,
  openModal, 
  setOpenModal
}) => {
  const history = useHistory(); 
  const [resetHover, setResetHover] = useState(false);
  

  const handleClickSelectChart = (view) => {
    history.replace(`/chartbuilder?slug=${slug}&metric=${view}&start=${startDate}&end=${endDate}`);
  }

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleClickChange = () => {
    setOpenModal(true);
  };

  

  return (
    <>
      <Box
        sx={{
          position: ['initial', 'initial', 'sticky'],
          top: 56,
          height: ['fit-content', 'fit-content', 'calc(100vh - 56px)'],
          overflowY: 'scroll',
          '::-webkit-scrollbar': {
            width: 0,
            background: 'transparent',
          },
          scrollbarWidth: 'none',
          px: ['20px'],
          mx: ['-20px'],
          mt: ['1em']
        }}
      >
        <Flex
          sx={{
            alignItems: 'center'
          }}>
          <Box
            sx={{
              pt: [3],
              pb: [3],
              fontSize: [6, 6, 6, 7],
              width: 'fit-content',
              fontFamily: 'AT Surt Medium',
              lineHeight: 'heading',
            }}
          >
            {slug}
          </Box>
          <Reset
            onMouseOver={() => setResetHover(true)}
            onMouseLeave={() => setResetHover(false)}
            onClick={handleClickChange}
            sx={{
              cursor: 'pointer',
              width: '16px',
              ml: [5],
              strokeWidth: 1.5,
              opacity:
                resetHover ? 1 : 0.3,
              transition: '0.15s',
              '&: hover': {
                stroke: 'primary',
                strokeWidth: '2px',
              },
            }}
          ></Reset>

          <ChangeStockModal
            openModal={openModal} 
            setOpenModal={setOpenModal} 
            handleClose={handleClose}
          ></ChangeStockModal>
        </Flex>
        
        <Box sx={{ 
          display: ['none', 'none', 'initial']}}>
          <DatePickerMaterial 
            startDate={startDate} 
            setStartDate={setStartDate} 
            endDate={endDate}
            setEndDate={setEndDate}
          ></DatePickerMaterial>
          
          <Divider sx={{ my: [0], mt: ['11px', '11px', '11px', '14px'] }} />
          <Box sx={{ pt: [2], ...sx.label}}>
            Select chart
              <Box sx={{ mt:[2], display: 'flex'}}>
              <Button
                onClick={()=> handleClickSelectChart('volume')}
                sx={{...sx.button, color: 'yellow', borderColor: 'yellow', opacity: view === 'volume' ? '': '0.33' }}
              >
                Volume
              </Button>
              <Button
                onClick={()=> handleClickSelectChart('price')}
                sx={{...sx.button, color: 'green', borderColor: 'green', ml:[2], opacity: view === 'price' ? '': '0.33'}}
              >
                Price
              </Button>
              <Button
                onClick={()=> handleClickSelectChart('social')}
                sx={{...sx.button, color: 'blue', borderColor: 'blue', ml:[2], opacity: view === 'social' ? '': '0.33'}}
              >
                Social
              </Button>
            </Box>
          </Box>
          <Divider sx={{ my: [0], mt: ['11px', '11px', '11px', '14px'] }} />
          <Box sx={{ pt: [2], ...sx.label }}>
            Confidence Interval
            <Flex>
              <Slider
                value={confidenceInterval}
                min={0}
                max={5}
                step={0.1}
                onDragEnd={(e) => console.log(e)}
                onChange={(e) => setConfidenceInterval(parseFloat(e.target.value))}
                sx={{ mr: 2 }}
              />
              <Badge sx={{ width: [60] }}> {confidenceInterval} </Badge>
            </Flex>
          </Box>
          <Divider sx={{ my: [0], mt: ['11px', '11px', '11px', '14px'] }} />
          <Box sx={{ pt: [2], ...sx.label, pb:[2] }}>
            Average Window
            <Flex>
              <Slider
                value={movingAverageDays}
                min={0}
                max={30}
                step={1}
                onChange={(e) => setMovingAverageDays(parseFloat(e.target.value))}
                sx={{ mr: 2 }}
              />
              <Badge sx={{ width: [60] }} > {String(movingAverageDays).padStart(3, '0')} </Badge>
            </Flex>
          </Box>
          {/* <TrendingHoldings columns={[0, 0, 3, 3]}></TrendingHoldings> */}
        </Box>
      </Box>
      <Tray expanded={settingsExpanded}>
        <DatePickerMaterial
          startDate={startDate} 
          setStartDate={setStartDate} 
          endDate={endDate}
          setEndDate={setEndDate}
        />
        <Divider sx={{ my: [0], mt: [4, 5] }} />
        <Box sx={{ pt: [2], ...sx.label}}>
            Select chart
            
              <Box sx={{ mt:[2], display: 'flex'}}>
                <Button
                  onClick={()=> handleClickSelectChart('volume')}
                  sx={{...sx.button, color: 'yellow', borderColor: 'yellow', opacity: view === 'volume' ? '': '0.33' }}
                >
                  Volume
                </Button>
                <Button
                  onClick={()=> handleClickSelectChart('price')}
                  sx={{...sx.button, color: 'green', borderColor: 'green', ml:[2], opacity: view === 'price' ? '': '0.33'}}
                >
                  Price
                </Button>
                
                <Button
                  onClick={()=> handleClickSelectChart('social')}
                  sx={{...sx.button, color: 'blue', borderColor: 'blue', ml:[2], opacity: view === 'social' ? '': '0.33'}}
                >
                  Social
                </Button>
              </Box>
          </Box>
        <Divider sx={{ my: [0], mt: [4, 5] }} />
        <Box sx={{ mb: [1], mt: [3], ...sx.label }}> Confidence Interval </Box>
        <Flex sx={{ mb: [2] }}>
          <Slider
            value={confidenceInterval}
            min={0}
            max={5}
            step={0.1}
            onDragEnd={(e) => console.log(e)}
            onChange={(e) => setConfidenceInterval(parseFloat(e.target.value))}
            sx={{ mr: 2 }}
          />
          <Badge sx={{ width: [60] }}> {confidenceInterval} </Badge>
        </Flex>
        <Divider sx={{ my: [0], mt: ['11px', '11px', '11px', '14px'] }} />
        <Box sx={{ mb: [1], mt: [3], ...sx.label }}> Average Window </Box>
        <Flex >
          <Slider
            value={movingAverageDays}
            min={0}
            max={30}
            step={1}
            onChange={(e) => setMovingAverageDays(parseFloat(e.target.value))}
            sx={{ mr: 2 }}
          />
          <Badge sx={{ width: [60] }} > {String(movingAverageDays).padStart(3, '0')} </Badge>
        </Flex>
      </Tray>
    </>
  )
}

export default Sidebar