import { memo } from 'react';
import { useThemeUI, Box, Flex } from 'theme-ui';
import { Tag } from '@carbonplan/components'
import { useHistory  } from "react-router-dom";

const Report = ({ data, setHighlighted, tooltips, embed, idx }) => {
  const { theme } = useThemeUI();
  const history = useHistory(); 

  var sx = {
    label: {
      fontFamily: 'mono',
      letterSpacing: 'mono',
      fontSize: [1, 1, 1, 2],
      textTransform: 'uppercase',
      color: 'secondary',
      mt: ['3px'],
      userSelect: 'none',
    }
  }

  let {
    id,
    name,
    source_model,
    source_id,
    tags,
  } = data

  const ml = [0, 0, '24px', '36px']
  
  return (
    <Box
      onMouseEnter={() => {
        if (setHighlighted) setHighlighted(id)
      }}
      onMouseLeave={() => {
        if (setHighlighted) setHighlighted(null)
      }}
      sx={{
        borderStyle: 'solid',
        borderColor: 'muted',
        borderWidth: '0px',
        borderBottomWidth: embed
          ? ['0px', '0px', '0px']
          : ['1px', '1px', '0px'],
        borderLeftWidth: embed ? [0, 0, 0] : ['0px', '0px', '1px'],
        borderRadius: '0px',
        pl: embed ? [0, 0, 0] : [0, 0, 0],
        pr: [0],
        pt: embed ? [0, 0, 0] : ['0px', '0px', '12px'],
        my: [embed ? 0 : 3],
        mb: embed ? [0, 0, 0] : [3, 3, '24px'],
        transition: 'border-color 0.15s',
        '&:hover': {
          borderColor: ['muted', 'muted', 'secondary'],
        },
        '@media (hover: hover) and (pointer: fine)': {
          '&:hover > #container > #box > #flex > #text > #expander': {
            stroke: 'primary',
          },
        },
        '@media (hover: none) and (pointer: coarse)': {
          '#container > #box > #flex > #text > #expander': {
            stroke: 'secondary',
          },
        },
      }}
    >
      <Box
        id='container'
        sx={{
          justifyContent: 'space-between',
          mt: ['-6px'],
          ml: ml,
        }}
      >
        <Box
          sx={{
            fontSize: [4, 4, 4, 5],
            lineHeight: 'heading',
            cursor: 'pointer',
            mb:[2]
          }}
          onClick={(e) => {
            let path; 
            //if(source_model === "CatEtf") path = `/etf/${source_id}?metric=volume`
            //if(source_model === "CatCrypto") path = `/crypto/${source_id}?metric=volume`; 
            if(source_model === "CatEtf") path = `/chart/etf/${source_id}?metric=volume`
            if(source_model === "CatCrypto") path = `/chart/crypto/${source_id}?metric=social`; 
            if(source_model === "CatStock") path = `/chart/stock/${source_id}?metric=volume`; 
            history.push(path);
          }}
        >
          { name }
        </Box>
        <Flex sx={{
          justifyContent: 'space-between',
          alignItems: 'flex-end'
        }}>
          <Box>
            <Box sx={{...sx.label, mb:[1]}}>Last Trigger</Box>
            <Box>07/21/2022</Box>
          </Box>
          <Box>
            {tags.map((tag, i) => (
              <Tag
                key={id + '-tag-' + i}
                label={tag}
                value={true}
                sx={{
                  mr: i === (tags.length - 1) ? [0] : [2],
                  color: theme.tags[tag],
                  cursor: 'pointer'
                }}
                onClick={()=>{
                  let path; 
                  if(source_model === "CatEtf") path = `/etf/${source_id}?metric=${tag}`
                  if(source_model === "CatCrypto") path = `/crypto/${source_id}?metric=${tag}`; 
                  history.push(path);
                }}
              >
                {tag}
              </Tag>
            ))}
          </Box>
        </Flex>
      </Box>
    </Box>            
    
  )
}

export default memo(Report)
