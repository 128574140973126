import * as React from 'react';
import { Box } from 'theme-ui';
import BarChart from './SmallBarChart';
import ArrowIndicator from './ArrowIndicator';


export default function TriDataComponent({label, chartColor}) {

  const fakeSeries = [{
    name: 'Population',
    data: [
        ['Shanghai', 24.2],
        ['Beijing', 20.8],
        ['Karachi', 14.9],
        ['Shenzhen', 13.7],
        ['Guangzhou', 13.1],
        ['Istanbul', 12.7],
        ['Mumbai', 12.4],
        ['Moscow', 12.2],
        ['São Paulo', 12.0],
        ['Delhi', 11.7],
        ['Kinshasa', 11.5],
        ['Tianjin', 11.2],
        ['Lahore', 11.1],
        ['Jakarta', 10.6],
        ['Dongguan', 10.6],
        ['Lagos', 10.6],
        ['Bengaluru', 10.3],
        ['Seoul', 9.8],
        ['Foshan', 9.3],
        ['Tokyo', 9.3]
    ],
    
  }]
  
  return (
    <Box sx={{
      display: 'flex',
      fontSize: [3, 3, 3, 4],
      lineHeight: 'heading',
      fontFamily: 'faux',
      letterSpacing: 'mono',
      color: 'primary',
      mt: ['2px'],
      userSelect: 'none',
      justifyContent: 'space-evenly',
      alignItems: 'center'
    }}>
      <Box sx={{
        whiteSpace: 'nowrap'
      }}>
        {label}
        <ArrowIndicator value={label}></ArrowIndicator>
      </Box>
      <Box sx={{
      }}>
        <BarChart data={fakeSeries} colors={chartColor}/>
      </Box>
      <Box sx={{
        ml: [1],
      }}>
        {/* 15% */}
      </Box>

    </Box>
  );
}