
import { Box, Flex } from 'theme-ui';
import { Row, Column } from '@carbonplan/components';
import NewsTableRow from './NewsTableRow';
import Spinner from './Spinner';

const sx = {
  reset: {
    display: 'block',
    width: 'auto',
    height: 'auto',
    margin: 0,
    padding: 0,
    border: 'none',
    borderCollapse: 'inherit',
    borderSpacing: 0,
    borderColor: 'inherit',
    verticalAlign: 'inherit',
    textAlign: 'left',
    fontWeight: 'normal',
  },
  header: {
    fontSize: [1, 2, 2, 2],
    mb: [3, 0, 0, 0],
    fontWeight: 'normal'    
  },
  highlight: {
    mb: [3, 3, 3, 4],
    fontSize: [3, 3, 3, 4],
    fontFamily: 'heading',
    letterSpacing: 'smallcaps',
    textTransform: 'uppercase',
    color: 'secondary',
  },
}

const fields = [
  'Ranking',
  'Source',
  'Date',
  'Title',
  'Readers'
]

const columns = {
  width: [
    [0, 1, 1, 1],
    [1, 1, 1, 1],
    [1, 1, 1, 1],
    [1, 2, 2, 2],
    [1, 1, 1, 1]
  ],
  start: [
    [0, 1, 1, 1],
    [1, 2, 2, 2],
    [1, 3, 3, 3],
    [2, 4, 4, 4],
    [3, 6, 6, 6],
  ],
}

const NewsTable = ({news, startDayNewsSelected, endDayNewsSelected, loadingSpinnerNewsArticles}) => {
  return (
    <Box>
      <Column start={[1]} sx={{ mt: [3] }} width={[3, 6, 6, 6]}>
        <Flex sx={{justifyContent: 'space-between', flexWrap: 'wrap'}}>
          <Box sx={sx.highlight}>Press highlights</Box>  

          <Box sx={{...sx.highlight, color: 'primary', fontSize: [18]}}>
            {startDayNewsSelected  && endDayNewsSelected ?
                `${new Date(startDayNewsSelected).toLocaleDateString("en-US", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })} - 
                ${new Date(endDayNewsSelected).toLocaleDateString("en-US", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}`
              : 
                ''
            }
          </Box>  
        </Flex>
      </Column>
      { loadingSpinnerNewsArticles ? 
        <Box sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          mt:[2, 5, 5, 5]
        }}>
          <Spinner label={true} labelTitle={'Loading'} labelText='Please wait. We are pulling articles.'></Spinner>
        </Box>
        :
          news ? 
            <Box as='table' sx={{ borderSpacing: '0px', ...sx.reset }}>
              <Box
                as='thead'
                sx={{
                  ...sx.reset,
                  position: ['relative', 'sticky', 'sticky', 'sticky'],
                  top: [0, 56, 56, 56],
                  pt: [4, '20px', '20px', '20px'],
                  bg: 'background',
                  zIndex: 1000,
                  borderStyle: 'solid',
                  borderColor: 'muted',
                  borderWidth: '0px',
                  borderBottomWidth: '1px'
                }}
              >
                <Row as='tr' columns={[3, 6, 6, 6]} sx={{ pb: [4] }}>
                  {fields.map((field, i) => {
                    return (
                      <Column
                        start={columns.start[i]}
                        width={columns.width[i]}
                        key={field}
                        as='th'
                        sx={{...sx.header, display: [ i === 0 ? 'none': 'inherit', 'inherit', 'inherit', 'inherit']}}
                      >
                        <Box
                          sx={{
                            mx: [0, -3, -3, -3],
                            px: [3],
                            my: [-2],
                            py: [2],
                            width: 'fit-content',
                            cursor: 'pointer',
                            color: 'secondary',
                          }}
                        >
                          {field}
                        </Box>
                      </Column>
                    )
                  })}
                </Row>
              </Box>
              <Box as='tbody' sx={sx.reset}>
                {news && news.map((d, i) => {
                  return (
                    <NewsTableRow
                      key={d._time}
                      fields={fields}
                      data={d}
                      first={i === 0}
                      idx={i}
                    />
                  )
                })}
              </Box>
            </Box>  
          : 
            <Box sx={{...sx.highlight, fontSize: [1, 1, 1, 2]}}>No news found on these dates.</Box>
      }
      
    </Box>
  )
}

export default NewsTable