import { memo } from 'react';
import { useHistory  } from "react-router-dom";
import { Box, Flex } from 'theme-ui'
import { Column, Row } from '@carbonplan/components'
import { Right } from '@carbonplan/icons';
import ArrowIndicator from '../components/ArrowIndicator';
import NumberFormat from "react-number-format";


const Report = ({ data, setHighlighted, tooltips, embed }) => {

  const history = useHistory(); 

  let {
    id,
    name,
    budget, 
    spend,
    convert
  } = data

  const ml = embed ? [0, 0, 0] : [0, 0, '24px', '36px']

  return (
    <Box
      onClick={(e) => {
        let path = `/accounts/${id}/campaigns`; 
        history.push(path);
      }}
      onMouseEnter={() => {
        if (setHighlighted) setHighlighted(id)
      }}
      onMouseLeave={() => {
        if (setHighlighted) setHighlighted(null)
      }}
      sx={{
        borderStyle: 'solid',
        borderColor: 'muted',
        borderWidth: '0px',
        borderBottomWidth: embed
          ? ['0px', '0px', '0px']
          : ['1px', '1px', '0px'],
        borderLeftWidth: embed ? [0, 0, 0] : ['0px', '0px', '1px'],
        borderRadius: '0px',
        cursor: embed ? 'default' : 'pointer',
        pl: embed ? [0, 0, 0] : [0, 0, 0],
        pr: [0],
        pb: [2, 2, 0, 0],
        pt: embed ? [0, 0, 0] : ['0px', '0px', '0px'],
        my: [embed ? 0 : 3],
        mb: embed ? [0, 0, 0] : [0, 0, 0],
        transition: 'border-color 0.15s',
        '&:hover': {
          borderColor: ['muted', 'muted', 'secondary'],
        },
        '@media (hover: hover) and (pointer: fine)': {
          '&:hover > #container > #box > #flex > #text > #expander': {
            stroke: 'primary',
          },
        },
        '@media (hover: none) and (pointer: coarse)': {
          '#container > #box > #flex > #text > #expander': {
            stroke: 'primary',
          },
        },
      }}
    >
      <Flex
        id='container'
        sx={{
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          mt: ['-6px'],
          ml: ml,
          mb: [2],
        }}
      >
        <Box id='box' sx={{ mt: ['6px'] }}>
          <Flex
            id='flex'
            sx={{
              width: ['fit-content'],
              flexWrap: 'wrap',
              ml: [-2],
            }}
          >
            <Box
              id='text'
              sx={{
                display: 'inline-block',
                fontSize: [4, 4, 4, 5],
                lineHeight: 'heading',
                ml: [2],
              }}
            >
              {name}
            </Box>
            <Box
              sx={{
                display: 'inline-block',
                fontSize: [3, 3, 3, 4],
                lineHeight: 'heading',
                ml: [3],
                fontFamily: 'faux',
                letterSpacing: 'mono',
                textTransform: 'uppercase',
                color: 'primary',
                mt: ['2px'],
                userSelect: 'none',
              }}
            >
              {data.value}
              <ArrowIndicator value={data.value}></ArrowIndicator>
            </Box>
          </Flex>
        </Box>
        <Right sx={{mt:[2], width:[15]}}></Right>
      </Flex>
      <Row columns={[3, 6, 9, 9]}>
        <Column start={[1, 1, 1, 1]} width={[3, 2, 3, 3]}>
          <Box
            sx={{
              fontSize: [1, 1, 1, 2],
              ml: ml,
              pt: ['2px'],
              pb: [1],
              color: 'secondary',
            }}
          >
            {'Budget:'}
            <Box sx={{
              display: 'inline-block',
              fontFamily: 'heading',
              textTransform: 'uppercase',
              color: 'primary',
              ml: [3]
            }}>
              <NumberFormat
                thousandsGroupStyle="thousand"
                value={ budget }
                prefix="$"
                decimalSeparator="."
                displayType="text"
                type="text"
                thousandSeparator={true}
              />
            </Box>
          </Box>
          <Box
            sx={{
              fontSize: [1, 1, 1, 2],
              ml: ml,
              pt: ['2px'],
              pb: [1],
              color: 'secondary',
            }}
          >
            {'ID:'}
            <Box sx={{
              display: 'inline-block',
              fontFamily: 'heading',
              textTransform: 'uppercase',
              color: 'primary',
              ml: [3]
            }}>
              { id }
            </Box>
          </Box>
        </Column>
        <Column start={[1, 3, 4, 4]} width={[3, 2, 3, 3]}>
          <Box
            sx={{
              fontSize: [1, 1, 1, 2],
              ml: ml,
              pt: ['2px'],
              pb: [1],
              color: 'secondary',
            }}
          >
            {'Spend (30d):'}
            <Box sx={{
              display: 'inline-block',
              fontFamily: 'heading',
              textTransform: 'uppercase',
              color: 'primary',
              ml: [3]
            }}>
              <NumberFormat
                thousandsGroupStyle="thousand"
                value={ spend }
                prefix="$"
                decimalSeparator="."
                displayType="text"
                type="text"
                thousandSeparator={true}
              />
            </Box>
          </Box>
        </Column>
        <Column start={[1, 5, 7, 7]} width={[3, 2, 3, 3]}>
        <Box
            sx={{
              fontSize: [1, 1, 1, 2],
              ml: ml,
              pt: ['2px'],
              pb: [1],
              color: 'secondary',
            }}
          >
            {'Conv. (30d):'}
            <Box sx={{
              display: 'inline-block',
              fontFamily: 'heading',
              textTransform: 'uppercase',
              color: 'primary',
              ml: [3]
            }}>
              <NumberFormat
                value={ convert }
                suffix="%"
                displayType="text"
                type="text"
              />
            </Box>
          </Box>
        </Column>
      </Row>
    </Box>
  )
}

export default memo(Report)
