import Cookies from 'universal-cookie';
import handleResponse from '../helpers/HandleResponse';
const cookies = new Cookies();


let cryptoService = {
  getCryptos, 
  getAccountCryptos, 
  postAccountCryptos, 
  getChartData, 
  getNews
};

function getCryptos(){
  return fetch(`${process.env.REACT_APP_API_URL}cat_cryptos`,{
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + cookies.get('jwt')
    }
  })
  .then(response => {
    return handleResponse(response)
  })
  .catch(error => console.warn(error));
}

function getAccountCryptos(){
  return fetch(`${process.env.REACT_APP_API_URL}account_cryptos`,{
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + cookies.get('jwt')
    }
  })
  .then(response => {
    return handleResponse(response)
  })
  .catch(error => console.warn(error));
}


function postAccountCryptos(crypto_symbol, tracking_type, advertising_type){
  return fetch(`${process.env.REACT_APP_API_URL}account_cryptos`,{
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + cookies.get('jwt')
    },
    body: JSON.stringify({ crypto_symbol, tracking_type })
  })
  .then(response => {
    return handleResponse(response)
  })
  .catch(error => console.warn(error));
}

function getChartData (symbol, params) {
  return fetch(`${process.env.REACT_APP_API_URL}cat_cryptos/${symbol}/getSocialVolume?${new URLSearchParams(params)}`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + cookies.get('jwt')
    }
  })
  .then(response => {
    return handleResponse(response)
  })
  .catch(error => console.warn(error));
}

function getNews (symbol, params) {
  return fetch(`${process.env.REACT_APP_API_URL}cat_cryptos/${symbol}/getNews?${new URLSearchParams(params)}`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + cookies.get('jwt')
    }
  })
  .then(response => {
    return handleResponse(response)
  })
  .then(res=>{
    let data = res[0].records.slice(0,9).map(r=> r.values);
    data.forEach(r=>{
      r.id = r._time;
      var date  = new Date(r._time);
      let year = date.getFullYear();
      let month = (1 + date.getMonth()).toString().padStart(2, '0');
      let day = date.getDate().toString().padStart(2, '0');
      r.date = month + '/' + day + '/' + year;
    })
    data.sort((a, b) => {
      return  b._value_views - a._value_views;
    })
    return data
  })
  .catch(error => console.warn(error));
}


export default cryptoService;