import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import FormControlLabel from '@mui/material/FormControlLabel';
import DialogTitle from '@mui/material/DialogTitle';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Box } from '@mui/material';
import { useThemeUI  } from 'theme-ui';
import AlertComponent from './AlertComponent';
import ComboBox from '../components/Autocomplete';
import useQuery from '../helpers/useQueryParams';
import etfsService from '../services/EtfService';
import stockService from '../services/StockService';
import cryptoService from '../services/CryptoService';

export default function ChangeTriggerModal({
  openModal, 
  handleClose,
}) {
  const themeUI  = useThemeUI();
  const theme = createTheme({
    components:{
      MuiPaper:{
        styleOverrides: {
          root: {
            color: themeUI.theme.colors.text,
            background:themeUI.theme.colors.background,
          },
        },
      },
      MuiTypography:{
        styleOverrides: {
          root:{
            color: themeUI.theme.colors.text + '!important',
          }
        }
      },
      MuiFormLabel:{
        styleOverrides: {
          root:{
            color: themeUI.theme.colors.text + '!important',
          }
        }
      },
      MuiSvgIcon:{
        styleOverrides: {
          root:{
            color: themeUI.theme.colors.text + '!important',
          }
        }
      },
      MuiOutlinedInput:{
        styleOverrides: {
          root: {
            ':hover .MuiOutlinedInput-notchedOutline': {
              borderColor: themeUI.theme.colors.muted,
            },
            color: themeUI.theme.colors.text + '!important',
          },
          notchedOutline:{
            borderColor: themeUI.theme.colors.text,
           },
        }
      },
      MuiSwitch:{
        styleOverrides: {
          root: {
            ".css-19ock7p-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track":{
              "backgroundColor": "rgba(126,179,106,0.55)"
            },
            "width":'70px',
            ".css-19ock7p-MuiButtonBase-root-MuiSwitch-switchBase":{
              "color": themeUI.theme.colors.secondary
            },
            ".css-19ock7p-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked":{
              "color": themeUI.theme.colors.green,
              "transform": "translateX(30px)"
            }
          },
          track:{
            backgroundColor: themeUI.theme.colors.muted
          }
          
        }
      },
      MuiDialogContent:{
        styleOverrides: {
          root: {
            paddingBottom: '350px'
          }
        }
      }
    }
    
  });

  var [stockList, setStockList] = useState([]);
  var [cryptoList, setCryptoList] = useState([]);
  var [etfList, setEtfList] = useState([]);
  var [typeStockSelected, setTypeStockSelected] = useState(undefined);
  var [stockSelected, setStockSelected] = useState(undefined);
  var [openAutocomplete, setOpenAutocomplete] = useState(false);
  var [search, setSearch] = useState('');
  var [alertStockSelectd, setAlertStockSelected] = useState(false);
  var [clearInput, setClearInput] = useState(false);
  var history = useHistory(); 
  var query = useQuery();
  var loading = false;
  

  const handleSelect = (e) =>{
    setTypeStockSelected(e.target.value);
    setStockSelected(undefined);
  }


  const handleChangeInput = (e) => {
    setAlertStockSelected(false);
    setSearch(e.target.value);
  };
  const handleChangeAutocomplete = (e,v, r) => {
    if(r==='clear') setClearInput(!clearInput);
    setStockSelected(v);
  }

  const handleChange = (e) =>{
    if(!stockSelected) setAlertStockSelected(true);
    else {
      history.replace(`/chartbuilder?slug=${stockSelected.symbol}&metric=${'volume'}&start=${query.get("start")}&end=${query.get("end")}`);
      handleClose();
    }
  }
  useEffect(() => {
    if(openModal){
      setTypeStockSelected(undefined)
      cryptoService.getCryptos()
      .then(response => {
        response = response.map(r=> {r['label'] = r.name; return r})
        setCryptoList(response)
      })
      etfsService.getETFs()
      .then(response=> {
        response = response.map(r=> {r['label'] = r.symbol; return r})
        setEtfList(response)
      })
    }
  },[openModal])
  useEffect(
    () => {
      let active = true;
      loading = true; 
      if(active && openModal){
        stockService.getStocks(search)
        .then(response=> {
          setStockList(response);
          loading = false;
        })
        
        return () => {
          active = false;
        };
      }
    }, [search, openModal, clearInput])
  
  useEffect(() => {
    if (!openAutocomplete) {
      setStockList([]);
      setSearch('');
    }
  }, [openAutocomplete]);
  
  return (
    <>
      <ThemeProvider theme={theme}>
      <Dialog 
        open={openModal} 
        onClose={()=>{handleClose(); setStockSelected(undefined);}}
        fullWidth={true}
        maxWidth={'sm'}
      >
        <DialogTitle>Change stock</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{
              mb:[2]
            }}>
            Please choose stock:
          </DialogContentText >
            <RadioGroup row>
              <FormControlLabel value="CatEtf" control={<Radio />} label="ETF" onChange={handleSelect}/>
              <FormControlLabel value="CatCrypto" control={<Radio />} label="Crypto" onChange={handleSelect}/>
              <FormControlLabel value="CatStock" control={<Radio />} label="Stock" onChange={handleSelect}/>
              {/* { alertSourceModel && (
                <AlertComponent 
                  type={'error'} 
                  strongText={'Select a trigger type.'} 
                  sx={{width: 250}
                }></AlertComponent>
              )} */}
            </RadioGroup>
            {
              typeStockSelected === 'CatEtf' && (
              <>
                <DialogContentText sx={{
                  mt: [3],
                  mb:[2]
                }}>
                  Select source:
                </DialogContentText>
                <Box sx={{
                  display: 'flex',
                  alignItems: 'center'
                }}>
                  <ComboBox 
                    data={etfList} 
                    label={'ETF'}
                    setValue={setStockSelected}
                    setAlert={setAlertStockSelected}
                    sx={{
                      width: 300,
                      marginRight: '10px'
                    }}
                  />
                  { alertStockSelectd && (
                    <AlertComponent 
                      type={'error'} 
                      strongText={'Select a source.'} 
                      sx={{width: 250}
                    }></AlertComponent>
                  )}
                </Box>
                
              </>
            )
          }
          {
            typeStockSelected === 'CatCrypto' && (
              <>
                <DialogContentText sx={{
                  mt: [3],
                  mb:[2]
                }}>
                  Select source:
                </DialogContentText>
                <Box sx={{
                  display: 'flex',
                  alignItems: 'center'
                }}>
                  <ComboBox 
                    data={cryptoList} 
                    label={'Crypto'}
                    setValue={setStockSelected}
                    setAlert={setAlertStockSelected}
                    sx={{
                      width: 300,
                      marginRight: '10px'
                    }}
                  />
                  { alertStockSelectd && (
                    <AlertComponent 
                      type={'error'} 
                      strongText={'Select a source.'} 
                      sx={{width: 250}
                    }></AlertComponent>
                  )}
                </Box>
                
              </>
            )
          }
          {
            typeStockSelected === 'CatStock' && (
              <>
                <DialogContentText sx={{
                  mt: [3],
                  mb:[2]
                }}>
                  Select source:
                </DialogContentText>
                <Box sx={{
                  display: 'flex',
                  alignItems: 'center'
                }}>
                  <Autocomplete
                    sx={{ width: 300 }}
                    open={openAutocomplete}
                    onOpen={() => {
                      setOpenAutocomplete(true);
                    }}
                    onClose={() => {
                      setOpenAutocomplete(false);
                      setSearch('');
                    }}
                    onChange={handleChangeAutocomplete}
                    isOptionEqualToValue={(option, value) => option.symbol === value.symbol }
                    getOptionLabel={(option) => option.symbol}
                    options={stockList}
                    loading={loading}
                    filterOptions={(x) => x}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Stock"
                        onChange={handleChangeInput}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {loading ? <CircularProgress color="inherit" size={20} /> : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                  { alertStockSelectd && (
                    <AlertComponent 
                      type={'error'} 
                      strongText={'Select a source.'} 
                      sx={{width: 250}
                    }></AlertComponent>
                  )}
                </Box>
                
              </>
            )
          }
            {/* <Box 
              sx={{
                display: 'flex',
                alignItems: 'center'
              }}>
                <Autocomplete
                  sx={{ width: 300 }}
                  open={openAutocomplete}
                  onOpen={() => {
                    setOpenAutocomplete(true);
                  }}
                  onClose={() => {
                    setOpenAutocomplete(false);
                    setSearch('');
                  }}
                  onChange={handleChangeAutocomplete}
                  isOptionEqualToValue={(option, value) => option.symbol === value.symbol }
                  getOptionLabel={(option) => option.symbol}
                  options={stockList}
                  loading={loading}
                  filterOptions={(x) => x}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Stock"
                      onChange={handleChangeInput}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
                  { alertStockSelectd && (
                    <AlertComponent 
                      type={'error'} 
                      strongText={'Select a stock.'} 
                      sx={{width: 250}
                    }></AlertComponent>
                  )}
                </Box> */}
            
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>{handleClose(); setStockSelected(undefined);}}>Cancel</Button>
          <Button onClick={handleChange}>Change</Button>
        </DialogActions>
      </Dialog>
      </ThemeProvider >
    </>
  );
}
