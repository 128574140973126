import { Box, Divider, Flex } from 'theme-ui';
import { Tray, Toggle } from '@carbonplan/components';
import ConfirmationActiveModal from './confirmationActiveModal';
import ListEvents from '../Home/listEvents';
import Top from './top';

export const sx = {
  label: {
    fontFamily: 'mono',
    letterSpacing: 'mono',
    fontSize: [1, 1, 1, 2],
    textTransform: 'uppercase',
    color: 'secondary',
    userSelect: 'none',
  },
}

const Sidebar = ({
  data,
  settingsExpanded,
  activeCampaign,
  events
}) => {


  return (
    <>
      <Box
        sx={{
          position: 'sticky',
          top: 56,
          height: ['fit-content', 'fit-content', 'calc(100vh - 56px)'],
          overflowY: 'scroll',
          '::-webkit-scrollbar': {
            width: 0,
            background: 'transparent',
          },
          scrollbarWidth: 'none'
        }}
      >
        <Top type={'recent events'} data={events}></Top>
        <ListEvents data={events} type={'sidebar'}></ListEvents>
      </Box>
      <Tray expanded={settingsExpanded}>
        <Box>
            {/* <OrderByPercent 
              sx={sx} 
              upSortHover={upSortHover}
              setSortUpHover={setSortUpHover}
              downSortHover={downSortHover}
              setSortDownHover={setSortDownHover}
              orderPercentAscending={orderPercentAscending}
              setOrderPercentAscending={setOrderPercentAscending}
              orderPercentDescending={orderPercentDescending}
              setOrderPercentDescending={setOrderPercentDescending}
            /> */}
          </Box>
      </Tray>
    </>
  )
}

export default Sidebar
