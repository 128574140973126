import { Box, Flex } from 'theme-ui';
import { Row, Column } from '@carbonplan/components';

export default function TrendingWordComponent({columns}) {
  const fakeWords = [
    'sandbox',
    'metamask',
    'sand',
    'screenshot',
    'contest',
    'selfie',
    'daily',
    'alpha',
    'use',
    'pass',
  ]

  return (
    <Row columns={columns} sx={{ mb: [4, 5, 6, 7] }}>
      <Column start={[1, 1, 1, 1]} sx={{ mb: [4, 0, 5, 6] }} width={columns}>
        <Flex sx={{
          mb: [3, 3, 3, 4],
          fontSize: [3, 3, 3, 4],
          fontFamily: 'heading',
          letterSpacing: 'smallcaps',
          textTransform: 'uppercase',
          color: 'primary',
        }} >
        <Box>#</Box>
        <Box sx={{ml:[5]}}>Trending Word</Box>
        </Flex>
      
      
      {fakeWords && fakeWords.map((d, i) => {
          return (
            <Flex key={d} sx={{
              fontFamily: 'mono',
              letterSpacing: 'mono',
              fontSize: [1, 1, 1, 2],
              textTransform: 'uppercase',
              color: 'secondary',
              borderStyle: 'solid',
              borderColor: 'muted',
              borderBottomWidth: ['1px'],
              marginTop: [2],
              paddingBottom: [1]
            }}>
              <Box>
                {String(i+1).padStart(2, '0')}
                
                </Box>
              <Box sx={{pl:[5]}}>{d}</Box>
            </Flex>
          )
      }
      )}
      </Column>
    </Row>
  );

}